import { Subject } from "rxjs";

const subject = new Subject();
const initialState = {
  menuOpen: false,
  headerVisible: true,
  headerThemeColor: "dark",
  openedHotspots: false,
  videoPlaying: false,
  landingHeader: false,
  candleForm: false,
  formOverlayOpen: false,
  hideFrame: false,
  hideIconUI: true,
  showSoundIcon: true,
  showTour: false,
  currentTour: "exterior",
  phaseColor: "dark",
  audioTrack: new Audio(),
  bgAudioTrack: new Audio(),
  mutedBg: true,
  mutedVideoAudio: true,
  audioPercent: 0,
  loadedImages: false,
  hotspotData: null,
  scrollToMap: null,
  hotspotOpen: false,
  language: { lang: "English", id: "en" },
  openedExternalLinkAlert: { opened: false, type: "donation", link: "" },
  lastPage: "",
  iconHotspot: { open: false, info: { title: "", description: "" } },
  iconLevelIdx: 0,
  iconLevelData: {},
  iconListOpen: false,
  iconList: [],
  disableControls: false,
  openCookiePrefs: false,
};

var state = initialState;

const store = {
  init: () => {
    state = { ...state };
    subject.next(state);
  },
  subscribe: (setState) => subject.subscribe(setState),
  unsubscribe: (subscription) => subscription.unsubscribe(),
  setLandingHeader: (landingBool) => {
    state = {
      ...state,
      landingHeader: landingBool,
    };
    subject.next(state);
  },
  toggleMenu: () => {
    state = {
      ...state,
      menuOpen: !state.menuOpen,
    };
    subject.next(state);
  },
  showSoundIcon: (showSound) => {
    state = {
      ...state,
      showSoundIcon: showSound,
    };
    subject.next(state);
  },
  setMenuOpen: (isOpen) => {
    state = {
      ...state,
      menuOpen: isOpen,
    };
    subject.next(state);
  },
  phaseColor: (phaseColor) => {
    state = {
      ...state,
      phaseColor: phaseColor,
    };
    subject.next(state);
  },
  setHeaderColorAll: (headerColor) => {
    state = {
      ...state,
      headerThemeColor: headerColor,
      phaseColor: headerColor,
    };
    subject.next(state);
  },
  setHeaderThemeColor: (headerThemeColor) => {
    state = {
      ...state,
      headerThemeColor: headerThemeColor,
    };
    subject.next(state);
  },
  setOpenHotspots: (isOpen) => {
    state = {
      ...state,
      openedHotspots: isOpen,
    };
    subject.next(state);
  },
  setVideoPlaying: (isPlaying) => {
    state = {
      ...state,
      videoPlaying: isPlaying,
    };
    subject.next(state);
  },
  setHeaderVisible: (isVisible) => {
    state = {
      ...state,
      headerVisible: isVisible,
    };
    subject.next(state);
  },
  setCandleForm: (candleFormBool) => {
    state = {
      ...state,
      candleForm: candleFormBool,
    };
    subject.next(state);
  },
  setFormOverlayOpen: (formBool) => {
    state = {
      ...state,
      formOverlayOpen: formBool,
    };
    subject.next(state);
  },
  hideFrame: (frameBool) => {
    state = {
      ...state,
      hideFrame: frameBool,
    };
    subject.next(state);
  },
  hideIconUI: (iconBool) => {
    state = {
      ...state,
      hideIconUI: iconBool,
    };
    subject.next(state);
  },
  showTour: (tourBool) => {
    state = {
      ...state,
      showTour: tourBool,
    };
    subject.next(state);
  },
  currentTour: (tour) => {
    state = {
      ...state,
      currentTour: tour,
    };
    subject.next(state);
  },
  audioTrack: (updatedTrack) => {
    state = {
      ...state,
      audioTrack: updatedTrack,
    };
    subject.next(state);
  },
  bgAudioTrack: (updatedTrack) => {
    state = {
      ...state,
      bgAudioTrack: updatedTrack,
    };
    subject.next(state);
  },
  mutedBg: (updateMuted) => {
    state = {
      ...state,
      mutedBg: updateMuted,
    };
    subject.next(state);
  },
  mutedVideoAudio: (updateMuted) => {
    state = {
      ...state,
      mutedBg: updateMuted,
    };
    subject.next(state);
  },
  audioPercent: (updatePercent) => {
    state = {
      ...state,
      audioPercent: updatePercent,
    };
    subject.next(state);
  },
  loadedImages: (loadedBool) => {
    state = {
      ...state,
      loadedImages: loadedBool,
    };
    subject.next(state);
  },
  hotspotData: (data) => {
    state = {
      ...state,
      hotspotData: data,
    };
    subject.next(state);
  },
  hotspotOpen: (data) => {
    state = {
      ...state,
      hotspotOpen: data,
    };
    subject.next(state);
  },
  language: (lang) => {
    localStorage.setItem("lang", JSON.stringify(lang));

    state = {
      ...state,
      language: lang,
    };
    subject.next(state);
  },
  scrollToMap: (data) => {
    state = {
      ...state,
      scrollToMap: data,
    };
    subject.next(state);
  },
  introVideoPercent: (data) => {
    state = {
      ...state,
      introVideoPercent: data,
    };
    subject.next(state);
  },
  openedExternalLinkAlert: (data) => {
    state = {
      ...state,
      openedExternalLinkAlert: data,
    };
    subject.next(state);
  },
  lastPage: (data) => {
    state = {
      ...state,
      lastPage: data,
    };
    subject.next(state);
  },
  iconHotspot: (data) => {
    state = {
      ...state,
      iconHotspot: data,
    };
    subject.next(state);
  },
  iconLevelData: (data) => {
    state = {
      ...state,
      iconLevelData: data,
    };
    subject.next(state);
  },
  iconLevelIdx: (data) => {
    state = {
      ...state,
      iconLevelIdx: data,
    };
    subject.next(state);
  },
  iconListOpen: (data) => {
    state = {
      ...state,
      iconListOpen: data,
    };
    subject.next(state);
  },
  iconList: (data) => {
    state = {
      ...state,
      iconList: data,
    };
    subject.next(state);
  },
  disableControls: (data) => {
    state = {
      ...state,
      disableControls: data,
    };
    subject.next(state);
  },
  openCookiePrefs: (data) => {
    state = {
      ...state,
      openCookiePrefs: data,
    };
    subject.next(state);
  },
  initialState,
};

export default store;
