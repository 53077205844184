
import NotoBold from  './assets/fonts/NotoSerif-Bold.eot';
import NotoBoldW2 from  './assets/fonts/NotoSerif-Bold.woff2';
import NotoBoldW from  './assets/fonts/NotoSerif-Bold.woff';
import Noto from  './assets/fonts/NotoSerif.eot';
import NotoW2 from  './assets/fonts/NotoSerif.woff2';
import NotoW from  './assets/fonts/NotoSerif.woff';
import NotoBoldItalic from  './assets/fonts/NotoSerif-BoldItalic.eot';
import NotoBoldItalicW2 from  './assets/fonts/NotoSerif-BoldItalic.woff2';
import NotoBoldItalicW from  './assets/fonts/NotoSerif-BoldItalic.woff';
import NotoItalic from  './assets/fonts/NotoSerif-Italic.eot';
import NotoItalicW2 from  './assets/fonts/NotoSerif-Italic.woff2';
import NotoItalicW from  './assets/fonts/NotoSerif-Italic.woff';
import Sintony from './assets/fonts/Sintony.eot';
import SintonyW2 from './assets/fonts/Sintony.woff2';
import SintonyW from './assets/fonts/Sintony.woff';
import SintonyBold from './assets/fonts/Sintony-Bold.eot';
import SintonyBoldW2 from './assets/fonts/Sintony-Bold.woff2';
import SintonyBoldW from './assets/fonts/Sintony-Bold.woff';
import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Noto Serif';
    src: url('${Noto}');
    src: url('${Noto}?#iefix') format('embedded-opentype'),
        url('${NotoW2}') format('woff2'),
        url('${NotoW}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Noto Serif';
    src: url('${NotoBold}');
    src: url('${NotoBold}?#iefix') format('embedded-opentype'),
        url('${NotoBoldW2}') format('woff2'),
        url('${NotoBoldW}') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Noto Serif';
    src: url('${NotoItalic}');
    src: url('${NotoItalic}?#iefix') format('embedded-opentype'),
        url('${NotoItalicW2}') format('woff2'),
        url('${NotoItalicW}') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Noto Serif';
    src: url('${NotoBoldItalic}');
    src: url('${NotoBoldItalic}?#iefix') format('embedded-opentype'),
        url('${NotoBoldItalicW2}') format('woff2'),
        url('${NotoBoldItalicW}') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sintony';
    src: url('${Sintony}');
    src: url('${Sintony}?#iefix') format('embedded-opentype'),
        url('${SintonyW2}') format('woff2'),
        url('${SintonyW}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sintony';
    src: url('${SintonyBold}');
    src: url('${SintonyBold}?#iefix') format('embedded-opentype'),
        url('${SintonyBoldW2}') format('woff2'),
        url('${SintonyBoldW}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  :root {
    --app-height: 100%;
  }
  html, body {
    scrollbar-width: none;
    height: 100vh;
    height: var(--app-height);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch
  }
  body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  #root {
    height: 100%;
  }
  body {
    position: relative;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, .header1 {
    font-family: 'Noto Serif', serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 70px;
  }

  h2, .header2 {
    font-family: 'Noto Serif', serif;
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
  }

  h3, .title1 {
    font-family: 'Noto Serif', serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 35px;
  }

  h4, .title2 {
    font-family: 'Noto Serif', serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
  }

  h5, .title3 {
    font-family: 'Sintony', sans-serif;
    font-size: 22px;
    font-weight: normal;
    line-height: 28px;
  }

  h6, .title4 {
    font-family: 'Noto Serif', serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }

  p, .body1 {
    font-family: 'Sintony', sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 28px;
  }

  .body2 {
    font-family: 'Sintony', sans-serif;
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  .button1 {
    font-family: 'Sintony', sans-serif;
    font-size: 18px;
    font-weight: bold;
  }

  .button2 {
    font-family: 'Sintony', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }

  .copyright {
    font-family: 'Sintony', sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 14px;
  }

  @media (min-width: 1024px) {
    h4, .title2 {
      font-family: 'Noto Serif', serif;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
    }
    .body2 {
      font-family: 'Sintony', sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
    }

    .button2 {
      font-family: 'Sintony', sans-serif;
      font-size: 16px;
      font-weight: bold;
    }
  }

  video::cue {
    font-size: 20px;
  }
  @media (min-width: 1920px) {
    video::cue {
      font-size: 38px;
    }
  }
`;

export default GlobalStyle;