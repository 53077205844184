import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import 'sanitize.css';
import 'sanitize.css/assets.css';
import 'sanitize.css/reduce-motion.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

TimeAgo.addDefaultLocale(en);


// replace console.log for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
