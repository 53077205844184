import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import store from "../store";
import { ReactComponent as BackToTopIcon } from "../assets/icons/24-icon-backToTop.svg";
import iconsJSON from "../assets/data/icon_feature.json";
import iconPanelSVG from "../assets/icons/icon-levelcard-bg.svg";
import CloseButton from "../components/CloseButton";

const MenuContainer = styled(motion.div)`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 102;
  @media (min-width: 1024px) {
    height: 100%;
    top: 0;
    width: ${470 / 16}rem;
    left: calc(100vw - ${470 / 16}rem);
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100% - 46px);
  margin-top: 48px;
  overflow-y: auto;
  background: ${(props) => props.theme.bgLight};A;
  position: relative;
  z-index: 99;
  & svg {
    & a:hover { opacity: 0.8; }
    & a:active { opacity: 0.4; }
  }

  @media (min-width: 1024px) {
    margin-top: 56px;
  }
`;

const CloseContainer = styled.div`
  box-shadow: inset 1px 4px 9px -6px #000;
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  background-color: #BB5921;
  color: #FFF;
  padding: ${14 / 16}rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
  & span {
    display: inline-block;
    margin-right: ${14 / 16}rem
    font-family: 'Sintony', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
  }
  @media (min-width: 1024px) {
    height: 56px;
    & span {
      font-size: 1rem;
    }
  }
`;

const ListTitle = styled.h3`
  margin-top: 3rem;
  width: 100%;
  color: ${(props) => props.theme.textMenu};
  font-size: ${18 / 16}rem;
  font-family: "Noto Serif", serif;
  text-align: center;
  background-color: ${(props) => props.theme.bgDark};
  margin-bottom: 0;
  padding: 1rem;

  @media (min-width: 1024px) {
    font-weight: semiBold;
    font-size: ${22 / 16}rem;
  }
`;

const SectionTitle = styled.h3`
  font-family: "CapitoliumNews 2", serif;
  line-height: ${30 / 16}rem;
  font-size: ${22 / 16}rem;
  font-weight: SemiBold;
  text-align: center;
  width: 100%;
  padding: 1rem 0;
  margin: 0;
  margin-bottom: 0;

  @media (min-width: 1024px) {
    font-size: ${32 / 16}rem;
    font-family: "Noto Serif", serif;
    line-height: ${40 / 16}rem;
  }
`;
const SectionList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-top: ${8 / 16}rem;
  margin: 0;
  background: ${(props) => props.theme.bgDark};

  & img {
    max-width: calc(100% - 32px);
    height: auto;
    margin: 0 16px;
    max-height: 300px;
    padding-left: auto;
  }
`;
const SectionItem = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.bgCaptionCard};
  margin: ${8 / 16}rem ${14 / 16}rem;
  padding: ${19 / 16}rem ${20 / 16}rem;
  min-height: ${128 / 16}rem;
  border-radius: ${8 / 16}rem;
  &.accordion-item {
    border: 0;
  }
`;
const Description = styled.p`
  width: auto;
  padding: 0 ${14 / 16}rem;
  margin: 0;
  margin-bottom: 1rem;
  font-family: "Sintony", sans-serif;
  font-size: ${12 / 16}rem;
  font-weight: normal;
  line-height: ${15 / 16}rem;

  @media (min-width: 1024px) {
    font-size: 1rem;
    line-height: ${20 / 16}rem;
  }
`;

// const SectionList = styled.div`
//   width: calc(100% - 32px);
//   margin: 0 16px;
//   margin-bottom: 60px;
//   padding: 0;
//   & div {
//     display: flex;
//     flex-flow: column nowrap;
//     justify-content: center;
//     align-items: center;
//     margin-top: 60px;
//     & h1 {
//       font-family: 'Noto Serif', serif;
//       font-size: 18px;
//       font-weight: 700;
//       line-height: 30px;
//       text-align: center;
//     }
//     & h2 {
//       font-family: 'Sintony', sans-serif;
//       font-size: 12px;
//       font-weight: normal;
//       line-height: 18px;
//       text-align: center;
//     }
//     & img {
//       display: block;
//       max-width: calc(100% - 32px);
//       height: auto;
//       max-height: 500px;
//       margin: 0 16px;
//     }
//   }
// `;

const BackToTopButton = styled.button`
  box-shadow: none;
  border: none;
  background-color: ${(props) => props.theme.bgDark};
  width: 100%;
  min-height: ${128 / 16}rem;
  color: ${(props) => props.theme.primaryDark};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  & svg {
    height: auto;
  }
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.4;
  }
`;

const Panel = styled.div`
  width: 100%;
  height: auto;
  background: ${(props) => props.theme.bgLight};
  padding: 20px;
  align-items: center;
  position: relative;
`;

const Level = styled.h1`
  font-family: "Noto Serif", serif;
  font-size: ${28 / 16}rem;
  font-weight: SemiBold;
  font-weight: 700;
  line-height: ${30 / 16}rem;
  margin: 0 auto;
  color: ${(props) => props.theme.neatural1};
  text-align: center;
`;

const Info = styled.p`
  width: 100%;
  font-family: "Sintony", sans-serif;
  padding-left: ${34 / 16}rem;
  padding-right: ${34 / 16}rem;
  font-size: ${12 / 16}rem;
  font-weight: normal;
  line-height: ${15 / 16}rem;
  margin-top: 1rem;
  color: ${(props) => props.theme.neatural1};
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 1rem;
    line-height: ${20 / 16}rem;
  }
`;

const IconList = () => {
  const [state, setState] = useState(store.initialState);
  const [formattedIconList, setFormattedIconList] = useState([]);
  const scrollingContainerRef = useRef(null);
  const levelRefs = useRef([]);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  // useEffect(() => {
  //   levelRefs.current[state.iconLevelIdx].scrollIntoView({ behavior: 'smooth' });
  // }, [state.iconLevelIdx]);

  const backToTop = () => {
    scrollingContainerRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const onCloseList = () => {
    store.setHeaderVisible(true);
    store.setMenuOpen(false);
    store.iconListOpen(false);
  };

  const scrollToSection = () => {
    console.log("done anim");
    levelRefs.current[state.iconLevelIdx].scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    console.log(state.iconList);
    const tempFormatList = {
      "Level 1": [],
      "Level 2": [],
      "Level 3": [],
      "Level 4": [],
    };
    if (state.iconList.length > 0) {
      for (const indx in state.iconList) {
        const level = state.iconList[indx];
        tempFormatList[level.level] = level.hotspots;
      }
      setFormattedIconList(tempFormatList);
    }
  }, [state.iconList]);

  useEffect(() => {
    const levelPanel = document.getElementById(`l-${state.iconLevelIdx}`);
    console.log("scroll dammit ", state.iconLevelIdx, levelPanel);
    levelPanel.scrollIntoView({ behavior: "smooth" });
  }, [state.iconLevelIdx]);

  useEffect(() => {
    console.log(formattedIconList, formattedIconList["Level 1"]);
  }, [formattedIconList]);

  return (
    <>
      <MenuContainer
        key={`hotspot-list level-wrapper-${state.iconLevelIdx}`}
        initial={{
          opacity: 0,
          transform:
            window.innerWidth >= 1024 ? "translateX(100%)" : "translateY(100%)",
        }}
        animate={{
          opacity: 1,
          transform:
            window.innerWidth >= 1024 ? "translateX(0%)" : "translateY(0%)",
        }}
        exit={{
          opacity: 0,
          transform:
            window.innerWidth >= 1024 ? "translateX(100%)" : "translateY(100%)",
        }}
        transition={{ duration: 0.4, type: "tween", ease: "easeInOut" }}
        onAnimationComplete={() => scrollToSection()}
      >
        <CloseContainer onClick={onCloseList}>
          <span>Close List View</span>
          <CloseButton action={onCloseList} />
        </CloseContainer>
        <MenuWrapper ref={scrollingContainerRef} key={``}>
          <Panel
            id="l-0"
            className="panel"
            ref={(el) => (levelRefs.current[0] = el)}
          >
            <Level>{iconsJSON[0].level}</Level>
            <Level>{iconsJSON[0].title}</Level>
            <Info>{iconsJSON[0].info}</Info>
          </Panel>
          <SectionList>
            {formattedIconList["Level 1"] !== undefined &&
              formattedIconList["Level 1"].map((icon, key) => (
                <SectionItem key={icon.title}>
                  <SectionTitle>{icon.title}</SectionTitle>
                  <Description>{icon.info}</Description>
                  <img
                    src={
                      icon.image !== ""
                        ? icon.image
                        : "https://place-hold.it/342x250/aaa/WIP"
                    }
                    alt="placeholder"
                  />
                </SectionItem>
              ))}
          </SectionList>

          <Panel
            id="l-1"
            className="panel"
            ref={(el) => (levelRefs.current[1] = el)}
          >
            <Level>{iconsJSON[1].level}</Level>
            <Level>{iconsJSON[1].title}</Level>
            <Info>{iconsJSON[1].info}</Info>
          </Panel>
          <SectionList flush>
            {formattedIconList["Level 2"] !== undefined &&
              formattedIconList["Level 2"].map((icon, key) => (
                <SectionItem key={icon.title}>
                  <SectionTitle>{icon.title}</SectionTitle>
                  <Description>{icon.info}</Description>
                  <img
                    src={
                      icon.image !== ""
                        ? icon.image
                        : "https://place-hold.it/342x250/aaa/WIP"
                    }
                    alt="placeholder"
                  />
                </SectionItem>
              ))}
          </SectionList>

          <Panel
            id="l-2"
            className="panel"
            ref={(el) => (levelRefs.current[2] = el)}
          >
            <Level>{iconsJSON[2].level}</Level>
            <Level>{iconsJSON[2].title}</Level>
            <Info>{iconsJSON[2].info}</Info>
          </Panel>
          <SectionList flush>
            {formattedIconList["Level 3"] !== undefined &&
              formattedIconList["Level 3"].map((icon, key) => (
                <SectionItem key={icon.title}>
                  <SectionTitle>{icon.title}</SectionTitle>
                  <Description>{icon.info}</Description>
                  <img
                    src={
                      icon.image !== ""
                        ? icon.image
                        : "https://place-hold.it/342x250/aaa/WIP"
                    }
                    alt="placeholder"
                  />
                </SectionItem>
              ))}
          </SectionList>
          <Panel
            id="l-3"
            className="panel"
            ref={(el) => (levelRefs.current[3] = el)}
          >
            <Level>{iconsJSON[3].level}</Level>
            <Level>{iconsJSON[3].title}</Level>
            <Info>{iconsJSON[3].info}</Info>
          </Panel>
          <SectionList flush>
            {formattedIconList["Level 4"] !== undefined &&
              formattedIconList["Level 4"].map((icon, key) => (
                <SectionItem key={icon.title}>
                  <SectionTitle>{icon.title}</SectionTitle>
                  <Description>{icon.info}</Description>
                  <img
                    src={
                      icon.image !== ""
                        ? icon.image
                        : "https://place-hold.it/342x250/aaa/WIP"
                    }
                    alt="placeholder"
                  />
                </SectionItem>
              ))}
          </SectionList>
          <BackToTopButton onClick={backToTop}>
            <BackToTopIcon /> <span>Back to Top</span>
          </BackToTopButton>
        </MenuWrapper>
      </MenuContainer>
    </>
  );
};

IconList.propTypes = {
  onCloseList: PropTypes.func,
};

IconList.defaultProps = {
  onCloseList: null,
};

export default IconList;
