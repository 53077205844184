import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import store from "../store";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  margin: 0;
  padding: 0;
  border-radius: 99rem;
  box-shadow: 0 0 ${10 / 16}rem rgba(0, 0, 0, 0.13);
  font-family: "Sintony", sans-serif;
  z-index: 99;
  margin-top: ${25/16}rem;
  width: ${195/16}rem;
  height: ${40/16}rem;
  text-decoration: none;
  &.dark {
    color: #fffefa;
    border: 1px solid #816920;
    background-color: #81691f;
    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.bgDarkHover};
    }
    &:active {
      cursor: pointer;
      background-color: ${(props) => props.theme.bgDarkClick};
    }
  }

  &.light {
    color: #816920;

    background-color: #fffefa;
    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.bgLightHover};
    }
    &:active {
      cursor: pointer;
      background-color: ${(props) => props.theme.bgLightClick};
    }
  }

  @media (min-width: 1024px) {
    width: ${240 / 16}rem;
    height: ${48 / 16}rem;
    font-size: ${18 / 16}rem;

    ${(props) => props.addClass === 'left-margin' ? `margin-left: 1.25rem;` : ''}
    ${(props) => props.addClass === 'right-margin' ? `margin-right: 1.25rem;` : ''}

    & svg {
      width: 32px;
      height: 32p;
  }

`;

const EndScreenButton = ({
  label,
  icon,
  classState,
  type,
  action,
  addClass,
  addStyle,
  isLink,
  link,
}) => {
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  return (
    <StyledButton
      onClick={action}
      className={`${type} ${classState} ${
        !label && icon ? "icon-only" : ""
      } ${addClass}`}
      style={addStyle}
      as={isLink ? "a" : "button"}
      href={isLink ? link : ""}
      target={isLink ? "_blank" : ""}
      disabled={state === "inactive"}
      addClass={addClass}
    >
        {label} {icon}
    </StyledButton>
  );
};

export default EndScreenButton;
