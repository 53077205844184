/* eslint-disable no-lonely-if */
module.exports = {
  fadeIn: (track, from, to, step, callback) => {
    const myTrack = track;
    myTrack.volume = from;

    const fade = () => {
      let val = parseFloat(myTrack.volume);
      val += step;
      if (!(val > to)) {
        myTrack.volume = val;
        requestAnimationFrame(fade);
      } else {
        if (callback) callback();
      }
    };
    fade();
  },
  fadeOut: (track, from, to, step, callback) => {
    const myTrack = track;
    myTrack.volume = from;


    console.log('I AM HERE')

    const fade = () => {
      let val = parseFloat(myTrack.volume);
      val -= step;
      if (!(val < to)) {
        myTrack.volume = val;
        requestAnimationFrame(fade);
      } else {
        if (callback) callback();
      }
    };
    fade();
  },
};
