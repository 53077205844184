import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as arrow } from "../assets/icons/32-icon-leftArrow-v2.svg";

const SwipeOverlayContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  height: ${307 / 16}rem;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(to top, transparent, #2e2218, transparent);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Sintony", sans-serif;
  font-weight: bold;
  font-size: ${20 / 16}rem;
  pointer-events: none;
  text-transform: capitalize; // Use 'capitalize' if you want to transform the text to Titlecase
`;

const LeftArrow = styled(arrow)`
  path {
    stroke: white;
  }
  margin-right: 0.5rem;
`;

const RightArrow = styled(arrow)`
  path {
    stroke: white;
  }
  transform: rotate(180deg);
`;

const SwipeText = styled.span`
  color: white;
`;

const SwipeOverlay = ({ timeout }) => {
  const [isVisible, setIsVisible] = useState(true);
  const timeOut = timeout || 3500;
  const isMobile = window.innerWidth < 1024;

  const timerId = setTimeout(() => {
    setIsVisible(false);
  }, timeOut);

  if (!isVisible) return null;

  return (
    <SwipeOverlayContainer>
      {isMobile ? (
        <SwipeText>
          <LeftArrow /> Swipe <RightArrow />
        </SwipeText>
      ) : (
        <SwipeText>
          <LeftArrow /> Scroll <RightArrow />
        </SwipeText>
      )}
    </SwipeOverlayContainer>
  );
};

export default SwipeOverlay;
