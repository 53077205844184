import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { motion, useDragControls, useScroll } from "framer-motion";
import ReactTimeAgo from "react-time-ago";
import { ReactComponent as CandleIcon } from "../../assets/icons/32-icon-candles.svg";
import Button from "../../components/Button";
import SimpleCandle from "./SimpleCandle";
import app from "../../config/fbConfig.js";
import SwipeOverlay from "../../components/SwipeOverlay";
import {
  getFirestore,
  getDocs,
  query,
  collection,
  orderBy,
} from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";

const LandingContainer = styled(motion.div)`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 64px;
  @media (min-width: 1024px) {
    padding-top: 80px;
  }
  @media (min-width: 768px) {
    justify-content: space-evenly;
  }
`;

const Title = styled.h1`
  width: 100%;
  padding: 16px;
  font-family: "Sintony", sans-serif;
  font-size: 10px;
  font-weight: normal;
  line-height: 30px;
  text-align: center;

  color: ${(props) => props.theme.bgLight};
  & span {
    font-family: "Noto Serif", serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    @media (min-width: 1366px) {
      font-size: 32px;
      line-height: 40px;
     
    }
  }
  @media (min-width: 1920px) {
    font-size: 14px;
    line-height: 40px;
  }

  @media (max-width: 1000px){
    margin-top: 30px; 
  }
`;

const PrayerList = styled.ul`
  width: 100vw;
  height: 45vh;
  margin-left: 0;
  margin-top: 2rem;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 0;
  list-style: none;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-auto-columns: 1fr;
  row-gap: 0;
  column-gap: 20px;
  padding-left: 16px;
  padding-top: 1rem;
  @media (min-width: 1024px) {
    height: auto;
    row-gap: 20px;
  }
  @media (min-width: 1920px) {
    row-gap: 30px;
    column-gap: 30px;
    padding-left: 100px;
  }
  /* total width */
  &::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0.2);
      width: 10px;
      height: 5px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.2);
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
      background-color: rgba(129, 105, 32, 0.4);
      border-radius: 18px;
      border: 1px solid black;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
      display:none;
  }
`;

const Prayer = styled.li`
  width: 200px;
  height: auto;
  position: relative;
  padding-left: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  &:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;
  }
  &:nth-child(2) {
    grid-row-start: 2;
    grid-column-start: 2;
  }
  &:nth-child(3) {
    grid-row-start: 3;
    grid-column-start: 1;
  }
  &:nth-child(4) {
    grid-row-start: 1;
    grid-column-start: 3;
  }
  &:nth-child(5) {
    grid-row-start: 2;
    grid-column-start: 4;
  }
  &:nth-child(6) {
    grid-row-start: 3;
    grid-column-start: 3;
  }
  &:nth-child(7) {
    grid-row-start: 1;
    grid-column-start: 5;
  }
  &:nth-child(8) {
    grid-row-start: 2;
    grid-column-start: 6;
  }
  &:nth-child(9) {
    grid-row-start: 3;
    grid-column-start: 5;
  }
  &:nth-child(10) {
    grid-row-start: 1;
    grid-column-start: 7;
  }
  &:nth-child(11) {
    grid-row-start: 2;
    grid-column-start: 8;
  }
  &:nth-child(12) {
    grid-row-start: 3;
    grid-column-start: 7;
  }
  &:nth-child(13) {
    grid-row-start: 1;
    grid-column-start: 9;
  }
  &:nth-child(14) {
    grid-row-start: 2;
    grid-column-start: 10;
  }
  &:nth-child(15) {
    grid-row-start: 3;
    grid-column-start: 9;
  }
  &:nth-child(16) {
    grid-row-start: 1;
    grid-column-start: 11;
  }
  &:nth-child(17) {
    grid-row-start: 2;
    grid-column-start: 12;
  }
  &:nth-child(18) {
    grid-row-start: 3;
    grid-column-start: 11;
  }
  &:nth-child(19) {
    grid-row-start: 1;
    grid-column-start: 13;
  }
  &:nth-child(20) {
    grid-row-start: 2;
    grid-column-start: 14;
  }
  &:nth-child(21) {
    grid-row-start: 3;
    grid-column-start: 13;
  }
  &:nth-child(22) {
    grid-row-start: 1;
    grid-column-start: 15;
  }
  @media (min-width: 1920px) {
    width: 340px;
  }
`;

const SwipeCta = styled.div`
  color: ${(props) => props.theme.bgLight};
  font-family: "Sintony", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  margin-bottom: 0;
  & svg:first-child {
    transform: rotate(90deg);
  }
  & svg:nth-child(2) {
    transform: rotate(-90deg);
  }
  & svg g path {
    stroke: ${(props) => props.theme.bgLight};
  }
  @media (min-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 80px;
    font-size: 16px;
    line-height: 40px;
  }
`;

const Message = styled.h2`
  margin: 0px;
  font-family: "Noto Serif", serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  color: ${(props) => props.theme.bgLight};
  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 30px;
  }
`;

const Location = styled.p`
  margin: 0px 4px 4px 0px;
  font-family: "Sintony", serif;
  font-size: 9px;
  font-weight: bold;
  line-height: 30px;
  color: ${(props) => props.theme.bgLight};
  @media (min-width: 1920px) {
    margin: 0px 25px 25px 0px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const StyledTime = styled(ReactTimeAgo)`
  margin: 0px 4px 4px 0px;
  color: ${(props) => props.theme.bgLight};
  font-family: "Sintony", serif;
  font-size: 7px;
  font-weight: normal;
  line-height: 30px;
  @media (min-width: 1920px) {
    margin: 0px 25px 25px 0px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const MobileBr = styled.br`
  @media (min-width: 1024px) {
    display: none;
  }
`;

let TOTAL_CANDLES = 21870855;
let TOTAL_COUNTRIES = 194;

const Landing = ({ setSection }) => {
  const [prayerDb, setPrayerDb] = useState([]);
  const prayers = useRef([]);
  const isMobile = window.innerWidth < 1024;
  const dragControls = useDragControls();
  const prayerListRef = useRef(null);

  const startDrag = (event) => {
    dragControls.start(event, { snapToCursor: true });
  };

  useEffect(() => {
    prayers.current = [];
    // anon sign in into firebase
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Initialize Firebase
        const db = getFirestore(app);

        const getFirestoreQuery = async () => {
          const dbQuery = await getDocs(
            query(collection(db, "prayers"), orderBy("dateSubmitted", "asc"))
          );
          dbQuery.forEach((doc) => {
            if (doc.data().status === "Approved") {
              prayers.current.push(doc.data());
            }
          });
          prayers.current = prayers.current.sort((a, b) => {
            return new Date(b.dateSubmitted) - new Date(a.dateSubmitted);
          });

          TOTAL_CANDLES = prayers.current.length;
          let countriesSet = new Set();
          prayers.current.forEach((prayer) => {
            if (prayer.country !== '') countriesSet.add(prayer.country);
          });
          TOTAL_COUNTRIES = countriesSet.size;
          console.log(countriesSet)

          prayers.current = prayers.current.slice(0, 21);
          console.log(prayers.current)
          setPrayerDb(prayers.current);
        };
        getFirestoreQuery();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("sign in error: ", `${errorCode} ${errorMessage}`);
      });
  }, []);

  useEffect(() => {
    const handleWheel = (e) => {
      if (prayerListRef.current) {
        prayerListRef.current.scrollLeft += e.deltaY;
      }
    };

    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    setPrayerDb((old) => {
      return old.sort((a, b) => {
        return new Date(b.dateSubmitted) - new Date(a.dateSubmitted);
      });
    });
  }, [prayerDb]);

  const renderMessage = (offFor, verb, noun) => {
    const possesiveFor =
      offFor === "family" || offFor === "friend" || offFor === "community"
        ? `my ${offFor}`
        : offFor;
    if (verb && noun) {
      return `I'm ${verb} for ${noun} for ${possesiveFor}.`;
    } else {
      return `I'm lighting a candle for ${possesiveFor}.`;
    }
  };

  return (
    <LandingContainer
      animate={{ transform: "translateX(0%)", opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ transform: "translateX(-50%)", opacity: 0 }}
    >
      <SwipeOverlay timeout={3500} />
      <Title>
        <span>{TOTAL_CANDLES.toLocaleString()}&#32;</span>
        candles have been lit <MobileBr /> from
        <span>&#32;{TOTAL_COUNTRIES.toLocaleString()}&#32;</span>
        countries.
      </Title>

      <PrayerList
        key="prayer list"
        ref={prayerListRef}
        drag="x"
        onPointerDown={startDrag}
        dragContols={dragControls}
      >
        {prayerDb.map((item, index) => (
          <Prayer key={`${index}-${item.id}`}>
            <SimpleCandle addClass="smol" delay={index} />
            <Message>
              {renderMessage(item.offeringFor, item.verb, item.noun)}
            </Message>
            <div
              style={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {item.state ? (
                <Location>
                  {item.state}, {item.country}
                </Location>
              ) : (
                <Location>{item.country}</Location>
              )}
              <StyledTime
                date={Date.parse(item.dateSubmitted)}
                locale="en-US"
              />
            </div>
          </Prayer>
        ))}
      </PrayerList>
      {/* <SwipeCta> TODO: if this is unused, get rid of it
        <ArrowIcon />
        {window.innerWidth > 1340 ? "Scroll" : "Swipe"}
        <ArrowIcon />
      </SwipeCta> */}
      <Button
        type="light"
        label="Light A Candle"
        action={() => setSection("main")}
        addClass="pl"
        addStyle={{ marginBottom: "6rem", width: "13rem", zIndex: 99 }}
        matchIconFillToText={true}
        icon={<CandleIcon />}
      />
    </LandingContainer>
  );
};

Landing.propTypes = {
  setSection: PropTypes.func,
};
Landing.defaultProps = {
  setSection: null,
};

export default Landing;
