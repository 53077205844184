const theme = {
  primaryDark: '#816920',
  primaryLight: '#A78724',
  secondaryDark: '#162939',
  secondaryLight: '#14395A',
  bgDark: '#F4F3F0',
  bgLight: '#FFFEFA',
  error: '#B52C2C',

  primary1: '#463309',
  primary2: '#5E4612',
  primary3: '#6F5719',
  primary4: '#816920',
  primary5: '#A78724',
  primary6: '#D0C39C',
  primary7: '#D9D5CE',
  primary8: '#2F4656',
  primary9: '#FFFEFA',

  secondary1: '#162939',
  secondary2: '#14395A',
  secondary3: '#31526F',
  secondary4: '#49657F',
  secondary5: '#768DA2',
  secondary6: '#95A8B9',
  secondary8: '#E5E9ED',
  secondary9: '#F9FAFC',

  neutral1: '#0D0D0D',
  neutral2: '#333332',
  neutral3: '#4C4B4A',
  neutral4: '#666564',
  neutral5: '#7F7E7C',
  neutral6: '#999996',
  neutral7: '#B2B2AF',
  neutral8: '#2F4656',
  neutral9: '#F1F0EC',

  bgDarkDefault: '#806920',
  bgDarkHover: '#A78724',
  bgDarkClick: '#605121',
  bgLightDefault: '#FFFEFA',
  bgLightHover: '#F4F3F0',
  bgLightClick: '#F4F3F0',
  bgNavyOverlay: '#162939',
  bgCaptionCard: '#FFFEFADE',
  bgFormControls: '#FFFEFA',
  bgFormControlsHover: '#F4F3F0',
  bgButtonLightDefault: '#F4F3F0',

  bgBackButtonLightDefault: '#FFFFFF',
  bgBackButtonHoverLightDefault: '#F4F3F0',
  bgBackButtonClickLightDefault: '#F4F3F0',
  bgLightACandleDarkDefault: '#816922',

  textDarkDefault: '#FFFFFF',
  textDarkHover: '#FFFFFF',
  textDarkClick: '#FFFFFF',
  textLightDefault: '#816920',
  textLightHover: '#816920',
  textLightClick: '#816920',
  textTourEnd: '#FFFFFF',
  textCaptionCard: '#0D0D0D',
  textCandleForm: '#939393',
  textLightOnDark: '#7D6A2D',
  textDarkCaption: '#7D6A2D',
  textMenu: '#806920',
  borderLightDefault: '#816920',
  borderLightHover: '#816920',
  borderLightClick: '#816920',
  borderFormControl: '#939393',

  iconNavbarTour: '#FFFFFF',
  iconNavbarDefault: '#7D6A2D',
  iconDarkened: '#816920',
  iconLightened: 'rgba(129, 105, 32, .5)',

  commonLight: '#FFFEFA',

  navWhite: '#FFFFFF',
  navGold: '#7D6A2D',
};

export default theme;