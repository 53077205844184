import { useState, useLayoutEffect, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Outlet, useLocation } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components/macro";
import { AnimatePresence } from "framer-motion";
import store from "../store";
import CookieConsent from "../components/CookieConsent.js";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeadphoneOverlay from "../components/HeaphoneOverlay";
import DonationAlert from "../components/DonationAlert";

import GlobalStyle from "../styles.js";
import theme from "../theme.js";

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  background: #f4f3f0;
  display: block;
  @media (min-width: 1024px) {
    /* height: ${(props) => props.locationPath.includes("/light-a-candle") ? "calc(100% - 70px)": "100%"}; */
    /* height: ${(props) => props.locationPath.includes("/light-a-candle") && "100%"};
    min-height: -webkit-fill-available; */
  }
`;

/*// height: ${(props) => TODO: clean this up if it is still unneeded
    //   !props.locationPath.includes("/tour") &&
    //   !props.locationPath.includes("/about")
    //     ? "calc(100% - 80px)"
    //     : "auto"};

    padding-top: ${(props) =>
      !props.locationPath.includes("/tour") && props.locationPath !== "/"
        ? "80px"
        : "0px"};

    height: ${(props) =>
    !props.locationPath.includes("/tour") &&
    !props.locationPath.includes("/about")
      ? "calc(100% - 48px)"
      : "auto"};
  min-height: -webkit-fill-available;

    padding-top: ${(props) =>
    !props.locationPath.includes("/tour") && props.locationPath !== "/"
      ? "48px"
      : "0px"};*/

const LandscapeDetector = styled.div`
  @media screen and (max-width: 1024px) and (min-aspect-ratio: 13/9) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: ${(props) => props.theme.bgDark};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 1009;
    &:after {
      content: "Please turn your device to view the website.";
      width: 80%;
      font-family: "Sintony", sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      margin: 0;
      color: ${(props) => props.theme.primaryDark};
      @media (min-width: 1024px) {
        font-family: "Noto Serif", serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: #000;
      }
    }
  }
`;

const PageLayout = ({ loading, setLoading, spinner, setSpinner }) => {
  const isMobile = window.matchMedia("(pointer:coarse)").matches;
  const [openedHotspots, setOpenedHotspots] = useState(false);
  const location = useLocation();
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    store.state = store.initialState; //TODO: this is a workaround because init() doesn't seem to be working, states have to accessed via store.state
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  // scroll to top for every route
  useEffect(() => {
    window.scrollTo(0, 0);
    if (state.mutedBg) store.mutedBg(true);
    if(location.pathname.includes('/light-a-candle') || location.pathname.includes('/about') || location.pathname.includes('/explore-icons')) store.setHeaderThemeColor(theme.navWhite);
    if(location.pathname.includes('/tour') || location.pathname === '/') store.setHeaderThemeColor(theme.navGold);

    store.setMenuOpen(false);
    store.iconListOpen(false);
    store.setOpenHotspots(false);
  }, [location]);

  useEffect(() => {
    if (
      state.hotspotData &&
      state.hotspotData.type === "interactive" &&
      location.pathname === "/tour"
    )
      store.hotspotOpen(true);
    else store.hotspotOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.hotspotData]);

  const setLoadingFunction = (load) => {
    setLoading(load);
  };

  const setSpinnerFunction = (spin) => {
    setSpinner(spin);
  };

  const onOpenedHotspots = (opened) => {
    setOpenedHotspots(opened);
  };

  useEffect(() => {
    console.log(loading, sessionStorage.getItem("headphones"))
  }, [loading])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <CookieConsent openPrefsFromOutside={state.openCookiePrefs} />
      <AnimatePresence key={loading} mode="wait">
        {loading &&
          location.pathname === "/" &&
          !sessionStorage.getItem("headphones") && (
            <HeadphoneOverlay
              spinner={spinner}
              setSpinner={setSpinnerFunction}
              loading={loading}
              setLoading={setLoadingFunction}
            />
          )}
      </AnimatePresence>
      <Header
        openedHotspots={openedHotspots}
        onOpenedHotspots={onOpenedHotspots}
      />
      <StyledContainer locationPath={location.pathname}>
        {isMobile &&
        !location.pathname.includes("/tour") &&
        !state.videoPlaying ? (
          <LandscapeDetector />
        ) : null}
        <Outlet />
      </StyledContainer>

      {state.openedExternalLinkAlert.opened ? (
        <DonationAlert
          setClosed={() => store.openedExternalLinkAlert({ opened: false, type: "" })}
          type={state.openedExternalLinkAlert.type}
          link={state.openedExternalLinkAlert.link}
        />
      ) : null}
    </ThemeProvider>
  );
};

PageLayout.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  spinner: PropTypes.bool,
  setSpinner: PropTypes.func,
};
PageLayout.defaultProps = {
  loading: true,
  setLoading: null,
  spinner: true,
  setSpinner: null,
};

export default PageLayout;
