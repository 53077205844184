// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCBG0HNt5HiyKH7ZM_2c0uQWDRRIAwgS6w',
  authDomain: 'st-nicholas-donors.firebaseapp.com',
  projectId: 'st-nicholas-donors',
  storageBucket: 'st-nicholas-donors.appspot.com',
  messagingSenderId: '635538872920',
  appId: '1:635538872920:web:c46b67274a2564d2ce082d',
  measurementId: 'G-FJXZMCKBLR',
  databaseURL: 'https://st-nicholas-donors.us-central.firebasedatabase.app'
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

// export const auth = getAuth(app);
export default app