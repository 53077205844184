import { ReactComponent as CloseIcon } from "../assets/icons/icon-close.svg";
import styled from "styled-components";

const CloseButtonContainer = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  svg {
    ${(props) => props.topOffset ? `top: ${props.topOffset};` : `top: 1rem;`}
    ${(props) => props.rightOffset && `right: 1rem;`}
     path {
        stroke: ${(props) => props.theme.textDarkDefault};
        fill: ${(props) => props.theme.textDarkDefault};
     }
    stroke: ${(props) => props.theme.textDarkDefault};
  }

  color: ${(props) => props.theme.textDarkDefault};
`;

const CloseButton = ({ action, type }) => (
  <CloseButtonContainer onClick={action} type={type}>
    <CloseIcon topOffset rightOffset/>
  </CloseButtonContainer>
);

export default CloseButton;
