import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import store from "../store";
import styled from "styled-components/macro";
import { useTheme } from "styled-components";
import selectIcon from "../assets/icons/24-icon-arrow-down.svg";

const CustomSelect = styled(Select)`
  & svg {
    display: none;
  }
`;

const LanguageSelector = () => {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState(null);
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  const customSelectStyles = {
    dropdownIndicator: (provided, state) => ({
      width: 32,
      height: 32,
      backgroundImage: `url(${selectIcon})`,
      backgroundSize: 24,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      transform: `${
        state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)"
      }`,
    }),
    control: (provided) => ({
      ...provided,
      width: `9.25rem`,
      height: `40px`,
      fontWeight: "600",
      color: theme.textLightDefault,
      backgroundColor: theme.bgLightDefault,
      boxShadow: `0 0 ${10 / 16}rem rgba(0, 0, 0, 0.13)`,
      border: "none",
      borderRadius: "99em",
      "&:hover": {
        backgroundColor: theme.bgLightClick,
        cursor: 'pointer',
      },
      "&:active": {
        backgroundColor: theme.bgLightClick,
        borderColor: theme.borderLightClick,
      },
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: "unset",
      borderRadius: "2rem",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? theme.bgDarkDefault
        : theme.bgLightDefault,
      padding: ".75rem 1rem",
      textAlign: "center",
      color: state.isSelected ? theme.textDarkDefault : theme.textLightDefault,
      "&:hover": {
        backgroundColor: state.isSelected
          ? theme.bgDarkHover
          : theme.bgLightHover,
        cursor: 'pointer',
      },
    }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
      borderRadius: "5rem",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.textLightDefault,
      textAlign: "center",
    }),
  };

  const options = [
    { value: "en", label: "English" },
    { value: "el", label: "Ελληνικά" },
    { value: "es", label: "Español" },
    { value: "zh-cn", label: "中文(简)" },
    { value: "zh-tw", label: "中文(繁)" },
    { value: "fr", label: "Français" },
    { value: "ar", label: "عربى" },
    { value: "ru", label: "русский" },
    { value: "ja", label: "日本語" },
  ];

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    const initialOption = options.find(
      (opt) => opt.value === state.language.id
    );
    setSelectedOption(initialOption);

    /** set lang from session var */
    if (localStorage.getItem('lang').length > 2) {
      const { lang, id } = JSON.parse(localStorage.getItem('lang'));
      console.log(lang, id);

      handleChange({ label: lang, value: id });
    }

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  const handleChange = async (option) => {
    await setSelectedOption(option);
  };

  useEffect(() => {
    if (selectedOption) {
      store.language({ lang: selectedOption.label, id: selectedOption.value });
    }
  }, [selectedOption]);

  return (
    <CustomSelect
      value={{
        label: `CC ${
          selectedOption ? selectedOption.label : state.language.lang
        }`,
        value: selectedOption ? selectedOption.value : state.language.id,
      }}
      onChange={handleChange}
      options={options}
      menuPlacement="top"
      placeholder={`CC ${state.language.lang}`}
      styles={customSelectStyles}
      isSearchable={false}
      menuPosition={"fixed"}
    />
  );
};

LanguageSelector.propTypes = {
  onCloseList: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onCloseList: null,
};

export default LanguageSelector;
