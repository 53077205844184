import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import styled, { useTheme } from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import store from "../../store";
import Button from "../../components/Button.js";
import CandleBgJpg from "../../assets/images/candle_bg.jpg";
import DesktopBg from "../../assets/images/candle_bg.jpg";
import { ReactComponent as LeftArrow } from "../../assets/icons/32-icon-leftArrow-v2.svg";
import Landing from "./Landing.js";
import Main from "./Main/";
import MapButton from "../../components/MapButton.js";


const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
`;

const BackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margins: 0;
  padding: 0;
  top: 0;
  left: 0;
  background: url(${CandleBgJpg})
    ${(props) => (props.subsection === "result" ? "#000" : "")};
  background-blend-mode: ${(props) =>
    props.subsection === "result" ? "soft-light" : "unset"};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
  &:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(13, 13, 13, 0.8);
    z-index: 2;
  }

  url {
    background-repeat: no-repeat;
  }
  @media (min-width: 1024px) {
    background: url(${DesktopBg});
    ${(props) => (props.subsection === "result" ? "#000" : "")};
    background-position: 50% 70%;
    background-size: ${(props) => (props.subsection === "result" ? "contain" : "auto 100%")};
    z-index: 0;
    background-blend-mode: ${(props) =>
      props.subsection === "result" ? "soft-light" : "unset"};
    }
`;

const Header = styled.div`
  position: absolute;
  left: 0px;
  top: ${56 / 16}rem;
  z-index: 900;
  background-color: transparent;
  width: 50%;
  @media (min-width: 1024px) {
    top: ${88 / 16}rem;
  }
`;

const BackButtonWrapper = styled.div`
  position: relative;
  z-index: 98;
`;

const Candle = () => {
  const [state, setState] = useState(store.initialState);
  const [section, setSection] = useState("landing");
  const [subsection, setSubsection] = useState("initial");
  const [prayer, setPrayer] = useState({});
  const navigate = useNavigate();
  const bgAudio = useRef(null);
  const sub = useRef(null);
  const theme = useTheme();
  const isMobile = window.innerWidth < 970;

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    store.showTour(false);
    store.lastPage("/light-a-candle");
    store.setHeaderThemeColor(theme.navWhite);
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(() => {
    store.setHeaderColorAll(theme.navWhite);
  }, []);

  useEffect(() => {
    if (subsection === "form") store.setCandleForm(true);
    else store.setCandleForm(false);
    store.setHeaderColorAll(theme.navWhite);
  }, [subsection]);

  /** just background audio things */
  useLayoutEffect(() => {
    console.log(state.mutedBg);

    if (!state.mutedBg) bgAudio.current.muted = false;
    else if (state.mutedBg) bgAudio.current.muted = true;

    console.log("muting bg from candle");
  }, [state.mutedBg]);

  return (
    <>
      <Helmet>
        <title>St. Nicholas Tour - Light A Candle</title>
        <meta
          name="description"
          content="Light a candle in prayer to connect with biblical stories, icons, and a light in your life. Choose from the Virgin Mary, Christ, Archangel Gabriel, and more."
        />
        <meta name="keywords" content="Light, Candle, Prayer, Icon" />
      </Helmet>
      <audio
        ref={bgAudio}
        src="/audios/Part_St_Nicholas_-_2nd_Section.mp3"
        autoPlay={true}
        loop={true}
        muted={state.mutedBg}
      />
      <Container subsection={subsection}>
        {section === "landing" && (
          <Header subsection={subsection}>
            <BackButtonWrapper>
              <MapButton
                label="Back"
                action={() => navigate(-1)}
                type="candle"
                icon={<LeftArrow />}
                addClass="icon-first"
              />
            </BackButtonWrapper>
          </Header>
        )}
        <AnimatePresence mode="out in">
          {section === "landing" ? (
            <Landing prayer={prayer} setSection={setSection} />
          ) : null}
          {section === "main" ? (
            <Main
              setSection={setSection}
              subsection={subsection}
              setSubsection={setSubsection}
              prayer={prayer}
              setPrayer={setPrayer}
            />
          ) : null}
        </AnimatePresence>
      </Container>
      <BackgroundImage subsection={subsection} />
    </>
  );
};

Candle.defaultProps = {};
Candle.propTypes = {};

export default Candle;
