import { useState, useRef, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { motion, AnimatePresence } from "framer-motion";
import Button from "../../../components/Button";
import ButtonLeaveMessageDonate from "../../../components/ButtonLeaveMessageDonate";
import SimpleCandle from "../SimpleCandle";
import IconPicker from "./IconPicker";
import CandleBgJpg from "../../../assets/images/candle_bg.jpg";
import leftGradient from "../../../assets/images/Candle/gradient_candle_left_desktop.png";
import rightGradient from "../../../assets/images/Candle/gradient_candle_right_desktop.png";
import store from "../../../store";
import { ReactComponent as RightArrowIcon } from "../../../assets/icons/icon-arrow-right.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/icon-x.svg";
import { ReactComponent as PencilIcon } from "../../../assets/icons/32-icon-pencil-v2.svg";
import { ReactComponent as DonateIcon } from "../../../assets/icons/32-icon-donate.svg";

const InitialContainer = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: relative;
  padding: 0;
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;
`;
const FlexColumn = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  padding-top: 64px;
  z-index: 10;
  @media (min-width: 1024px) {
    padding-top: 80px;
    justify-content: space-between;
  }
`;

const CandleWrapper = styled.div`
  margin-top: 15vh;
  flex-grow: 1;
  @media (min-width: 1024px) {
    margin-top: 22vh;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
`;

const SaintBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 50% 35%;
  z-index: 1;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${(props) =>
      props.isLit
        ? `radial-gradient(
        circle,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 50%,
        rgba(0, 0, 0, 1) 85%
      )`
      : `radial-gradient(
        circle,
        rgba(0, 0, 0, .5) 0%,
        rgba(0, 0, 0, 0.8) 20%,
        rgba(0, 0, 0, 1) 85%
        )`};
    z-index: 2;
  }
`;

const MotionButtonContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  @media(min-width: 1024px) {
    margin-bottom: 80px;
  }
`;

const ResponsiveButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.3vh;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 40%;
  z-index: 9;
  button {
    margin-bottom: 1.5rem;
    width: 50%;
  }
  @media (min-width: 1024px) {
    flex-flow: row nowrap;
    width: 100%;
    height: auto;

    button {
      width: 30%;
    }
  }
  @media (min-width: 1920px) {
    flex-flow: row nowrap;
    height: auto;
    width: 100%;
  }
`;

const Info = styled.div`
  padding: 0 30px;
  color: ${(props) => props.theme.bgLight};
  text-align: center;
  /* position: absolute;
  top: ${36 / 16}rem;
  left: 50%; */
  margin: 0 auto;
  margin-top: 20px;
  width: 82%;
  /* transform: translateX(-50%); */
  z-index: 11;

  h3 {
    font-family: "Noto Serif", serif;
    font-size: ${25 / 16}rem;
    font-weight: bold;
    line-height: ${35 / 16}rem;
    margin-bottom: 1rem;
    color: #fffefa;
    text-shadow: 0pt 0pt 5pt rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  p {
    font-family: "Sintony", sans-serif;
    font-size: 12px; // adjust as needed
    line-height: ${20 / 16}rem;
    color: #fffefa;
    text-shadow: 0pt 0pt 2pt rgba(0, 0, 0, 0.5);
  }

  @media (min-width: 1366px) {
    h3 {
      font-size: 32px; // adjust as needed
      line-height: 40px;
    }
    p {
      font-size: 14px; // adjust as needed
      line-height: 20px;
    }
  }
`;

const Initial = ({ setSubsection, chosenIcon, setChosenIcon }) => {
  const [openOnIndex, setOpenOnIndex] = useState(-1);
  const [openIconOverlay, setOpenIconOverlay] = useState(false);
  const [isLit, setIsLit] = useState(false);
  const [pickedBoth, setPickedBoth] = useState([false, false]);
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);
  const isMobile = window.innerWidth < 1024;

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  const setToggleDonationAlert = () => {
    store.openedExternalLinkAlert({ opened: true, type: "donation" });
  };

  return (
    <InitialContainer
      layoutId="initial"
      animate={{ transform: "translateX(0%)" }}
      initial={{ transform: "translateX(100%)" }}
      exit={{ transform: "translateX(-100%)" }}
      transition={{ duration: 0.6 }}
    >
      {chosenIcon !== null ? (
        <SaintBackground
          key="icon-bg"
          style={{ backgroundImage: `url(${chosenIcon.imgUrl})` }}
          isLit={isLit}
        />
      ) : null}
      <AnimatePresence>
        {openIconOverlay ? (
          <IconPicker
            key="icon-picker"
            openOnIndex={openOnIndex}
            setOpenIconOverlay={setOpenIconOverlay}
            setChosenIcon={setChosenIcon}
          />
        ) : (
          <FlexColumn key="flex-column">
            <Info>
              <h3>Light Your Candle</h3>
              {!pickedBoth[0] && (
                <p>
                  Candles remind us of the light in our lives. Orthodox
                  Christians often choose to light a candle in front of a
                  specific icon.
                </p>
              )}
            </Info>
            <CandleWrapper>
              <SimpleCandle
                pickedBoth={pickedBoth}
                setPickedBoth={setPickedBoth}
                lighting={true}
                isLit={false}
                setIsLit={setIsLit}
              />
            </CandleWrapper>

            <AnimatePresence>
              {!pickedBoth[1] ? (
                !pickedBoth[0] && (
                  <MotionButtonContainer key="buttons-anim">
                    <Button
                      type="light"
                      label="Choose an Icon"
                      addStyle={{
                        marginBottom: "1.5rem",
                        paddingRight: "12px",
                        paddingLeft: "12px",
                      }}
                      action={() => {
                        setPickedBoth([true, false]);
                        setOpenOnIndex(0);
                        setOpenIconOverlay(true);
                      }}
                      icon={<RightArrowIcon />}
                    />
                    <Button
                      type="dark"
                      label="No Icon"
                      addStyle={{
                        border: "1px solid #816920",
                      }}
                      icon={<XIcon />}
                      action={() => {
                        setOpenIconOverlay(false);
                        setPickedBoth([true, false]);
                        setChosenIcon(null);
                      }}
                    />
                  </MotionButtonContainer>
                )
              ) : (
                <MotionButtonContainer
                  key="other-buttons-anim"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ delay: 0.6 }}
                >
                  <ResponsiveButtonContainer>
                    <ButtonLeaveMessageDonate
                      type="light"
                      label="Leave A Message&nbsp;"
                      action={() => setSubsection("form")}
                      addStyle= {isMobile ? {marginBottom: '9.5px', width: '15rem'} : {marginRight: '1.5rem', width: '15rem'}}
                      icon={<PencilIcon />}
                    />
                    <ButtonLeaveMessageDonate
                      type="light"
                      label="Make A Donation"
                      action={setToggleDonationAlert}
                      addStyle={
                        isMobile
                          ? { width: "15rem" }
                          : { marginRight: "1.5rem", width: "15rem" }
                      }
                      icon={<DonateIcon />}
                    />
                  </ResponsiveButtonContainer>
                </MotionButtonContainer>
              )}
            </AnimatePresence>
          </FlexColumn>
        )}
      </AnimatePresence>
    </InitialContainer>
  );
};

Initial.propTypes = {
  setSubsection: PropTypes.func,
  chosenIcon: PropTypes.object,
  setChosenIcon: PropTypes.func,
};
Initial.defaultProps = {
  setSubsection: null,
  chosenIcon: null,
  setChosenIcon: null,
};

export default Initial;
