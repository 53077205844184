/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import store from "../store";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import { ReactComponent as PlayIcon } from "../assets/icons/32-icon-play.svg";
import { ReactComponent as LocationIcon } from "../assets/icons/32-icon-location-v2.svg";
import { Helmet } from "react-helmet";
import Button from "../components/Button";
import { ReactComponent as LargeCloseIcon } from "../assets/icons/48-icon-close.svg";
import { useTheme } from "styled-components/macro";

/** Landing Component */
const LogoStyled = styled(motion(Logo))`
  width: 100%;
  height: 40px;
  margin-top: 30vh;
  path {
    fill: ${(props) => props.theme.bgLight};
  }
  @media (min-width: 1024px) {
    height: 58px;
    margin-top: 5em;
  }
  @media (min-width: 1920px) {
    height: 70px;
    margin-top: 9em;
  }
`;

const Title = styled(motion.h1)`
  position: relative;
  font-family: "Noto Serif", serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  color: ${(props) => props.theme.bgLight};
  margin-top: 2.8vh;
  margin-left: auto;
  margin-right: auto;
  transition: opacity 500ms ease-out;
  z-index: 20;
  @media (min-width: 1024px) {
    font-size: 45px;
    line-height: 55px;
    margin-top: 2.5em;
    width: 750px;
  }
  @media (min-width: 1920px) {
    font-size: 60px;
    line-height: 75px;
    width: 896px;
  }
`;

const ResponsiveButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.3vh;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 40%;
  button {
    margin-bottom: 1.5rem;
    width: 50%;
  }
  @media (min-width: 1024px) {
    flex-flow: row nowrap;
    margin-top: 10rem;
    width: 100%;
    height: auto;

    button {
      width: 30%;
    }
  }
  @media (min-width: 1920px) {
    flex-flow: row nowrap;
    margin-top: 10rem;
    height: auto;
    width: 100%;
  }
`;

const Subtitle = styled(motion.h2)`
  font-family: "Sintony", sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  margin-top: 2.3vh;
  margin-left: auto;
  margin-right: auto;
  color: ${(props) => props.theme.bgLight};
  width: 90%;
  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
    margin-top: 2em;
    width: 100%;
  }
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  &::cue {
    size: 10%;
  }
`;

const Gradient = styled.div`
  position: absolute;
  top; 0; left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  z-index: 3;
`;

const StyledMotionContainer = styled(motion(Container))`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  min-height: 100vh;
  &.headphone-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: fixed;
  }
`;
const StyledRow = styled(Row)`
  z-index: 10;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

const Landing = ({ loading }) => {
  const [state, setState] = useState(store.initialState);
  const [showVideo, setShowVideo] = useState(false);
  const [hideTitle, setHideTitle] = useState(false);
  const [videoTime, setVideoTime] = useState(0);
  const [topLandingRef, topLandingInView] = useInView({ threshold: 0.1 });
  const [bottomLandingInRef, bottomLandingInView] = useInView();
  // const [introVideoSrc, setIntroVideoSrc] = useState("");
  const navigate = useNavigate();
  const video = useRef(null);
  const sub = useRef(null);
  const isMobile = window.innerWidth < 1024;
  const theme = useTheme();

  const closeVideo = () => {
    setVideoTime(video.current.currentTime);
    setShowVideo(false);
    video.current.pause();
    store.setVideoPlaying(false);
  };

  const handleVideo = (isOpen) => {
    setShowVideo(isOpen);
    store.setVideoPlaying(isOpen);
  };

  /** autoplay video on click */
  useEffect(() => {
    if (showVideo && video.current) {
      video.current.muted = false;
      video.current.textTracks[0].mode = 'hidden';
      video.current.play();

      video.current.onended = () => {
        closeVideo();
      };
    }
  }, [showVideo]);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    store.lastPage("/");

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(() => {
    console.log("top landing section is in view: ", topLandingInView);
    store.setLandingHeader(!topLandingInView);
  }, [topLandingInView]);

  useEffect(() => {
    console.log("bottom landing section is in view: ", bottomLandingInView);
    if (bottomLandingInView) store.showTour(false);
  }, [bottomLandingInView]);

  const videoSourcePerMedia = () => {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      return (
        <source src="/videos/SN_WelcomeMessage_Final_md.mp4" type="video/mp4" />
      );
    }

    return (
      <source src="/videos/SN_WelcomeMessage_Final_lo.mp4" type="video/mp4" />
    );
  };

  return (
    <>
      <Helmet>
        <title>St. Nicholas Greek Orthodox Church Tour</title>
        <meta
          name="description"
          content="Navigate the Saint Nicholas Greek Orthodox Church and National Shrine at the World Trade Center with the assistance of an immersive, auto-narrated tour guide."
        />
        <meta
          name="keywords"
          content="Shrine, Tour, 911, Welcome, Saint Nicholas Greek Orthodox Church, St. Nicholas Greetk Orthodox Church"
        />
      </Helmet>
      {showVideo ? (
        <div>
          <VideoBackground
            ref={video}
            muted={true}
            controls={true}
            controlsList="nodownload"
            playsInline={true}
            loop={false}
            autoPlay={false}
            preload="metadata"
            crossorigin="anonymous"
            poster="/videos/poster.jpg"
          >
            {videoSourcePerMedia()}
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src="/videos/welcomeVTT_new.vtt"
              default
            />
            <track
              label="Ελληνικά"
              kind="subtitles"
              srcLang="el"
              src="/videos/welcome_video_captions_gre_final.vtt"
            />
            <track
              label="Español"
              kind="subtitles"
              srcLang="es"
              src="/videos/welcome_video_captions_spa-M9.vtt"
            />
            <track
              label="中文(简)"
              kind="subtitles"
              srcLang="zh-cn"
              src="/videos/welcome_video_captions_zho-CN.vtt"
            />
            <track
              label="中文(繁)"
              kind="subtitles"
              srcLang="zh-tw"
              src="/videos/welcome_video_captions_zho-CN-traditional.vtt"
            />
            <track
              label="Français"
              kind="subtitles"
              srcLang="fr"
              src="/videos/welcome_video_captions_fre-FR.vtt"
            />
            <track
              label="عربى"
              kind="subtitles"
              srcLang="ar"
              src="/videos/welcome_video_captions_ara.vtt"
            />
            <track
              label="русский"
              kind="subtitles"
              srcLang="ru"
              src="/videos/welcome_video_captions_rus.vtt"
            />
            <track
              label="日本語"
              kind="subtitles"
              srcLang="ja"
              src="/videos/welcome_video_captions_jpn.vtt"
            />
          </VideoBackground>
          <Button
            type="exit"
            icon={<LargeCloseIcon />}
            action={() => closeVideo()}
            classState="white-outline welcome-video-position"
          />
        </div>
      ) : (
        <motion.div
          style={{
            zIndex: 11,
            width: "100%",
            height: "auto",
            position: "relative",
          }}
        >
          <StyledMotionContainer fluid ref={topLandingRef}>
            <StyledRow>
              <Col
                md={8}
                lg={12}
                xl={12}
                style={{ position: "relative", zIndex: 10 }}
              >
                <LogoStyled
                  initial={{ opacity: loading ? 0 : 0 }}
                  animate={{ opacity: loading ? 0 : 1 }}
                  transition={{ delay: 0.5, duration: 1 }}
                />
                <Title
                  initial={{ opacity: loading ? 0 : 0 }}
                  animate={{ opacity: loading ? 0 : 1 }}
                  transition={{ delay: 1, duration: 1 }}
                >
                  Welcome to the Saint Nicholas National Shrine
                </Title>
                <Subtitle
                  initial={{ opacity: loading ? 0 : 0 }}
                  animate={{ opacity: loading ? 0 : 1 }}
                  transition={{ delay: 1.5, duration: 1 }}
                >
                  This is an immersive, audio-narrated tour that guides you
                  through the Shrine.
                </Subtitle>
                <ResponsiveButtonContainer>
                  <Button
                    label="Intro Film"
                    icon={<PlayIcon />}
                    type="light"
                    addStyle={
                      isMobile
                        ? { marginBotton: "9.5px" }
                        : { marginRight: "1.5rem" }
                    }
                    action={() => handleVideo(true)}
                  />
                  <Button
                    label="Start Tour"
                    icon={<LocationIcon />}
                    type="light"
                    addStyle={isMobile ? {} : { marginRight: "1.5rem" }}
                    action={() => {
                      store.setHeaderThemeColor(theme.navGold);
                      navigate("/tour");
                    }}
                    iconHeight={32}
                    iconWidth={32}
                  />
                </ResponsiveButtonContainer>
              </Col>
            </StyledRow>
            <Gradient />
            <VideoBackground
              style={{
                objectPosition: "50% 70%",
              }}
              muted={true}
              controls={false}
              playsInline={true}
              loop={true}
              autoPlay={true}
              poster="/videos/poster_landing.jpg"
            >
              <source src="/videos/Drone_Landing_v4_15s.mp4" type="video/mp4" />
            </VideoBackground>
          </StyledMotionContainer>
        </motion.div>
      )}
    </>
  );
};

export default Landing;
