import React, { useRef, useLayoutEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import store from '../store';
import { ReactComponent as CloseIcon } from '../assets/icons/48-icon-close.svg';
import Button from './Button';

const MenuContainer = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0; left: 0;
  background: transparent;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 105;
  @media (min-width: 1024px) {
    height: calc(100vh - 80px);
    top: 80px;
    width: ${470 / 16}rem;
    left: calc(100vw - ${470 / 16}rem);
  }
`;

const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  color: ${props => props.theme.primaryDark};
  padding: 1.2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  display: inline-block;
  text-align: center;
  margin-right: ${14 / 16}rem
  font-family: 'Sintony', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  z-index: 99;
  @media (min-width: 1024px) {
    height: 56px;
    & span {
      font-size: 1rem;
    }
  }
`;

const MenuWrapper = styled.div`
  box-shadow: inset 1px 4px 9px -6px #000;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.bgLight};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  z-index: 98;
`;

const MenuItem = styled.button`
  width: 100%;
  height: 7vh;
  background: ${props => props.theme.bgLight};
  border-bottom: 1px solid ${props => props.theme.primary7};
  color: ${props => props.theme.primaryDark};
  text-align: center;
  text-decoration: none;
  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  &:hover {
    color: ${props => props.theme.primaryDark};
  }
  &.title2 {
    line-height: 7vh;
    font-size: 100%;
  }
  &.border-top {
    border: none;
    border-top: 1px solid ${props => props.theme.primary7};
  }
  &.active,
  &:active {
    background: ${props => props.theme.primaryDark};
    color: ${props => props.theme.bgLight};
    & svg path,
    & svg tspan {
      fill: ${props => props.theme.bgLight};
    }
  }
  @media (min-width: 1024px) {
    &.title2 {
      line-height: 7vh;
    }
    &:hover {
      background: ${props => props.theme.bgDark};
      color: ${props => props.theme.primaryDark};
    }
  }
  @media (min-width: 1366px) {
    &.title2 {
      line-height: 7vh;
      font-size: 100%;
    }
  }
  @media (min-width: 1920px) {
    &.title2 {
      line-height: 7vh;
      font-size: 125%;
    }
  }
`;

const LanguageList = ({ onCloseList }) => {
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    if (localStorage.getItem('lang')) store.language(JSON.parse(localStorage.getItem('lang'))); 

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  return(
    <>
      <MenuContainer
        key="language-list"
        initial={{ opacity: 0, transform: window.innerWidth >= 1024 ? 'translateX(100%)' : 'translateY(100%)' }}
        animate={{ opacity: 1, transform: window.innerWidth >= 1024 ? 'translateX(0%)' : 'translateY(0%)' }}
        exit={{ opacity: 0, transform: window.innerWidth >= 1024 ? 'translateX(100%)' : 'translateY(100%)' }}
        transition={{ duration: 0.4, type: 'tween', ease: 'easeInOut' }}
      >
        {/* <CloseContainer onClick={() => onCloseList()}> */}
          <Button
            type="tertiary"
            icon={<CloseIcon />}
            addStyle={{
              position: 'absolute', 
              top: '1rem',
              right: '1rem',
              zIndex: 100
            }}
            action={onCloseList}
          />
        {/* </CloseContainer> */}

        <Title>Captions Language</Title>
        <MenuWrapper>
          <MenuItem
            onClick={() => { store.language({ lang: 'English', id: 'en' }); onCloseList(); }}
            className={state.language.id === 'en' ? 'active' : ''}
          >
            English
          </MenuItem>
          <MenuItem
            onClick={() => { store.language({ lang: 'Ελληνικά', id: 'el' }); onCloseList(); }}
            className={state.language.id === 'el' ? 'active' : ''}
          >
            Ελληνικά
          </MenuItem>
          <MenuItem
            onClick={() => { store.language({ lang: 'Español', id: 'es' }); onCloseList(); }}
            className={state.language.id === 'es' ? 'active' : ''}
          >
            Español
          </MenuItem>
          <MenuItem
            onClick={() => { store.language({ lang: '中文(简)', id: 'zh-cn' }); onCloseList(); }}
            className={state.language.id === 'zh-cn' ? 'active' : ''}
          >
            中文(简)
          </MenuItem>
          <MenuItem
            onClick={() => { store.language({ lang: '中文(繁)', id: 'zh-tw' }); onCloseList(); }}
            className={state.language.id === 'zh-tw' ? 'active' : ''}
          >
            中文(繁)
          </MenuItem>
          <MenuItem
            onClick={() => { store.language({ lang: 'Français', id: 'fr' }); onCloseList(); }}
            className={state.language.id === 'fr' ? 'active' : ''}
          >
            Français
          </MenuItem>
          <MenuItem
            onClick={() => { store.language({ lang: 'عربى', id: 'ar' }); onCloseList(); }}
            className={state.language.id === 'ar' ? 'active' : ''}
          >
            عربى
          </MenuItem>
          <MenuItem
            onClick={() => { store.language({ lang: 'русский', id: 'ru' }); onCloseList(); }}
            className={state.language.id === 'ru' ? 'active' : ''}
          >
            русский
          </MenuItem>
          <MenuItem
            onClick={() => { store.language({ lang: '日本語', id: 'ja' }); onCloseList(); }}
            className={state.language.id === 'ja' ? 'active' : ''}
          >
            日本語
          </MenuItem>
        </MenuWrapper>
      </MenuContainer>
    </>
  );
};

LanguageList.propTypes = {
  onCloseList: PropTypes.func,
};

LanguageList.defaultProps = {
  onCloseList: null,
};

export default LanguageList;
