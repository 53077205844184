/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components/macro";
import { motion, AnimatePresence } from "framer-motion";
import { svgAsPngUri, saveSvgAsPng } from "save-svg-as-png";
import store from "../../../store";
import Dropdown from "react-bootstrap/Dropdown";
import SimpleCandle from "../SimpleCandle";
import { ReactComponent as DonateIcon } from "../../../assets/icons/48-icon-donate.svg";
import { ReactComponent as CandleIcon } from "../../../assets/icons/32-icon-candles.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/icon-check-circled.svg";
import ArrowIcon from "../../../assets/icons/icon-uparrow.svg";

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: black;
`;

// const ArrowIconDown = styled(ArrowIcon)`
//   transform: rotate(-90deg);
// `;

// const ArrowIconUp = styled(ArrowIcon)`
//   transform: rotate(90deg);
// `;

const PrayerContainer = styled.div`
  //padding: 20px;
  //padding-bottom: 54px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 10;
`;
const MainText = styled.div`
  color: #fff;
  font-family: "Noto Serif", serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  width: 100%;
  text-align: center;
  position: relative;
  top: 12%;
  padding: 0 10px;
  z-index: 10;
  @media (min-width: 1366px) and (min-height: 1024px) {
    top: 12%;
    width: ${500 / 16}rem;
    text-align: center;
    font-size: 2.5em;
    transform: translateX(30%);
    line-height: normal;
  }
`;
const SecondaryText = styled.div`
  font-family: "Sintony", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  position: relative;
  top: 12% !important;
  z-index: 10;
  @media (min-width: 1366px) and (min-height: 1024px) {
    top: 60%;
    font-size: 1em;
    line-height: normal;
  }
`;

const StyledDropdown = styled(Dropdown)`
  position: relative;
  width: auto;
  z-index: 20;
  text-align: center;
`;
const StyledDropdownToggle = styled(Dropdown.Toggle)`
  border: none;
  font-family: "Sintony";
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  width: 60%;
  height: 40px;
  margin: 0;
  margin-left: 20%;
  padding: 0 16px;
  border-radius: 99em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: ${(props) => props.theme.primaryDark};
  background-color: ${(props) => props.theme.bgLight};
  border: 1px solid ${(props) => props.theme.primaryDark};

  @media (min-width: 1024px) {
    width: ${240 / 16}rem;
    margin: 0;
    font-size: 16px;
    padding: 0 24px;
    text-align: center;
  }
  &.dropdown-toggle:after {
    content: "";
    width: 32px;
    height: 32px;
    border: none;
    text-align: center;
    background-image: url(${ArrowIcon});
    background-size: 32px;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.dropdown-toggle[aria-expanded="true"]:after {
    content: "";
    height: 32px;
    border: none;
    text-align: center;
    background-image: url(${ArrowIcon});
    background-size: 32px;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }
  &.btn-success:active,
  &.btn-success:hover,
  &.btn-success:focus,
  &.btn-success.dropdown-toggle {
    background-color: ${(props) => props.theme.bgDark} !important;
    border-color: ${(props) => props.theme.primaryDark} !important;
    color: ${(props) => props.theme.primaryDark} !important;
    box-shadow: none !important;
    text-align: center;
  }
  &:hover,
  &:active {
    cursor: pointer;
  }
  @media (min-width: 1344px) {
    &:hover {
      background-color: ${(props) => props.theme.bgDark};
    }
  }
  @media (min-width: 1920px) {
    font-size: 18px;
    min-width: 116px;
    height: 48px;
    padding: 0 30px;
  }
`;
const StyledDropdownMenu = styled(Dropdown.Menu)`
  position: absolute;
  width: 240px;
  border-radius: 2rem;
  overflow: hidden;

  @media (min-width: 1024px) {
    width: 240px;
    background-color: ${(props) => props.theme.bgLight};
    //transform: translate3d(-50%, -34px, 0px) !important;
    left: 50% !important;
    border-radius: 2rem;
    padding: 0 0.5rem 2.5rem 0.5rem;
    display: none;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    padding-bottom: 2.5rem;
    z-index: -1;
    top: -6rem !important;
    bottom: 2rem !important;
    transform: translate3d(-50%, 0, 0px) !important;
  }
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  font-family: "Sintony", sans-serif;
  font-size: ${14 / 16}rem;
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  white-space: nowrap;
  color: ${(props) => props.theme.textLightDefault};
  border: none;
  width: 100%;
  overflow: hidden;
  margin: 0 !important;
  &.copyLink,
  &.download {
    background-color: ${(props) => props.theme.bgDarkDefault};
    color: ${(props) => props.theme.textDarkDefault};
    border-radius: 0rem !important;
    width: 120%;
    // width: 300%;
    // transform: translateX(-15%);
  }

  @media (min-width: 1024px) {
    background-position: right;
    background-size: 32px;
    background-repeat: no-repeat;
    z-index: 0;
    position: relative;
    border-bottom: 1px solid #ccc;
    transform: translateX(-15%);
    width: 150% !important;
    border radius: 0rem !important;
    &.copyLink,
    &.download {
      background-color: ${(props) => props.theme.bgDarkDefault};
      color: ${(props) => props.theme.textDarkDefault};
    }
    &:last-child {
      border-bottom: none;
    }
    & svg {
      width: 32px;
      height: 32px;
      & circle,
      path {
        stroke: ${(props) => props.theme.textDarkDefault};
      }
    }
    &:active {
      background-color: #f4f3f0;
    }
  }
`;

const spin = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg); }
  to { transform: translate(-50%, -50%) rotate(360deg); }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    top: 25%;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border-top: 2px solid #a78724;
    border-right: 2px solid #a78724;
    border-left: 2px solid #a78724;
    animation: ${spin} 500ms infinite ease;
  }
`;

const Svg = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 1;
  @media (min-width: 1024px) {
    width: 56.25vh;
    height: 100vh;
  }
  @media (min-width: 1366px) and (min-height: 768px) {
    width: 75vh;
    height: 100vh;
  }
`;

const ForgeignObjectTop = styled.foreignObject`
  x: 0;
  y: 0;
  width: 100%;
  height: 125vh;
  @media (min-width: 1024px) {
    width: 56.25vh;
    height: 135vh;
  }
  @media (min-width: 1366px) and (min-height: 768px) {
    width: 75vh;
    height: 135h;
  }
  &::after {
    content: "";
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 120%;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 15%,
      rgba(0, 0, 0, 0.6) 60%
    );
  }
`;

const ForgeignObjectBottom = styled.foreignObject`
  x: 0;
  y: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  @media (min-width: 1024px) {
    width: 56.25vh;
    height: 100vh;
  }
  @media (min-width: 1366px) and (min-height: 768px) {
    width: 75vh;
    height: 100vh;
  }
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  bottom: 5%;
  width: 100%;
  height: 100%;
  background: transparent;

  button {
    margin-left: ${40 / 16}rem;
    margin-right: ${40 / 16}rem;
    margin-bottom: ${25 / 16}rem;
    padding: 0;
    white-space: nowrap;
    font-size: ${16 / 16}rem;
    width: 240px;
    background: ${(props) => props.theme.bgLight};
    color: ${(props) => props.theme.textLightDefault};
    font-family: "Sintony", sans-serif;
    font-weight: bold;
    border-radius: 99em;
    height: ${48 / 16}rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    svg {
      width: 40;
      height: 40;
    }
  }
  .goToGallery {
    background: ${(props) => props.theme.primaryDark};
    color: ${(props) => props.theme.textDarkDefault};
  }
  @media (min-width: 1024px) {
    /* bottom: ${205 / 16}rem; */
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: center;
    width: 840px;

    button {
      margin-left: ${40 / 16}rem;
      margin-right: ${40 / 16}rem;
      padding: 0;
      white-space: nowrap;
      font-size: ${16 / 16}rem;
      width: 240px;
      background: ${(props) => props.theme.bgLight};
      color: ${(props) => props.theme.textLightDefault};
      font-family: "Sintony", sans-serif;
      font-weight: bold;
      border-radius: 99em;
      height: ${48 / 16}rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      svg {
        width: 40;
        height: 40;
      }
    }
    .goToGallery {
      background: ${(props) => props.theme.primaryDark};
      color: ${(props) => props.theme.textDarkDefault};
    }

    button:nth-child(2) {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const Result = ({ prayer, overlayOpen, setOverlayOpen }) => {
  const [submittedCandle, setSubmittedCandle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pngUri, setPngUri] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [type, setType] = useState("");
  const prayerElement = useRef(null);
  const svgElement = useRef(null);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const setToggleDonationAlert = () => {
    store.openedExternalLinkAlert({ opened: true, type: 'donation'} );
  };

  useEffect(() => {
    return () => {
      setType("");
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log(type);
    if (type === "copyLink" || type === "download") {
      setLoading(true);
      const iconImg = new Image();
      const fileName = `StNicholas_CandleLighting_${prayer.icon.name.replace(
        /\s/g,
        ""
      )}_${prayer.initials}`;
      iconImg.onload = () => {
        svgAsPngUri(svgElement.current).then((uri) =>
          svgAsPngUri(svgElement.current).then((uri) => {
            setPngUri(uri);
            //setOverlayOpen(true);
            type === "download" &&
              saveSvgAsPng(svgElement.current, `${fileName}.png`);
            
            type === "copyLink" && navigator.clipboard.writeText(window.location.href);
            setLoading(false);
          })
        );
      };
      iconImg.src = prayer.icon.imgUrl;
    } else if (type === "email") {
      setLoading(true);
      const iconImg = new Image();
      iconImg.onload = () => {
        svgAsPngUri(svgElement.current).then((uri) => {
          svgAsPngUri(svgElement.current).then((uri) => {
            setPngUri(uri);
            setOverlayOpen(true);
            setLoading(false);
          });
        });
      };
      iconImg.src = prayer.icon.imgUrl;
    }
  }, [type]);

  useEffect(() => {
    if (!overlayOpen) setType("");
  }, [overlayOpen]);

  const constructPrayer = (prayerObj) => {
    let offeringFor = prayerObj.offering;
    let prayerPhrase = `${prayerObj.verb} for ${prayerObj.noun}`;
    if (
      offeringFor === "friend" ||
      offeringFor === "family" ||
      offeringFor === "community"
    ) {
      offeringFor = "my " + offeringFor;
    } else if (offeringFor === "911") {
      offeringFor = "those who lost their lives on 9/11";
    }

    if (prayerObj.verb === "" || prayerObj.noun === "") {
      prayerPhrase = "offering a candle";
    }

    if (prayerObj.verb === "thinking") {
      prayerPhrase = `${prayerObj.verb} about ${prayerObj.noun}`;
    }

    return `I'm ${prayerPhrase} for ${offeringFor}.`;
  };

  return (
    <Wrapper
      layoutId="result"
      animate={{ transform: "translateX(0%)", opacity: 1 }}
      initial={{ transform: "translateX(1000%)", opacity: 0 }}
      exit={{ transform: "translateX(-100%)", opacity: 0 }}
      transition={{ duration: 0.6 }}
      ref={prayerElement}
    >
      <PrayerContainer>
        {loading ? <Loader className="remove-when-downloading" /> : null}
        <StyledButtonContainer>
          {!isMobile && (
            <>
              <button
                onClick={() => window.location.reload()}
                className="goToGallery"
              >
                Go To The Gallery <CandleIcon />
              </button>
              <button onClick={setToggleDonationAlert}>
                Make a Donation <DonateIcon />
              </button>
            </>
          )}
          <StyledDropdown
            className="remove-when-downloading"
            drop="up"
            role="menu"
            onSelect={(eventKey, event) => console.log(eventKey, event)}
          >
            <StyledDropdownToggle variant="success" id="dropdown-basic">
              Share / Download
            </StyledDropdownToggle>
            <StyledDropdownMenu>
              <StyledDropdownItem
                role="menuitem"
                as="button"
                onClick={() => {
                  setType("copyLink");
                }}
                className={type === "copyLink" ? "copyLink" : ""}
              >
                {type === "copyLink" ? (
                  <>
                    Link Copied <CheckIcon />
                  </>
                ) : (
                  "Copy Link"
                )}
              </StyledDropdownItem>
              <StyledDropdownItem
                role="menuitem"
                as="button"
                className={type === "download" ? "copyLink" : ""}
                onClick={() => {
                  setType("download");
                }}
              >
                {type === "download" ? (
                  <>
                    Downloading <CheckIcon />
                  </>
                ) : (
                  "Download Image"
                )}
              </StyledDropdownItem>
            </StyledDropdownMenu>
          </StyledDropdown>
          {isMobile && (
            <>
              <button onClick={setToggleDonationAlert}>
                Make a Donation <DonateIcon />
              </button>
              <button
                onClick={() => window.location.reload()}
                className="goToGallery mobile"
              >
                Go To The Gallery <CandleIcon />
              </button>
            </>
          )}
        </StyledButtonContainer>
      </PrayerContainer>

      <Svg ref={svgElement}>
        <radialGradient
          id="d"
          cx=".5"
          cy=".425"
          gradientTransform="matrix(1 0 0 .462 0 .229)"
          gradientUnits="objectBoundingBox"
          r=".597"
        >
          <stop offset="0" stopOpacity="0" />
          <stop offset=".165" stopOpacity=".204" />
          <stop offset="1" />
        </radialGradient>
        <image
          height="100%"
          width="100%"
          preserveAspectRatio="xMidYMin slice"
          xlinkHref={`${prayer.icon.imgUrl}`}
          src={`${prayer.icon.imgUrl}`}
        />
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="url(#d)"
          opacity=".85"
        />
        <g>
          <ForgeignObjectTop width="100%" height="100%">
            <SimpleCandle addClass="result" />
          </ForgeignObjectTop>
        </g>
        <g fill="#fff">
          <ForgeignObjectBottom width="100%" height="100%">
            <MainText>{constructPrayer(prayer)}</MainText>
            <SecondaryText>
              {prayer.initials.toUpperCase()}&nbsp;
              {prayer.state
                ? `${prayer.state}, ${prayer.country}`
                : `${prayer.country}`}
            </SecondaryText>
          </ForgeignObjectBottom>
        </g>
      </Svg>
    </Wrapper>
  );
};

Result.propTypes = {
  prayer: PropTypes.object,
  setSubsection: PropTypes.func,
  setSection: PropTypes.func,
};
Result.defaultProps = {
  prayer: {},
  setSubsection: null,
  setSection: null,
};

export default Result;
