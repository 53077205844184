import React from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const LoadingOverlayWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${(props) => props.height}px;
  ${(props) =>
    props.backgroundImage &&
    `
    background-image: url(${props.backgroundImage});
    background-position: center;
    background-size: cover;
  `}
  background-color: #f4f3f0;
  z-index: 10000;
  ${(props) =>
    props.backgroundImage &&
    `
  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 254, 250, 0.85);
  }`}
`;

const LoaderWrapper = styled.div`
  position: absolute;
  bottom: ${111 / 16}rem; // Changed from top to bottom
  left: 50%;
  transform: translate(-50%, -50%); // Centering horizontally
  background-color: transparent;
  width: ${48 / 16}rem;
  height: ${48 / 16}rem;
  z-index: 10005;

  @media (min-width: 2100px) {
    width: 64px;
    height: 64px;
  }

`;

const LoaderTagline = styled.div`
  position: absolute;
  font-family: "Noto Serif", sans-serif;
  font-size: ${25 / 16}rem;
  font-weight: bold;
  color: ${(props) => props.theme.textLightDefault};
  top: ${364 / 16}rem;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  z-index: 10005;
  @media (min-width: 1024px) {
    font-size: 40px;
    font-weight: 700;
  }
  @media (min-width: 2100px) {
    font-size: 60px;
  }
`;

const Subtext = styled.div`
  position: absolute;
  font-family: "Sintony", sans-serif;
  font-size: ${14 / 16}rem;
  color: ${(props) => props.theme.textLightDefault};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10005;
  white-space: nowrap;

  @media (min-width: 1024px) {
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    margin-top: 20px;
  }
  @media (min-width: 2100px) {
    font-size: 25px;
    margin-top: 2rem;
  }
  top: calc(
    ${364 / 16}rem + ${25 / 16}rem + ${12 / 16}rem
  );
`;

const Blurb = styled.div`
  font-family: "Noto Serif", sans-serif;
  font-style: italic;
  font-size: ${10 / 16}rem;
  text-align: center;
  color: ${(props) => props.theme.textLightDefault};
  position: absolute; // Making it absolute for specific positioning
  bottom: ${20 / 16}rem; // Adjust as needed
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10005;

  @media (min-width: 2100px) {
    font-size: 20px;
  }
`;

const LoadingOverlay = ({
  height,
  percentLoaded = 0,
  tagline,
  subtagline,
  hideBlurb = false,
  backgroundImage,
}) => {
  return (
    <LoadingOverlayWrapper height={height} backgroundImage={backgroundImage}>
      <LoaderTagline>{tagline}</LoaderTagline>
      <Subtext>{subtagline}</Subtext>
      <LoaderWrapper>
        <CircularProgressbar
          value={percentLoaded}
          text={`${percentLoaded}%`}
          styles={buildStyles({
            textColor: "#816920",
            pathColor: "#816920",
            trailColor: "transparent",
          })}
        />
      </LoaderWrapper>
      {!hideBlurb && (
        <Blurb>
          This experience is generated from
          <br />
          3D photogrammetry reconstruction
        </Blurb>
      )}
    </LoadingOverlayWrapper>
  );
};

export default LoadingOverlay;
