import { useState, useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import Form from "react-bootstrap/Form";
import Button from "../../../components/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Select from "react-select";
import errorIcon from "../../../assets/icons/32-icon-error.svg";
import selectIcon from "../../../assets/icons/24-icon-arrow-down.svg";
import checkedIcon from "../../../assets/icons/24-icon-checked.svg";
import countries from "../../../assets/data/countries.json";
import states from "../../../assets/data/states.json";
import { ReactComponent as UpIcon } from "../../../assets/icons/32-icon-up.svg";

import app from "../../../config/fbConfig.js";
import { getFirestore, addDoc, collection } from "firebase/firestore";

const FormWrapper = styled(motion(Container))`
  margin-top: 5%;
  z-index: 11;
  width: ${350 / 16}rem;
  height: 100%;
  background-color: transparent;
  color: ${(props) => props.theme.textFormControls};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 64px;
  @media (min-width: 1024px) {
    justify-content: center;
    padding-top: 80px;
    margin: 0;
    width: ${584 / 16}rem;
  }
`;

const RadialGradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: -webkit-radial-gradient(circle farthest-corner at center center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 35%);
  background: -moz-radial-gradient(circle farthest-corner at center center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 35%);
  background: radial-gradient(circle farthest-corner at center center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 35%);
  z-index: 2;
`;

const Title = styled.h1`
  font-family: "Noto Serif", serif;
  font-size: ${25 / 16}rem;
  font-weight: bold;
  line-height: ${35 / 16}rem;
  margin-top: 20px;
  margin-bottom: 4.5rem;
  z-index: 50;
  color: #fffefa;
`;

const SaintBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 35%;
  z-index: 1;
`;

const StyledForm = styled(Form)`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  & input:focus,
  & select:focus {
    border: 1px solid #816920;
    outline: 1px solid #816920;
    box-shadow: none;
  }
  &.was-validated {
    & .form-select:valid {
      background-image: url(${selectIcon}) !important;
      background-size: 24px !important;
      border-color: ${(props) => props.theme.borderFormControl} !important;
    }
    & .form-select:invalid {
      background-image: url(${selectIcon}), url(${errorIcon}) !important;
      background-size: 24px, 24px !important;
    }
    & .form-control:valid {
      background-image: none;
      border-color: ${(props) => props.theme.borderFormControl} !important;
    }
    & .form-control:invalid {
      background-image: url(${errorIcon}) !important;
      background-size: 24px !important;
    }
    & .form-control:valid:focus,
    & .form-select:valid:focus {
      box-shadow: 0 0 0 0.1rem #816920;
    }
  }
  @media (min-width: 1024px) {
    margin: auto;
  }
`;

const FormGroup = styled(Form.Group)`
  z-index: 50;
  color: ${(props) => props.theme.textLightOnDark};
  &.visible {
    visibility: visible;
    height: auto;
    color: ${(props) => props.theme.textLightOnDark};
  }
  &.hidden {
    visibility: hidden;
    height: 0;
  }
`;
const FormGroupInitials = styled(Form.Group)`
  z-index: 50;
  width: 100%;
  color: ${(props) => props.theme.textLightOnDark};

  @media (min-width: 1024px) {
    width: 100%;
  }
  &.visible {
    visibility: visible;
    height: auto;
    color: ${(props) => props.theme.textLightOnDark};
  }
  &.hidden {
    visibility: hidden;
    height: 0;
  }
`;

const CustomSelect = styled(Select)`
  z-index: 1000;
  color: ${(props) => props.theme.textLightOnDark};
  & svg {
    display: none;
  }
  ${(props) =>
    props.className === "state-select" ? `margin-top: 1rem;` : null}
`;

const FormLabel = styled(Form.Label)`
  font-family: "Sintony", sans-serif;
  z-index: 50;
  font-size: 12px;
  color: ${(props) => props.theme.primary9};
  font-weight: bold;
  line-height: 25px;
`;

const FormControl = styled(Form.Control)`
  font-family: "Sintony", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  height: 48px;
  width: 100%;
  border-radius: 10rem;
  border-color: ${(props) => props.theme.borderFormControl};
  color: ${(props) => props.theme.textLightOnDark};
  background-color: ${(props) => props.theme.bgFormControls};
  &:placeholder {
    color: ${(props) => props.theme.textFormControls};
  }
`;
const FormControlInitials = styled(Form.Control)`
  font-family: "Sintony", sans-serif;
  z-index: 50;
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  height: 48px;
  border-radius: 10rem;
  width: 47%;
  border-color: ${(props) => props.theme.borderFormControl};
  color: ${(props) => props.theme.textLightOnDark};
  background-color: ${(props) => props.theme.bgFormControls};
  &:placeholder {
    color: ${(props) => props.theme.textFormControls};
  }

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: "Sintony", sans-serif;
  z-index: 50;
  font-size: 10px;
  font-weight: normal;
  line-height: 25px;
  color: #b52c2c;
`;

const FormText = styled(Form.Text)`
  display: block;
  z-index: 25;
  width: 100%;
  font-family: "Sintony", sans-serif;
  font-size: 9px;
  font-weight: normal;
  text-align: right;
  line-height: 20px;
  margin-top: -1.25em;
  margin-bottom: 1.5rem;
  position: relative;
`;

const PrayerForm = ({
  prayerObj,
  setPrayerFunc,
  setSubsectionFunc,
  chosenIcon,
  setChosenIcon,
}) => {
  const [validated, setValidated] = useState(false);
  const [validationAttempted, setValidationAttempted] = useState(false);
  const [offering, setOffering] = useState("");
  const [verb, setVerb] = useState("");
  const [noun, setNoun] = useState("");
  const [initials, setInitials] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [formObj, setFormObj] = useState({
    offering,
    verb,
    noun,
    initials,
    state,
    country,
  });
  const alphaCountries = useRef([]);
  const alphaStates = useRef([]);
  const offerArray = [
    { value: "friend", label: "Friend" },
    { value: "family", label: "Family" },
    { value: "community", label: "Community" },
    { value: "myself", label: "Myself" },
    { value: "911", label: "Those who lost their lives on September 11" },
  ];
  const verbArray = [
    { value: "hoping", label: "Hoping" },
    { value: "praying", label: "Praying" },
    { value: "wishing", label: "Wishing" },
    { value: "thinking", label: "Thinking about" },
  ];
  const nounArray = [
    { value: "healing", label: "Healing" },
    { value: "love", label: "Love" },
    { value: "peace", label: "Peace" },
    { value: "gratitude", label: "Gratitude" },
    { value: "strength", label: "Strength" },
    { value: "success", label: "Success" },
  ];
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      fontFamily: "'Sintony', sans-serif",
      borderRadius: "10rem",
      fontSize: 14,
      fontWeight: "normal",
      color: state.isSelected ? "#0D0D0D" : "#939393",
      boxShadow: `0 0 0 1px ${state.isFocused ? "#816920" : "transparent"}`,
    }),
    valueContainer: (provider) => ({
      ...provider,
      height: 48,
    }),
    menu: (provider) => ({
      ...provider,
      padding: "10px 0",
      width: "100%",
    }),
    option: (provider, state) => ({
      ...provider,
      whiteSpace: "nowrap",
      borderBottom: "1px solid #D9D5CE",
      backgroundColor: state.isSelected
        ? "transparent"
        : state.isFocused
        ? "transparent"
        : "transparent",
      "@media and (minw-width: 1366px)": {
        backgroundColor: state.isSelected
          ? "transparent"
          : state.isFocused
          ? "#D0C39C"
          : "transparent",
      },
      backgroundImage: state.isSelected ? `url(${checkedIcon})` : "none",
      backgroundPosition: "100% 50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: 24,
      color: state.isSelected ? "#0D0D0D" : "#0D0D0D",
      fontWeight: state.isSelected ? "bold" : "normal",
      height: 48,
      marginLeft: 3,
      marginRight: 5,
      width: "calc(100% - 8px)",
      zIndex: 1000,
    }),
    indicatorSeparator: (provided) => ({
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      width: 32,
      height: 32,
      backgroundImage: `url(${selectIcon})`,
      backgroundSize: 24,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      transform: `${
        state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)"
      }`,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const customTheme = (theme) => ({
    ...theme,
    borderRadius: "0.5em",
    colors: {
      ...theme.colors,
      primary: "#816920",
      primary75: "#A78724",
      primary50: "#D0C39C",
      primary25: "#D9D5CE",
    },
  });

  useEffect(() => {
    console.log(offering.length, initials.length);
  }, [offering, initials]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /** sorting @countries and @states by letter of the alphabet */
  useLayoutEffect(() => {
    if (alphaCountries.current && alphaStates.current) {
      alphaCountries.current.push({
        label: "",
        options: [],
      });

      countries.forEach((country, index) => {
        const firstLetter = country.trim().charAt(0);
        if (alphaCountries.current[index] === undefined) {
          alphaCountries.current.push({
            label: firstLetter,
            options: [],
          });
        }
      });
      alphaCountries.current = alphaCountries.current.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.label === value.label)
      );
      countries.forEach((country, index) => {
        const firstLetter = country.trim().charAt(0);
        alphaCountries.current.forEach((group, indx) => {
          if (firstLetter === group.label) {
            alphaCountries.current[indx].options.push({
              value: country,
              label: country,
            });
          }
        });
      });

      alphaCountries.current[0].options.push({
        value: countries[238],
        label: countries[238],
      });

      console.log(alphaCountries)

      states.forEach((state, index) => {
        const firstLetter = state.State.trim().charAt(0);
        if (alphaStates.current[index] === undefined) {
          alphaStates.current.push({
            label: firstLetter,
            options: [],
          });
        }
      });
      alphaStates.current = alphaStates.current.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.label === value.label)
      );
      states.forEach((state, index) => {
        const firstLetter = state.State.trim().charAt(0);
        alphaStates.current.forEach((group, indx) => {
          if (firstLetter === group.label) {
            alphaStates.current[indx].options.push({
              label: state.State,
              value: state.Code,
            });
          }
        });
      });
      states.forEach((state) => {
        const firstLetter = state.State.trim().charAt(0);
        if (!alphaStates.current[firstLetter]) {
          alphaStates.current[firstLetter] = [];
        } else if (alphaStates.current[firstLetter].length === 0) {
          alphaStates.current[firstLetter] = [state.State.trim()];
        } else {
          alphaStates.current[firstLetter] = [
            ...alphaStates.current[firstLetter],
            state.State.trim(),
          ];
        }
      });
    }

    return () => {
      alphaCountries.current = [];
      alphaStates.current = [];
    };
  }, []);

  useEffect(() => {
    if (formObj.offering !== "") {
      setPrayerFunc({ ...prayerObj, ...formObj });
      setSubsectionFunc("result");
    }
  }, [formObj, prayerObj, setPrayerFunc, setSubsectionFunc]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    setValidationAttempted(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      form.classList.add("was-validated");
    } else {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
      setFormObj({
        offering,
        verb,
        noun,
        initials,
        state,
        country,
      });

      /** send to CMS and Gallery */
      // Initialize Firebase
      const db = getFirestore(app);

      const getFirestoreQuery = async () => {
        const query = await addDoc(
          collection(db, "prayers"),
          {
            dateSubmitted: new Date().toUTCString(),
            initials: initials,
            state: state,
            country: country,
            noun: noun,
            offeringFor: offering,
            verb: verb,
            status: "Pending",
          },
          { merge: false }
        );
        console.log("Document written with ID: ", query.id);
      };
      await getFirestoreQuery();
    }
  };

  return (
    <>
      <RadialGradientOverlay />
      {chosenIcon !== null ? (
        <SaintBackground
          style={{ backgroundImage: `url(${chosenIcon.imgUrl})` }}
        />
      ) : null}
      <FormWrapper
        layoutId="form"
        animate={{ transform: "translateX(0%)", opacity: 1 }}
        initial={{ transform: "translateX(1000%)", opacity: 0 }}
        exit={{ transform: "translateX(-100%)", opacity: 0 }}
        z
        transition={{ duration: 0.6 }}
        fluid="sm"
      >
        <Title>Leave a Message</Title>
        <StyledForm noValidate validated={validated} onSubmit={handleSubmit}>
          <Row sm={1} style={{ marginBottom: "1em" }}>
            <FormGroup id="offering">
              <FormLabel htmlFor="offering">
                I’m offering a candle for *
              </FormLabel>
              <CustomSelect
                tabIndex="1"
                required
                isInvalid={!offering}
                isSearchable={false}
                styles={customSelectStyles}
                theme={customTheme}
                options={offerArray}
                onChange={(e) => setOffering(e.value)}
                placeholder="Family"
                menuPortalTarget={document.body}
              />
              <FormControlFeedback
                type="invalid"
                style={{ display: !offering && validationAttempted ? "block" : "none" }}
              >
                Please select an option.
              </FormControlFeedback>
            </FormGroup>
          </Row>

          <Row sm={2} style={{ marginBottom: "1em" }}>
            <FormGroup as={Col} id="verb">
              <FormLabel htmlFor="verb">I'm</FormLabel>
              <CustomSelect
                tabIndex="2"
                closeMenuOnSelect={false}
                isSearchable={false}
                styles={{
                  ...customSelectStyles,
                  menu: (provider) => ({
                    ...provider,
                    "@media (max-width: 767px)": {
                      padding: "10px 0",
                      width: "calc(100vw)",
                    },
                  }),
                }}
                theme={customTheme}
                options={verbArray}
                onChange={(e) => setVerb(e.value)}
                placeholder="Hoping"
                menuPortalTarget={document.body}
              />
            </FormGroup>

            <FormGroup as={Col} id="noun">
              <FormLabel htmlFor="noun">For</FormLabel>
              <CustomSelect
                tabIndex="3"
                isSearchable={false}
                styles={customSelectStyles}
                theme={customTheme}
                options={nounArray}
                onChange={(e) => setNoun(e.value)}
                placeholder="Healing"
                menuPortalTarget={document.body}
              />
            </FormGroup>
          </Row>

          <Row sm={2} style={{ marginBottom: "1em", marginTop: "3rem" }}>
            <FormGroupInitials as={Col} id="initials">
              <FormLabel htmlFor="initials">Your initials *</FormLabel>
              <FormControlInitials
                tabIndex="4"
                required
                type="text"
                placeholder="Type your initials"
                value={initials.toUpperCase() || ""}
                onChange={(e) => setInitials(e.target.value.toUpperCase())}
                maxLength={2}
              />
              <FormControlFeedback type="invalid">
                Please provide your initials.
              </FormControlFeedback>
            </FormGroupInitials>
            {/* <Col /> */}
          </Row>

          <Row sm={1} style={{ marginBottom: "0em" }}>
            <FormGroup id="noun">
              <FormLabel htmlFor="noun">Where are you from</FormLabel>
              <CustomSelect
                tabIndex="5"
                menuPlacement="top"
                styles={{
                  ...customSelectStyles,
                  option: (provider) => ({
                    ...provider,
                    whiteSpace: "wrap",
                    lineHeight: "auto",
                    borderBottom: "1px solid #D9D5CE",
                    backgroundColor: state.isSelected
                      ? "transparent"
                      : state.isFocused
                      ? "transparent"
                      : "transparent",
                    "@media and (minw-width: 136699999999px)": {
                      backgroundColor: state.isSelected
                        ? "transparent"
                        : state.isFocused
                        ? "#D0C39C"
                        : "transparent",
                    },
                    backgroundImage: state.isSelected
                      ? `url(${checkedIcon})`
                      : "none",
                    backgroundPosition: "100% 50%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 24,
                    color: state.isSelected ? "#0D0D0D" : "#0D0D0D",
                    fontWeight: state.isSelected ? "bold" : "normal",
                    height: 48,
                    marginLeft: 10,
                    marginRight: 10,
                    width: "calc(100% - 20px)",
                    marginBottom: "0rem",
                  }),
                }}
                theme={customTheme}
                options={alphaCountries.current}
                onChange={(e) => setCountry(e.value)}
                placeholder="Choose Your Location"
                menuPortalTarget={document.body}
              />
            </FormGroup>

            {country === "United States" && (    
              <FormGroup
                className={`mb-3 ${
                  country === "United States" ? "visible" : "hidden"
                }`}
                id="noun"
              >
                <CustomSelect
                  tabIndex="6"
                  menuPlacement="top"
                  styles={{
                    ...customSelectStyles,
                    option: (provider) => ({
                      ...provider,
                      whiteSpace: "wrap",
                      lineHeight: "auto",
                      borderBottom: "1px solid #D9D5CE",
                      backgroundColor: state.isSelected
                        ? "transparent"
                        : state.isFocused
                        ? "transparent"
                        : "transparent",
                      "@media and (minw-width: 136699px)": {
                        backgroundColor: state.isSelected
                          ? "transparent"
                          : state.isFocused
                          ? "#D0C39C"
                          : "transparent",
                      },
                      backgroundImage: state.isSelected
                        ? `url(${checkedIcon})`
                        : "none",
                      backgroundPosition: "100% 50%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: 24,
                      color: state.isSelected ? "#0D0D0D" : "#0D0D0D",
                      fontWeight: state.isSelected ? "bold" : "normal",
                      height: 48,
                      marginLeft: 10,
                      marginRight: 10,
                      width: "calc(100% - 20px)",
                    }),
                  }}
                  theme={customTheme}
                  options={alphaStates.current}
                  onChange={(e) => setState(e.value)}
                  placeholder="Choose Your State"
                  menuPortalTarget={document.body}
                  className="state-select"
                />
              </FormGroup>
            )}
          </Row>

          <FormText id="requiredFieldBlock" muted>
            * Indicates required field
          </FormText>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type={offering && initials ? "form-submit-active" : "form-submit"}
              label="Submit"
              icon={<UpIcon />}
              addStyle={{
                width: "10rem",
                marginBottom: "2em",
                zIndex: 200,
              }}
              state={
                offering.length > 0 && initials.length > 0 ? "" : "inactive"
              }
            />
          </div>
        </StyledForm>
      </FormWrapper>
    </>
  );
};

PrayerForm.propTypes = {
  setPrayerFunc: PropTypes.func,
  setSubsectionFunc: PropTypes.func,
  prayerObj: PropTypes.object,
};
PrayerForm.defaultProps = {
  setPrayerFunc: null,
  setSubsectionFunc: null,
  prayerObj: {},
};

export default PrayerForm;
