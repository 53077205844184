/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import store from '../../store';
import hintShapeSvg from '../../assets/icons/hint-shape.svg';
import { ReactComponent as DownarrowIcon } from '../../assets/icons/48-icon-down.svg';
import Caption from './Caption';

const MULT = 18;

const Container = styled(motion.div)`
  height: auto;
  width: 100%;
  position: relative;
`;

const Overlay = styled(motion.div)`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.height + 80}px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 20;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 5;
  }
  @media (min-width: 1024px) {
    height: ${props => props.height}px;
  }
`;

const OverlayTitle = styled.h1`
  position: relative;
  font-family: 'Noto Serif', serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0.5em;
  z-index: 6;
`;

const OverlayTagline = styled.h2`
  position: relative;
  font-family: 'Sintony', sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  z-index: 6;
`;

const DownArrowWrapper = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  z-index: 6;
  & svg {
    width: 32px;
    height: 32px;
  }
  @media (min-width: 1024px) {
    padding: 0.5em 2.5em;
    & svg {
      width: 48px;
      height: 48px;
    }
  }
`;

const Label = styled.div`
  margin-bottom: 20px;
  color: ${props => props.theme.primaryDark};
`;

const Hint = styled.div`
  width: 88px;
  height: ${28 / 16}rem;
  position: absolute;
  top: ${props => props.top ? '5rem' : 'unset'};
  right: 1rem;
  bottom: ${props => props.bottom ? '10rem' : 'unset'};
  z-index: 6;
  @media (min-width: 1024px) {
    top: ${props => props.top ? '6rem' : 'unset'};
    bottom: ${props => props.bottom ? 'calc(80px + 1rem)' : 'unset'};
    right: 2.5rem;
  }
  &:before {
    content: "";
    position: absolute;
    width: 88px;
    height: ${28 / 16}rem;
    background-image: url(${hintShapeSvg});
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleY(${props => props.bottom ? '-1' : '1'});
    z-index: 1;
  }
  &:after {
    content: "${props => props.text}";
    position: absolute;
    top: ${props => props.top ? '5px' : 0}; left: 0;
    width: 88px;
    height: ${23 / 16}rem;
    color: #FFF;
    font-family: 'Sintony', sans-serif;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    line-height: ${23 / 16}rem;
    z-index: 5;
  }
`;

const Runway = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.frameCount * MULT}px;
  background-size: auto 100vh;
  background-position: 50% 0%; 
  background-repeat: no-repeat;
  z-index: 14;
  & .scroll-canvas {
    z-index: 10;
    display: ${props => props.loadedImages ? 'block' : 'none'};
    & .scroll-canvas-canvas {
      height: ${props => props.height}px;
      width: 100vw;
      object-fit: cover !important;
      z-index: 10;
      transition: opacity 500ms ease-in-out;
    }
  }
  @media (min-width: 1130px) {
    background-size: 100%;
    background-position: 0% 0%; 
  }
`;

const Canvas = styled.canvas`
  top: 0; left: 0;
  height: ${props => props.height}px;
  width: 100vw;
  object-fit: cover !important;
  z-index: 10;
  transition: opacity 500ms ease-in-out;
`;

const Scroller = styled.div`
  position: absolute;
  top: 0; right: 0;
  width: 6px; height: ${props => props.height}px;
  background: rgba(129, 105, 32, 0.8);
  transition: opacity 500ms ease-in-out;
  overflow: hidden;
  z-index: 19;
`;

const ScrollerBar = styled.div`
  position: absolute;
  top: 0; right: 0;
  width: 6px; height: 100vh;
  background: ${props => props.theme.bgLight};
  transition: transform 0.5s linear;
`;

const spin = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg); }
  to { transform: translate(-50%, -50%) rotate(360deg); }
`;

const Loader = styled.div`
  position: absolute;
  top: 0; left: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    top: 50%; left: 50%;
    width: ${120 / 16}rem; height: ${120 / 16}rem;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border-top: 2px solid #A78724;
    border-right: 2px solid #A78724;
    border-left: 2px solid #A78724;
    animation: ${spin} 500ms infinite ease;
  }
`;

const LoadingOverlay = styled(motion.div)`
  position: fixed;
  top: 0; left: 0;
  width: 100vw;
  height: ${props => props.height}px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  z-index: 20;
`;

const LandscapeDetector = styled.div`
  @media screen and (max-width: 1024px) and (min-aspect-ratio: 13/9) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0; left: 0;
    background: ${props => props.theme.bgDark};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 1009;
    &:after {
      content: "Please turn your device to view the website.";
      width: 80%;
      font-family: 'Sintony', sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      margin: 0;
      color: ${props => props.theme.primaryDark};
      @media (min-width: 1024px) {
        font-family: 'Noto Serif', serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: #000;
      }
    }
  }
`;

const ImageSequence = ({
  id,
  percentScrolled,
  setPercentScrolled,
  frameCount,
  firstImage,
  title,
  tagline,
  videoDuration,
  captionsData,
  hotspotData
}) => {
  const isMobile = window.matchMedia('(pointer:coarse)').matches;
  const [state, setState] = useState(store.initialState);
  const [scrollTop, setScrollTop] = useState(0);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const { ref, inView } = useInView({});
  // const [loaded, setLoaded] = useState(false);
  const [blurRunway, setBlurRunway] = useState(false);
  const runway = useRef(null);
  const canvas = useRef(null);
  const sub = useRef(null);
  const cframe = useRef(0);
  const images = useRef([]);

  const drawImageScaled = (img, cvs, ctx) => {
    var hRatio = cvs.width  / img.width;
    var vRatio =  cvs.height / img.height;
    var ratio  = Math.max( hRatio, vRatio );
    var centerShift_x = ( cvs.width - img.width * ratio ) / 2;
    var centerShift_y = ( cvs.height - img.height * ratio ) / 2;  
    ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);  
 }

  useEffect(() => {
    console.log(id, inView)
    if (inView) store.currentTour(id);
  }, [inView]);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
    store.loadedImages(false);
    const cvs = document.querySelector(`#${state.currentTour}-canvas`);
    const ctx = cvs.getContext('2d');

    canvas.current.width = window.innerWidth;
    canvas.current.height = screenHeight;

    const currentFrame = index => (
      `/tour/${id}-hirez/frame-${(index).toString().padStart(5, '0')}.jpg`
    );

    images.current = []

    const anim = {
      frame: 1
    };
    console.log('state show tour ', state.showTour);

    for (let i = 0; i <= frameCount; i++) {
      const img = new Image();

      img.src = currentFrame(i);
      images.current.push(img);
    }

    gsap.to(anim, {
      frame: frameCount,
      ease: 'none',
      snap: 'frame',
      scrollTrigger: {
        id: `${id}-trigger`,
        trigger: runway.current,
        start: 'top top',
        pin: false,
        scrub: true,
        invalidateOnRefresh: false,
        onToggle: self => {
          setScreenHeight(window.innerHeight);
          canvas.current.height = window.innerHeight;
          store.showTour(self.isActive);
        },
        onUpdate: self => {
          if (anim.frame > 0) {
            ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
            drawImageScaled(images.current[anim.frame], cvs, ctx);
          }


          /** set correct screen height if not set */
          if (screenHeight !== window.innerHeight) setScreenHeight(window.innerHeight);

          /** render the scrollbar progress and set percent for captions */
          setScrollTop(self.progress * self.end);
          setPercentScrolled(self.progress * 100);
          cframe.current = anim.frame;

          document.getElementById(`${id}-tour`).querySelectorAll('video').forEach((video) => {
            if (!video.paused) {
              video.pause();
            }
          });
        }
      },
    });

    return () => {
      // clean up ScrollTrigger instance on title element
      const runwayScrollTrigger = ScrollTrigger.getById(`${id}-trigger`);
      if (runwayScrollTrigger) {
        runwayScrollTrigger.kill();
      }
    };
  }, [state.currentTour]);

  useEffect(() => {
    if ((state.scrollToMap) && (state.currentTour === id)) {
      setBlurRunway(true);

      console.log(`#${state.scrollToMap.tour}-tour`);

      gsap.to(window,
        {
          duration: state.scrollToMap.from ? 0 : 1,
          scrollTo: `#${state.scrollToMap.tour}-tour`,
          onComplete: () => {
            const cvs = document.querySelector(`#${state.scrollToMap.tour}-canvas`);
            const ctx = cvs.getContext('2d');
            setBlurRunway(false);

            try {
              const img = new Image();
              img.src = `/tour/${state.scrollToMap.tour}-hirez/frame-00000.jpg`;

              ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
              drawImageScaled(img, cvs, ctx);
            } catch(err) {
              console.log('scroll to tour section error ', err);
            }

            store.hotspotOpen(true);
            store.scrollToMap(null)
          }
        });
    }
  }, [state.scrollToMap]);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  const calcOffset = (time) => {
    const percentOffset = time * 100 / videoDuration;
    return percentOffset;
  };

  return (
    <Container
      id={`${id}-tour`}
      ref={ref}
      key={id}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 3 }}
    >
      {isMobile ? <LandscapeDetector /> : null }
      <AnimatePresence>
        {blurRunway && (
          <LoadingOverlay
            key='runway-blurrer'
            height={screenHeight}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Loader />
          </LoadingOverlay>
        )}
      </AnimatePresence>
      <Overlay
        height={screenHeight}
        firstImage={firstImage}
        animate={{ opacity: ( 1 - scrollTop * 0.005) }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundImage: `url(${cframe.current > 0 ? images.current[cframe.current].src : firstImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <OverlayTitle>{title}</OverlayTitle>
        <OverlayTagline>{tagline}</OverlayTagline>

        <DownArrowWrapper>
          <motion.div
            animate={{ transform: ['translateY(0px)', 'translateY(-15px)', 'translateY(0px)'] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          >
            <DownarrowIcon />
          </motion.div>
          <Label className="button2">Scroll to Enter</Label>
        </DownArrowWrapper>

        {/* {!loaded
          ? (
            <LoaderWrapper>
              <OverlayLoader>Please be patient<br /> Loading High-Quality User&nbsp;Experience...</OverlayLoader>
              <div>
                <LoaderLine />
              </div>
            </LoaderWrapper>
          )
          : (
            <DownArrowWrapper><DownarrowIcon /></DownArrowWrapper>
          )} */}
        {id === 'exterior' && <Hint top={true} text="Audio Controls" /> }
        {id === 'exterior' && <Hint bottom={true} text="Tour Controls" /> }
      </Overlay>

      <Scroller style={{ position: (state.showTour && (state.currentTour === id)) ? 'fixed' : 'absolute' }} height={screenHeight}>
        <ScrollerBar
          frameCount={frameCount}
          style={{
            transform: `translateY(-${100 - percentScrolled}%)`
          }}
        />
      </Scroller>
      <Runway
        id={id}
        ref={runway}
        frameCount={frameCount}
        height={screenHeight}
        loadedImages={state.loadedImages}
      >
        <Canvas
          ref={canvas}
          id={`${id}-canvas`}
          height={screenHeight}
          atTop={state.showTour && (state.currentTour === id)}
          style={{
            position: 'sticky',
            backgroundImage: `url(${cframe.current > 0 ? images.current[cframe.current].src : firstImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
      </Runway>
      {captionsData.map((caption, key) => (
        <Caption
          key={key}
          index={key}
          tour={id}
          text={caption.text}
          audioUrl={caption.url}
          offset={calcOffset(caption.start)}
          captionData={caption}
          hotspotData={hotspotData}
        />
      ))}

      {/* {hotspotData.map((hotspot, key) => (
        <Hotspot
          key={key}
          hotspot={hotspot}
          offsetStart={calcOffset(hotspot.start)}
          offsetEnd={calcOffset(hotspot.finish)}
          startPixels={calcStartPixels(hotspot.start)}
          endPixels={calcEndPixels(hotspot.finish)}
          percentScrolled={percentScrolled}
          scrolledPix={scrollTop}
          tourId={id}
          pinned={pinned}
          setPinned={(pin) => setPinned(pin)}
          loaded={loaded}
        />
      ))} */}
    </Container>
  );
}

ImageSequence.propTypes = {
  id: PropTypes.string,
  percentScrolled: PropTypes.number,
  setPercentScrolled: PropTypes.func,
  frameCount: PropTypes.number,
  firstImage: PropTypes.string,
  title: PropTypes.string,
  tagline: PropTypes.string,
  removeHeight: PropTypes.number, 
  videoDuration: PropTypes.number,
  captionsData: PropTypes.array,
  hotspotData: PropTypes.array,
  currentTour: PropTypes.string,
  setCurrentTour: PropTypes.func,
  loaded: PropTypes.bool,
};
ImageSequence.defaultProps = {
  id: '',
  percentScrolled: 0,
  setPercentScrolled: null,
  frameCount: 0,
  firstImage: '',
  title: '',
  tagline: '',
  removeHeight: 0,
  videoDuration: 0,
  captionsData: [],
  hotspotData: [],
  currentTour: '',
  setCurrentTour: null,
  loaded: false,
};

export default ImageSequence;