/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components/macro';
import store from '../../../store';
import Initial from './Initial.js';
import PrayerForm from './Form.js';
import Result from './Result.js';
import CandleBgJpg from '../../../assets/images/candle_bg.jpg';

import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';

const MainContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  color: ${props => props.theme.bgLight};
  background-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
`;

const Main = ({
  prayer,
  setPrayer,
  setSection,
  subsection,
  setSubsection,
}) => {
  const [chosenIcon, setChosenIcon] = useState(null);
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    store.setHeaderColorAll(theme.navWhite);
  }, []);


  useEffect(() => {
 
      setPrayer({...prayer, icon: { desc: '', id: 0, imgUrl: CandleBgJpg, name: '' }});
      console.log(`${chosenIcon} is null?`);
      if (chosenIcon) {
        setPrayer({...prayer, icon: { desc: chosenIcon.desc, id: chosenIcon.id, imgUrl: chosenIcon.imgUrl, name: chosenIcon.name }});
        console.log(`${chosenIcon} is not null`);
      }

    console.log(chosenIcon)
  }, [chosenIcon]);

  useEffect(() => {
    console.log(prayer);
  }, [prayer])

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    store.lastPage('/light-a-candle');
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  return(
    <>
      <MainContainer
        subsection={subsection}
        animate={{ transform: 'translateX(0%)', opacity: 1 }}
        initial={{ transform: 'translateX(50%)', opacity: 0 }}
        exit={{ transform: 'translateX(-50%)', opacity: 0  }}
        transition={{ delay: 0.1 }}
      >
        <AnimateSharedLayout>
          <AnimatePresence>
            {subsection === 'initial' ? <Initial setSubsection={setSubsection} chosenIcon={chosenIcon} setChosenIcon={setChosenIcon} /> : null }
            {subsection === 'form' ? <PrayerForm prayerObj={prayer} setPrayerFunc={setPrayer} setSubsectionFunc={setSubsection} chosenIcon={chosenIcon} setChosenIcon={setChosenIcon} /> : null }
            {subsection === 'result' ? <Result prayer={prayer} setSubsection={setSubsection} setSection={setSection} /> : null }
          </AnimatePresence>
        </AnimateSharedLayout>
      </MainContainer>
    </>
  );
};

Main.propTypes = {
  setSection: PropTypes.func,
  subsection: PropTypes.string,
  setSubsection: PropTypes.func,
  prayer: PropTypes.object,
  setPrayer: PropTypes.func,
  overlayOpen: PropTypes.bool,
  setOverlayOpen: PropTypes.func,
};
Main.defaultProps = {
  setSection: null,
  subsection: '',
  setSubsection: null,
  prayer: {},
  setPrayer: null,
  overlayOpen: false,
  setOverlayOpen: null,
};

export default Main;