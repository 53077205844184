import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { NavLink} from "react-router-dom";
import Button from './Button';
import { ReactComponent as CloseIcon } from "../assets/icons/icon-close.svg";

const CookieConsentPrefsContainer = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 1008;
`;
const CookieConsentPrefsWrapper = styled.div`
  width: 90%;
  height: 90%;
  border-radius: 8px;
  background: ${props => props.theme.bgLight};
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  padding-top: 14px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  z-index: 102;
  @media (min-width: 820px) {
    width: auto;
    height: auto;
  }
`;

const Header = styled.div`
  width: 100%;
  height: auto;
  border-radius: 8px;
  top: 0;
  left: 0;
  position: fixed;
`;

const Body = styled.div`
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  margin-top: 24px;
  overflow-y: auto;
`;

const Section = styled.div`
  width: 100%;
  height: auto;
  padding: 24px 12px;
  margin: 0;
  border-bottom: 1px solid ${(props) => props.theme.textMenu};
`;

const Message = styled.p`
  font-family: 'Sintony', sans-serif;
  text-align: center;
  font-size: ${10 / 16}rem;
  line-height: normal;
  color: #000;
  margin: 0;
  & strong {
    font-size: ${12 / 16}rem;
  }
  @media (min-width: 1920px) {
    font-size: ${12 / 16}rem;
    & strong {
      font-size: ${14 / 16}rem;
    }
  }
  @media (min-width: 2160) {
    font-size: ${14 / 16}rem;
    & strong {
      font-size: ${16 / 16}rem;
    }
  }
`;

const TitleMessage = styled(Message)`
  font-size: 16px;
  font-weight: bold;
  line-height: 54px;
  background-color: #FFF;
  border-radius: 8px;
  @media (min-width: 1920px) {
    font-size: 20px;
  }
  @media (min-width: 2160) {
    font-size: 24px;
  }
`;

const Buttons = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 24px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  @media (min-width: 1920px) {
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 97;
  background: ${props => props.theme.secondary1};
  opacity: 0.8;
`;

const Switch = styled.div`
  width: auto;
  height: auto;
  margin: 12px;
  margin-bottom: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  & .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 27px;
  }
  
  & .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  & .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  & input:checked + .slider {
    background-color: ${props => props.theme.primaryLight};
  }
  
  & input:focus + .slider {
    box-shadow: 0 0 1px ${props => props.theme.primaryLight};
  }
  
  & input:checked + .slider:before {
    transform: translateX(22px);
  }

  & .slider.round {
    border-radius: 28px;
  }
  
  & .slider.round:before {
    border-radius: 50%;
  }
`;

const CookieConsentPrefs = ({ open, setOpen, accept, setAccept }) => {
  const toggleAcceptCookies = () => {
    if (accept) {
      setAccept(false);
    } else {
      setAccept(true);
    }
  };

  return(
    <CookieConsentPrefsContainer
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0  }}
    >
      <CookieConsentPrefsWrapper>
        <Header>
          <Button
            type="header"
            icon={<CloseIcon />}
            action={() => setOpen(false)}
            addStyle={{
              padding: 0,
              position: 'absolute',
              top: 12,
              left: 12,
            }}
          />
          <TitleMessage>Cookie Preferences</TitleMessage>
        </Header>
        <Body>  
          <Section>
            <Message>
              <strong>Your privacy is important to us.</strong>
              <br/><br/>
              Cookies are very small text files that are stored on your computer when you visit a website. We use cookies for a variety of purposes and to enhance your online experience on our website (for example, to remember your language preferences).
              <br/><br/>
              You can change these preferences and decline certain types of cookies to be stored on your computer while browsing our website. You can also remove any cookies already stored on your computer, but keep in mind that deleting cookies may prevent you from using parts of our website.
            </Message>
            </Section>
            <Section>
              <Message>
                <strong>Strictly necessary cookies.</strong>
                <br/><br/>
                These cookies are essential to provide you with services available through our website and to enable you to use certain features of our website.
                <br/><br/>
                Without these cookies, we cannot provide you certain services on our website.
              </Message>
              <Switch>
                <label className="switch">
                  <input type="checkbox" checked readOnly />
                  <span className="slider round"></span>
                </label>
                <Message style={{ fontWeight: 'bold', marginLeft: 6 }}>Always Accept</Message>
              </Switch>
            </Section>
            <Section>
              <Message>
                <strong>Tracking cookies.</strong>
                <br/><br/>
                These cookies are used to collect information to analyze the traffic to our website and how visitors are using our website.
                <br/><br/>
                For example, these cookies may track things such as how long you spend on the website or the pages you visit which helps us to understand how we can improve our website for you.
                <br/><br/>
                The information collected through these tracking and performance cookies do not identify any individual visitor.
              </Message>
              <Switch>
                <label className="switch">
                  <input type="checkbox" checked={accept} onChange={() => toggleAcceptCookies()} />
                  <span className="slider round"></span>
                </label>
                <Message style={{ fontWeight: 'bold', marginLeft: 6 }}>{accept? 'Accept Cookies' : 'Decline Cookies'}</Message>
              </Switch>
            </Section>
            <Section style={{ borderBottom: 'none' }}>
              <Message>
                <strong>More information.</strong>
                <br/><br/>
                For any queries in relation to our policy on cookies and your choices, please read our <NavLink as="a" to="/privacy-cookies-policy">Privacy and Cookies Policy</NavLink>, or <a href="mailto:info@stnicholaswtc.org" target="_blank" rel="noreferrer"><span >contact&nbsp;us</span></a>.
              </Message>
            </Section>
          <Buttons>
            <Button
              type="tertiary"
              label="Save my preferences"
              addStyle={{
                textDecoration: 'none',
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'auto',
                height: 'auto',
                fontSize: '80%',
                boxShadow: 'none',
                padding: '.5em 1.5em',
                margin: 5,
              }}
              action={() => setOpen(false)}
            />
          </Buttons>
        </Body>
      </CookieConsentPrefsWrapper>
      <Background onClick={() => setOpen(false)} />
    </CookieConsentPrefsContainer>
  );
};

CookieConsentPrefs.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
};


CookieConsentPrefs.defaultProps = {
  open: false,
  setOpen: null,
};

export default CookieConsentPrefs;
