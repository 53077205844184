/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CaptionBackButton from "../../components/CaptionBackButton";
import CaptionViewVideoImageButton from "../../components/CaptionViewVideoImageButton";
import Slideshow from "../../components/Slideshow";
import PlayButton from "../../components/PlayButton";
import store from "../../store";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/icons/32-icon-play-v2.svg";
import { ReactComponent as PlayIconDark } from "../../assets/icons/32-icon-play-dark.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/48-icon-pause-white.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/32-icon-eye-opened.svg";
import { ReactComponent as CandleIcon } from "../../assets/icons/32-icon-candles.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/32-icon-leftArrow-v2.svg";
import Button from "../../components/Button";
import { useTheme } from "styled-components/macro";
import { ReactComponent as ArrowAroundIcon } from "../../assets/icons/32-icon-around.svg";

const CaptionContainer = styled(motion.div)`
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 19;
`;

const CaptionBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: ${348 / 16}rem;
  height: auto;
  min-height: ${58 / 16}rem;
  bottom: calc(100% - 90vh);
  left: calc((100vw - ${340 / 16}rem) / 2);
  background-color: ${(props) => props.theme.bgCaptionCard};
  color: ${(props) => props.theme.textCaptionCard};
  padding: ${25 / 16}rem ${25 / 16}rem;
  padding-top: ${18.5 / 16}rem;
  padding-left: ${20 / 16}rem;
  padding-right: ${15 / 16}rem;
  font-size: ${12 / 16}rem;
  font-family: "Noto Serif", serif;
  font-weight: bold;
  line-height: ${18 / 16}rem;
  z-index: 20;
  border-radius: 8px;
  transition: margin 0.1ms linear;
  align-items: center;
  @media (min-width: 1024px) {
    width: ${(600 / 16) * 0.7}rem;
    font-size: ${20 / 16}rem;
    line-height: ${27 / 16}rem;
    left: 3vw;
    bottom: auto;
    padding-right: ${29 / 16}rem;

    /** uniform stopping point for all captions on desktop */
    top: 55%;
    transform: translateY(-50%);

    @media (min-height: 1080px) {
      width: ${600 / 16}rem;
    }
    @media (min-height: 2160px) {
      width: ${900 / 16}rem;
      font-size: ${56 / 16}rem;
      line-height: ${54 / 16}rem;
    }
  }
`;

const CaptionRow = styled.div`
  display: flex;
  flex-direction: row nowrap;
  justify-content: center;
  align-items: center;
`;

const CaptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledVideo = styled.video`
  width: 80%;
  height: auto;
`;

const StyledTitle = styled.h1`
  font-family: "Noto Serif", serif;
  font-size: ${16 / 16}rem;
  font-weight: bold;
  color: rgba(13, 13, 13, 1); //TODO: switch these to using theme.js
  margin-top: ${44 / 16}rem;
  margin-bottom: 0;
  line-height: 30px;
  align-self: flex-start;
`;

const CaptionText = styled.p`
  flex: 1;
  margin: 0;
  width: 100%;
  font-family: "Noto Serif", serif;
  font-size: ${11.5 / 16}rem};
  font-weight: bold;
  line-height: ${18 / 16}rem;
  text-align: ${(props) => (props.rtl ? "right" : "left")};;
  position: relative;
  margin-right: ${7 / 16}rem;
  padding: 0;
  margin-bottom: 13px;
  direction: ${(props) => (props.rtl ? "rtl" : "ltr")};
  z-index: 5;
  & span {
    font-size: 14px;
    color: ${(props) => props.theme.primaryDark};
  }
  @media (min-width: 1366px) {
    text-align: left;
    font-size: 18px;
    line-height: 30px;
    & span {
      font-size: 22px;
    }
  }
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 32px;
    & span {
      font-size: 24px;
    }
  }
`;

const CaptionSubtitle = styled.p`
  flex: 1;
  margin: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 10px;
  font-family: "Sintony", serif;
  font-size: ${12 / 16}rem;
  line-height: ${22 / 16}rem;
  text-align: left;
  position: relative;
  direction: ${(props) => (props.rtl ? "rtl" : "ltr")};
  z-index: 5;
  & span {
    font-size: 14px;
    color: ${(props) => props.theme.primaryDark};
  }
  @media (min-width: 1366px) {
    text-align: left;
    font-size: 18px;
    line-height: 30px;
    & span {
      font-size: 22px;
    }
  }
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 32px;
    & span {
      font-size: 24px;
    }
  }
`;

const Caption = ({
  text,
  offset,
  audioUrl,
  captionData,
  hotspotData,
  index,
  tour,
}) => {
  const [state, setState] = useState(store.initialState);
  const [hotspotType, setHotspotType] = useState(null);
  const location = useLocation();
  const [matchedHotspot, setMatchedHotspot] = useState(null);
  const { ref, inView, entry } = useInView({ threshold: 0.1 });
  const sub = useRef(null);
  const theme = useTheme();

  const handleHotspotButton = (type) => {
    setHotspotType(type);
  };

  const navigate = useNavigate();
  const playAudio = () => {
    // disablePageScroll();

    const playPromise = state.audioTrack.play();
    if (playPromise !== undefined) {
      playPromise
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          console.log(`autoplay started for ${entry.target.dataset.src}`);
        });
    }
  };

  const pressPlayAudio = (play) => {
    console.log("play ", play);
    if (play) {
      if (state.audioTrack.muted) state.audioTrack.muted = false;
      playAudio();
    } else {
      state.audioTrack.pause();
    }
  };

  const renderRegularCaption = () => (
    <>
      {inView && <ProgressBar />}
      <CaptionRow key={state.language.id}>
        <CaptionText rtl={state.language.id === "ar"}> {text}</CaptionText>
        {!state.audioTrack.paused ? (
          <PlayButton
            type="play"
            icon={<PauseIcon />}
            action={() => pressPlayAudio(false)}
          />
        ) : (
          <PlayButton
            type="play"
            icon={<PlayIcon />}
            action={() => pressPlayAudio(true)}
          />
        )}
      </CaptionRow>
      {matchedHotspot && (
        <CaptionRow>
          {matchedHotspot.type === "video" && (
            <CaptionViewVideoImageButton
              action={() => handleHotspotButton("video")}
              type="hotspot"
              label="Watch Video"
              icon={<PlayIconDark />}
              fillColor="#816920"
            />
          )}
          {matchedHotspot.type === "slideshow" && (
            <CaptionViewVideoImageButton
              action={() => handleHotspotButton("slideshow")}
              type="hotspot"
              label="View Images"
              icon={<EyeIcon />}
            />
          )}
          {matchedHotspot.type === "light-a-candle" && (
            <CaptionViewVideoImageButton
              action={() => navigate(matchedHotspot.url)}
              type="lightACandle"
              label="Light A Candle"
              icon={<CandleIcon />}
            />
          )}
          {matchedHotspot.type === "explore-icons" && (
            <CaptionViewVideoImageButton
              action={() => navigate(matchedHotspot.url)}
              type="lightACandle"
              label="Explore Icons"
              icon={<ArrowAroundIcon />}
            />
          )}
        </CaptionRow>
      )}
    </>
  );

  const videoSourcePerMedia = () => {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      return <source src={matchedHotspot.urlmd} type="video/mp4" />;
    }
    return <source src={matchedHotspot.url} type="video/mp4" />;
  };

  const onPlayEmbeddedVideo = () => {
    if (!state.mutedBg) store.mutedBg(true);
  };

  const renderVideoCaption = () => (
    <>
      <CaptionBackButton
        action={() => handleHotspotButton(null)}
        type="captionBack"
        label="Back"
        icon={<LeftArrow />}
        addClass="icon-first"
        addStyle={{ position: "absolute", top: `${12 / 16}rem`, left: 0 }}
      />
      <CaptionColumn>
        <StyledTitle>{matchedHotspot.title}</StyledTitle>
        <CaptionSubtitle>{matchedHotspot.subtitle}</CaptionSubtitle>
        <StyledVideo
          muted={false}
          playsInline
          controls={true}
          controlsList="nodownload"
          autoPlay={false}
          preload="metadata"
          poster={matchedHotspot.poster}
          onPlay={() => onPlayEmbeddedVideo()}
        >
          {videoSourcePerMedia()}
          {matchedHotspot.vtt_en && (
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src={matchedHotspot.vtt_en}
              default
            />
          )}
          {matchedHotspot.vtt_el && (
            <track
              label="Ελληνικά"
              kind="subtitles"
              srcLang="el"
              src={matchedHotspot.vtt_el}
            />
          )}
          {matchedHotspot.vtt_es && (
            <track
              label="Español"
              kind="subtitles"
              srcLang="es"
              src={matchedHotspot.vtt_es}
            />
          )}
          {matchedHotspot.vtt_zhcn && (
            <track
              label="中文(简)"
              kind="subtitles"
              srcLang="zh-cn"
              src={matchedHotspot.vtt_zhcn}
            />
          )}
          {matchedHotspot.vtt_zhtw && (
            <track
              label="中文(繁)"
              kind="subtitles"
              srcLang="zh-tw"
              src={matchedHotspot.vtt_zhtw}
            />
          )}
          {matchedHotspot.vtt_fr && (
            <track
              label="Français"
              kind="subtitles"
              srcLang="fr"
              src={matchedHotspot.vtt_fr}
            />
          )}
          {matchedHotspot.vtt_ar && (
            <track
              label="عربى"
              kind="subtitles"
              srcLang="ar"
              src={matchedHotspot.vtt_ar}
            />
          )}
          {matchedHotspot.vtt_ru && (
            <track
              label="русский"
              kind="subtitles"
              srcLang="ru"
              src={matchedHotspot.vtt_ru}
            />
          )}
          {matchedHotspot.vtt_ja && (
            <track
              label="日本語"
              kind="subtitles"
              srcLang="ja"
              src={matchedHotspot.vtt_ja}
            />
          )}
        </StyledVideo>
      </CaptionColumn>
    </>
  );

  const renderSlideshowCaption = () => (
    <>
      <CaptionBackButton
        action={() => handleHotspotButton(null)}
        type="captionBack"
        label="Back"
        icon={<LeftArrow />}
        addClass="icon-first"
        addStyle={{ position: "absolute", top: `${12 / 16}rem`, left: 0 }}
      />
      <CaptionColumn>
        <StyledTitle>{matchedHotspot.title}</StyledTitle>
        <CaptionSubtitle>{matchedHotspot.subtitle}</CaptionSubtitle>
        <Slideshow images={matchedHotspot.images} />
      </CaptionColumn>
    </>
  );

  const renderCandleCaption = () => (
    <>
      <CaptionBackButton
        action={() => handleHotspotButton(null)}
        type="captionBack"
        label="Back"
        icon={<LeftArrow />}
        addClass="icon-first"
        addStyle={{ position: "absolute", top: `${12 / 16}rem`, left: 0 }}
      />
      <CaptionColumn>
        <StyledTitle>{matchedHotspot.title}</StyledTitle>
        <CaptionSubtitle>{matchedHotspot.subtitle}</CaptionSubtitle>
        <Button
          label="Light a Candle"
          type="dark"
          icon={<CandleIcon />}
          action={() => {
            store.setHeaderColorAll(theme.primary9);
            navigate("/light-a-candle");
          }}
        />
      </CaptionColumn>
    </>
  );

  const renderIconsCaption = () => (
    <>
      <CaptionBackButton
        action={() => handleHotspotButton(null)}
        type="captionBack"
        label="Back"
        icon={<LeftArrow />}
        addClass="icon-first"
        addStyle={{ position: "absolute", top: `${12 / 16}rem`, left: 0 }}
      />
      <CaptionColumn>
        <StyledTitle>{matchedHotspot.title}</StyledTitle>
        <CaptionSubtitle>{matchedHotspot.subtitle}</CaptionSubtitle>
        <Button
          label="Explore Icons"
          type="dark"
          icon={<ArrowAroundIcon />}
          action={() => {
            store.setHeaderColorAll(theme.primary9);
            navigate("/explore-icons");
          }}
        />
      </CaptionColumn>
    </>
  );

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    return () => {
      store.unsubscribe(sub.current);
      if (state.audioTrack) {
        state.audioTrack.pause();
        state.audioTrack.src = "";
      }
    };
  }, []);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      id: `id-${index}-${tour}`,
      trigger: `#caption-${index}-${tour}`,
      start: "top top",
      end: "+=" + captionData.lasts,
      pin: true,
      anticipatePin: 1,
      scrub: true,
    });

    console.log("CAPTION CREATING A SCROLL TRIGGER ", state.currentTour);
    // }

    return () => {
      // clean up ScrollTrigger instance on title element
      const captionScrollTrigger = ScrollTrigger.getById(`id-${index}-${tour}`);
      if (captionScrollTrigger) {
        captionScrollTrigger.kill();
      }
    };
  }, [state.currentTour]);

  useEffect(() => {
    if (captionData.hotspot) {
      setMatchedHotspot((old) => {
        return hotspotData.filter(
          (hotspot) => (parseInt(captionData.hotspot, 10) === hotspot.id)

        )[0];
      });
    }
  }, [captionData]);

  useEffect(() => {
    if (inView) {
      state.audioTrack.src = entry.target.dataset.src;
      // state.audioTrack.muted = false;
      state.audioTrack.load();
      store.audioPercent(0);

      if (!state.mutedBg) {
        state.audioTrack.muted = false;
        playAudio();
      } else {
        state.audioTrack.pause();
        console.log("i am paused");
      }

      state.audioTrack.ontimeupdate = () => {
        if (state.audioTrack.duration) {
          const percent = !isNaN(
            (state.audioTrack.currentTime * 100) / state.audioTrack.duration
          )
            ? (state.audioTrack.currentTime * 100) / state.audioTrack.duration
            : 100;
          store.audioPercent(percent);
        }
      };

      if (state.mutedBg) {
        state.audioTrack.muted = true;
        state.audioTrack.pause();
      } else {
        state.audioTrack.muted = false;
        state.audioTrack.play();
      }
    } else {
      store.audioPercent(0);
      // pausing playing videos when they leave view
      document.querySelectorAll("video")
      .forEach((video) => {
        if (!video.paused) {
          video.pause();
        }
      });
    }
  }, [state.audioTrack, inView, state.mutedBg]);

  useEffect(() => {
    if (location.pathname !== "/tour") {
      state.audioTrack.pause();
    }
  }, [location]);

  useEffect(() => {
    if (state.mutedBg) state.audioTrack.muted = true;
    else state.audioTrack.muted = false;
  }, [state.mutedBg]);

  return (
    <CaptionContainer
      style={{ top: `${offset}%` }}
      id={`caption-${index}-${tour}`}
    >
      <CaptionBox ref={ref} data-src={audioUrl} data-text={text}>
        {hotspotType === "video" && renderVideoCaption()}
        {hotspotType === "slideshow" && renderSlideshowCaption()}
        {/* {hotspotType === "light-a-candle" && renderCandleCaption()}
        {hotspotType === "explore-icons" && renderIconsCaption()} */}
        {hotspotType === null && renderRegularCaption()}
      </CaptionBox>
    </CaptionContainer>
  );
};

Caption.propTypes = {
  text: PropTypes.string,
  offset: PropTypes.number,
  audioUrl: PropTypes.string,
  captionData: PropTypes.object,
  hotspotData: PropTypes.array,
};
Caption.defaultProps = {
  text: "",
  offset: 0,
  audioUrl: "",
  index: 0,
  captionData: null,
  hotspotData: [],
};

const Progress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${4 / 16}rem;
  overflow: hidden;
  z-index: 5;
`;

const ProgressFill = styled(motion.div)`
  position: relative;
  width: 110%;
  height: 100%;
  background-color: #BB5921;
  /* transform: translateX(-${(props) => 100 - props.currentPercent}%); */
  /* transition: transform 1s linear; */
`;

const ProgressBar = () => {
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  return (
    <Progress>
      <ProgressFill
        initial={{ transform: `translateX(-100%)` }}
        animate={{ transform: `translateX(-${100 - state.audioPercent}%)` }}
        transition={{ type: "spring" }}
      />
    </Progress>
  );
};

export default Caption;
