/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import Accordion from "react-bootstrap/Accordion";
import store from "../../store";
import closeIcon from "../../assets/icons/32-icon-close.svg";
import Button from "../../components/Button";
import ButtonLeaveMessageDonate from "../../components/ButtonLeaveMessageDonate";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DonateIcon } from "../../assets/icons/32-icon-donate.svg";
import iconDown from "../../assets/icons/32-icon-arrowDown.svg";
import arrowIcon from "../../assets/icons/32-icon-arrowDown.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HotspotOverlayWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.captioninline ? "auto" : "100%")};
  position: ${(props) => (props.isinline === "true" ? "relative" : "fixed")};
  top: ${(props) => (props.isinline === "true" ? "unset" : 0)};
  left: ${(props) => (props.isinline === "true" ? "unset" : 0)};
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${(props) =>
    props.captioninline === "true" ? "flex-start" : "center"};
  align-items: ${(props) =>
    props.captioninline === "true" ? "flex-start" : "center"};
  z-index: 900;
`;

const Background = styled(motion.div)`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
`;

const HotspotCard = styled(motion(Modal.Dialog))`
  // margin: ${(props) => (props.nomargin === "true" ? 0 : "auto 1rem")};
  margin: 0;
  z-index: 10;
  &.modal-dialog {
    width: ${(props) => (props.captioninline === "true" ? "100%" : "auto")};
    max-width: 100%;
    @media (min-width: 768px) {
      max-width: 70vw;
    }
    @media (min-width: 1920px) {
      max-width: 45vw;
    }
  }
  & .modal-content {
    max-width: 90vw;
    border: none;
    border-radius: 0;
    //box-shadow: ${(props) => props.captioninline === "true" ? "none" : "0px 3px 6px rgb(0 0 0 / 30%)"};
  }
`;

const HotspotHeader = styled(Modal.Header)`
  flex-direction: column;
  position: relative;
  border: 0;
  padding: 0.625rem;
  padding-left: ${(props) => (props.captioninline === "true" ? 0 : "0.625em")};
  @media (min-width: 1024px) {
    padding: 1.25rem;
    padding-left: ${(props) =>
      props.captioninline === "true" ? 0 : "0.625em"};
  }

  & button.btn-close {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
    margin: 0;
    background: transparent url(${closeIcon}) center/2em auto no-repeat;
    opacity: 1;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      oapcity: 0.5;
    }

    @media (min-width: 1024px) {
      right: 1.25rem;
      top: 1.25rem;
    }
  }
`;

const Title = styled(Modal.Title)`
  font-family: "Noto Serif", serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-top: -1rem;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.h2`
  font-family: "Sintony", sans-serif;
  font-size: 11px;
  font-weight: normal;
  line-height: 15px;
  margin: 0;
  width: 100%;
`;

const HotspotBody = styled(Modal.Body)`
  padding-top: 0;
  padding: ${(props) => (props.isinline === "true" ? 0 : "1rem")};
`;

const SectionList = styled(Accordion)`
  width: 100%;
  padding: 0;
  margin: 0;
`;

const SectionItem = styled(Accordion.Item)`
  background-color: transparent;
  width: 100%;
  &.accordion-item {
    border: 0;
  }
`;

const SectionHeader = styled(Accordion.Header)`
  height: 100%;
  border: none;
  box-shadow: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  & div {
    width: 90%;
    flex-grow: 1;
  }
  & button {
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border: none;
    &.accordion-button {
      border: none;
    }
    &.accordion-button:focus,
    &.accordion-button:not(.collapsed) {
      outline: 0;
      background-color: transparent;
      box-shadow: none;
      color: inherit;
      border: none;
    }
    &.accordion-button:after {
      content: "";
      background-image: url(${iconDown});
      background-size: 48px;
      width: 48px;
      height: 48px;
    }
  }
`;

const SectionBody = styled(Accordion.Body)`
  padding: 0;
`;

const HotspotOverlay = ({ inline, captionInline, hotspotData, closeList }) => {
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  const onAccordionToggle = () => {
    const videos = document
      .getElementById(`${state.currentTour}-tour`)
      .querySelectorAll("video");
    videos.forEach((video) => {
      if (!video.paused) {
        video.pause();
      }
    });
  };

  return (
    <HotspotOverlayWrapper
      isinline={inline.toString()}
      captioninline={captionInline.toString()}
    >
      {hotspotData && (
        <>
          <HotspotCard
            key="modal-card"
            initial={{ opacity: 0, transform: "scale(0)" }}
            animate={{ opacity: 1, transform: "scale(1)" }}
            exit={{ opacity: 0, transform: "scale(0)" }}
            nomargin={captionInline.toString()}
            captioninline={captionInline.toString()}
          >
            {captionInline ? (
              <SectionList defaultActiveKey="1">
                <SectionItem eventKey="0">
                  <SectionHeader onClick={onAccordionToggle}>
                    <HotspotHeader
                      style={{
                        alignItems: "flex-start",
                        paddingTop:
                          hotspotData.type === "interactive"
                            ? `${34 / 16}rem`
                            : "0.625rem",
                      }}
                      captioninline={captionInline.toString()}
                    >
                      <Title>{hotspotData.title}</Title>
                      <Subtitle
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {hotspotData.subtitle}
                      </Subtitle>
                    </HotspotHeader>
                  </SectionHeader>
                  <SectionBody>
                    <HotspotBody isinline={inline.toString()}>
                      {hotspotData.type === "video" && (
                        <VideoHotspot
                          videoUrl={hotspotData.url}
                          videoUrlmd={hotspotData.urlmd}
                          poster={hotspotData.poster}
                          vtt_en={hotspotData.vtt_en}
                          vtt_el={hotspotData.vtt_el}
                          vtt_es={hotspotData.vtt_es}
                          vtt_zhcn={hotspotData.vtt_zhcn}
                          vtt_zhtw={hotspotData.vtt_zhtw}
                          vtt_fr={hotspotData.vtt_fr}
                          vtt_ar={hotspotData.vtt_ar}
                          vtt_ru={hotspotData.vtt_ru}
                          vtt_ja={hotspotData.vtt_ja}
                          muted={state.mutedVideoAudio}
                        />
                      )}
                      {hotspotData.type === "slideshow" && (
                        <>
                          <SlideshowHotspot
                            images={hotspotData.images}
                            inline={true}
                            captioninline={captionInline.toString()}
                          />
                        </>
                      )}
                      {hotspotData.type === "interactive" && (
                        <InteractiveHotspot
                          url={hotspotData.url}
                          inline={inline.toString()}
                          closeList={closeList}
                        />
                      )}
                    </HotspotBody>
                  </SectionBody>
                </SectionItem>
              </SectionList>
            ) : (
              <HotspotBody isinline={inline.toString()}>
                {hotspotData.type === "video" && (
                  <VideoHotspot
                    closeList={closeList}
                    videoUrl={hotspotData.url}
                    videoUrlmd={hotspotData.urlmd}
                    poster={hotspotData.poster}
                    vtt_en={hotspotData.vtt_en}
                    vtt_el={hotspotData.vtt_el}
                    vtt_es={hotspotData.vtt_es}
                    vtt_zhcn={hotspotData.vtt_zhcn}
                    vtt_zhtw={hotspotData.vtt_zhtw}
                    vtt_fr={hotspotData.vtt_fr}
                    vtt_ar={hotspotData.vtt_ar}
                    vtt_ru={hotspotData.vtt_ru}
                    vtt_ja={hotspotData.vtt_ja}
                    muted={state.mutedVideoAudio}
                  />
                )}
                {hotspotData.type === "slideshow" && (
                  <SlideshowHotspot images={hotspotData.images} inline={true} />
                )}
                {hotspotData.type === "interactive" && (
                  <InteractiveHotspot
                    url={hotspotData.url}
                    inline={inline.toString()}
                    closeList={closeList}
                  />
                )}
              </HotspotBody>
            )}
          </HotspotCard>

          {!inline && (
            <Background
              key="modal-bg"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          )}
        </>
      )}
    </HotspotOverlayWrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  object-fit: contain;
  z-index: 19;
`;

const VideoHotspot = ({
  closeList,
  videoUrl,
  videoUrlmd,
  poster,
  muted,
  vtt_en,
  vtt_el,
  vtt_es,
  vtt_zhcn,
  vtt_zhtw,
  vtt_fr,
  vtt_ar,
  vtt_ru,
  vtt_ja,
}) => {
  const video = useRef(null);
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    video.current.onplay = () => {
      store.mutedBg(true);
    };

    // video.current.onended = () => {
    //   if (!state.mutedBg) state.mutedBg = false;
    // };

    return () => {
      store.unsubscribe(sub.current);
      if (video.current) video.current.pause();
    };
  }, []);

  useEffect(() => {
    /** pausing video play on closed hotspot list */
    if (!state.openedHotspots) {
      video.current.pause();
    }
  }, [state.openedHotspots]);

  const videoSourcePerMedia = () => {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      return <source src={videoUrlmd} type="video/mp4" />;
    }

    return <source src={videoUrl} type="video/mp4" />;
  };

  const onPlayEmbeddedVideo = () => {
    if (!state.mutedBg) store.mutedBg(true);
  };

  return (
    <Wrapper>
      <Video
        muted={false}
        playsInline
        controls={true}
        controlsList="nodownload"
        autoPlay={false}
        ref={video}
        preload="metadata"
        poster={poster}
        onPlay={() => onPlayEmbeddedVideo()}
      >
        {videoSourcePerMedia()}
        {vtt_en && (
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={vtt_en}
            default
          />
        )}
        {vtt_el && (
          <track label="Ελληνικά" kind="subtitles" srcLang="el" src={vtt_el} />
        )}
        {vtt_es && (
          <track label="Español" kind="subtitles" srcLang="es" src={vtt_es} />
        )}
        {vtt_zhcn && (
          <track
            label="中文(简)"
            kind="subtitles"
            srcLang="zh-cn"
            src={vtt_zhcn}
          />
        )}
        {vtt_zhtw && (
          <track
            label="中文(繁)"
            kind="subtitles"
            srcLang="zh-tw"
            src={vtt_zhtw}
          />
        )}
        {vtt_fr && (
          <track label="Français" kind="subtitles" srcLang="fr" src={vtt_fr} />
        )}
        {vtt_ar && (
          <track label="عربى" kind="subtitles" srcLang="ar" src={vtt_ar} />
        )}
        {vtt_ru && (
          <track label="русский" kind="subtitles" srcLang="ru" src={vtt_ru} />
        )}
        {vtt_ja && (
          <track label="日本語" kind="subtitles" srcLang="ja" src={vtt_ja} />
        )}
      </Video>
      {/* <CurrentTime>
      <ClockIcon />
      {currentTime} / {duration}
    </CurrentTime> */}
    </Wrapper>
  );
};

const SlideshowColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const SlideshowWrapper = styled.div`
  width: ${286 / 16}rem;
  height: auto;
    & .slick-arrow.slick-prev,
    & .slick-arrow.slick-next {
      width: ${32 / 16}rem;
      height: ${32 / 16}rem;
      background-image: url(${arrowIcon});
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 5;
      &:before {
        content: "";
      }
      @media (min-width: 1024px) {
        width: ${48 / 16}rem;
        height: ${48 / 16}rem;
      }
    }
    & .slick-arrow.slick-prev {
      transform: rotate(90deg) translate(100%, 0%);
      top: 25%;
      @media (min-width: 1024px) {
        left: -20%;
      }
    }
    & .slick-arrow.slick-next {
      transform: rotate(-90deg) translate(-100%, 0%);
      top: 25%;
      @media (min-width: 1024px) {
      right: -20%;
      }
    }
    & .slick-dots {
      @media (min-width: 1024px) {
        bottom: -4.5rem;
      }
      & li {
        & button {
          &::before {
            content: "•";
            color: ${(props) => props.theme.primaryDark};
            width: 1rem;
            height: 2rem;
            line-height: 2rem;
          }
        }
      }

      @media (min-width: 1024px) {
        margin-bottom: ${48 / 16}rem;
        width: ${(props) =>
          props.isinline === "true" ? `${286 / 16}rem` : "100%"};
      }
      @media (min-width: 1024px) {
        margin-bottom: ${48 / 16}rem;
        width: ${(props) =>
          props.isinline === "true" ? `${286 / 16}rem` : "100%"};
      }
    }
`;

/* TODO: Clean during code cleanup
const SlideshowWrapper = styled.div`
width: ${286 / 16}rem;
  // width: ${(props) =>
    props.isinline === "true" ? `${286 / 16}rem` : "100%"};
  // height: auto;
  // margin-bottom: 2rem;
  // & .slick-slider {
  //   & .slick-dots {
  //     z-index: 3;
  //     bottom: -2rem;
  //     & li {
  //       width: 1rem;
  //       height: 2rem;
  //       & button {
  //         width: 1rem;
  //         height: 2rem;
  //         &::before {
  //           content: "•";
  //           color: ${(props) => props.theme.primaryDark};
  //           width: 1rem;
  //           height: 2rem;
  //           line-height: 2rem;
  //         }
  //       }
  //     }
      @media (min-width: 1024px) {
        // bottom: -${48 / 16}rem;
        // & li {
        //   width: ${24 / 15}rem;
        //   height: ${48 / 16}rem;
        //   & button {
        //     width: ${24 / 15}rem;
        //     height: ${48 / 16}rem;
        //     &:before {
        //       content: "•";
        //       width: ${24 / 15}rem;
        //       height: ${48 / 16}rem;
        //       line-height: ${48 / 16}rem;
            }
          }
        }
      }
    }
    & .slick-arrow.slick-prev,
    & .slick-arrow.slick-next {
      // top: unset;
      // bottom: 0;
      // left: unset;
      // right: unset;
      width: ${32 / 16}rem;
      height: ${32 / 16}rem;
      background-image: url(${arrowIcon});
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 5;
      &:before {
        content: "";
      }
      @media (min-width: 1024px) {
        width: ${48 / 16}rem;
        height: ${48 / 16}rem;
      }
    }
    & .slick-arrow.slick-prev {
      transform: rotate(90deg) translate(100%, 0%);
      // left: 0;
    }
    & .slick-arrow.slick-next {
      transform: rotate(-90deg) translate(-100%, 0%);
      // right: 0;
    }
  }

`;
*/
/*
    @media (min-width: 1024px) {
    margin-bottom: ${48 / 16}rem;
    width: ${(props) =>
      props.isinline === "true" ? `${286 / 16}rem` : "100%"};
  }
  @media (min-width: 1366px) {
    margin-bottom: ${48 / 16}rem;
    width: ${(props) =>
      props.isinline === "true" ? `${398 / 16}rem` : "100%"};
  }

*/

const Image = styled.img`
  max-height: 200px;
  margin: 0 auto;
  @media (min-width: 1920px) {
    max-height: 265px;
  }
`;

const Caption = styled.p`
  font-family: "Sintony", sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 28px;
  font-family: "Sintony", sans-serif;
  font-size: 10px;
  font-weight: normal;
  line-height: 13px;
  margin-top: 2rem;
  padding: 10px;
  padding-left: ${(props) => (!props.captioninline ? "10px" : 0)};
  height: 85px;
  @media (min-width: 1024px) {
    font-family: Arial, Helvetica, sans-serif;
    font-size: ${14 / 16}rem;
    line-height: ${20 / 16}rem;
    margin-top: 1rem;
  }

  // @media (min-width: 1366px) {
  //   height: 75px;
  // }
`;

const SlideshowHotspot = ({
  images,
  inline,
  captioninline,
  onBeforeChange,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
      if (onBeforeChange) onBeforeChange(oldIndex, newIndex);
    },
  };

  return (
    <SlideshowColumn>
      <SlideshowWrapper isinline={inline}>
        <Slider {...settings}>
          {images.map((image, key) => (
            <React.Fragment key={key}>
              <Image
                key={key}
                src={image.image}
                alt={`slideshow slide #${key}`}
              />
            </React.Fragment>
          ))}
        </Slider>
      </SlideshowWrapper>
      <Caption captioninline={captioninline}>
        <b>{images[currentSlide].caption}</b>
        <br />
        <i>{images[currentSlide].credit}</i>
      </Caption>
    </SlideshowColumn>
  );
};

const ButtonWrapper = styled.div`
  width: ${(props) => (props.isinline === "true" ? `${318 / 16}rem` : "100%")};
  height: ${(props) => (props.isinline === "true" ? `${178 / 16}rem` : "100%")};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    width: ${(props) =>
      props.isinline === "true" ? `${398 / 16}rem` : "100%"};
    height: ${(props) =>
      props.isinline === "true" ? `${223 / 16}rem` : "100%"};
  }
`;

const isMobile = window.innerWidth < 1024;

const InteractiveHotspot = ({ url, doCloseHotspot, inline, closeList }) => {
  const navigate = useNavigate();

  const setToggleDonationAlert = () => {
    store.openedExternalLinkAlert({ opened: true, type: "donation" });
  };

  const goToInteractive = () => {
    navigate(url);
    store.hotspotOpen(false);
    store.showTour(false);
    console.log(typeof closeList);
    if (typeof closeList === "function") closeList(false);
  };

  return (
    <ButtonWrapper >
      <ButtonLeaveMessageDonate
        type="light"
        label="Tap to Enter"
        addStyle= {isMobile ? {marginBottom: '1rem', width: '15rem'} : {marginBottom: '1rem', marginRight: '1rem', width: '15rem'}}
        action={goToInteractive}
      />
      <ButtonLeaveMessageDonate
        type="light"
        icon={<DonateIcon />}
        label="Make a Donation"
        addStyle= {isMobile ? { width: '15rem'} : {marginRight: '1rem', width: '15rem'}}
        action={setToggleDonationAlert}
      />
    </ButtonWrapper>
  );
};

HotspotOverlay.propTypes = {
  inline: PropTypes.bool,
  hotspotData: PropTypes.object,
};

HotspotOverlay.defaultProps = {
  inline: false,
  hotspotData: null,
};

export default HotspotOverlay;
