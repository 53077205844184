/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useLayoutEffect, useEffect, useMemo } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import store from '../../store';
import { fadeIn, fadeOut } from '../../modules/audioFader.js'
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { NavLink, useLocation, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import ImageSequence from './ImageSequence';
import Button from '../../components/Button';
import {ReactComponent as DonateIcon} from '../../assets/icons/48-icon-donate.svg';
import {ReactComponent as EmailIcon} from '../../assets/icons/32-icon-email.svg';
import {ReactComponent as ShareIcon} from '../../assets/icons/32-icon-share.svg';
import exteriorData from '../../assets/data/exterior_audio_scroll.json';
import exteriorHotspotData from '../../assets/data/exterior_hotspots.json';
import narthexHotspotData from '../../assets/data/narthex_hotspots.json';
import naveHotspotData from '../../assets/data/nave_hotspots.json';
import narthexData from '../../assets/data/narthex_audio_scroll.json';
import naveData from '../../assets/data/nave_audio_scroll.json';
import calatravaImage from '../../assets/calatrava.jpg';
import NewsletterForm from '../../components/NewsletterForm';
import SocialDropdown from '../../components/SocialDropdown';

import logoIcon from '../../assets/icons/logo.svg';
import arrowIcon from '../../assets/icons/32-icon-arrowDown.svg';
import menuIcon from '../../assets/icons/48-icon-menu.svg';
import mapIcon from '../../assets/icons/48-icon-map.svg';
import shareIcon from '../../assets/icons/48-icon-share.svg';
import closeIcon from '../../assets/icons/48-icon-close.svg';
import captionicon from '../../assets/icons/32-icon-caption.svg';
import mutedIcon from '../../assets/icons/32-icon-mute.svg';
import unmutedIcon from '../../assets/icons/32-icon-equalizer.svg';
import listIcon from '../../assets/icons/48-icon-list.svg';
import smallCloseIcon from '../../assets/icons/32-icon-close.svg';
import moreIcon from '../../assets/icons/32-icon-more.svg';
import exteriorMapImage from '../../assets/exterior_map_image.png';
import narthexMapImage from '../../assets/narthex_map_image.png';
import naveMapImage from '../../assets/nave_map_image.png';
import Map from './Map';

// const mailchimp = require('@mailchimp/mailchimp_marketing');

const StyledTourContainer = styled(Container)`
  padding: 0;
  position: relative;
  background: #000;
  height: auto;
  background-size: auto 100vh;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 11;
  margin: 0;

  @media (min-width: 1024px) {
    ${(props) => props.openedHotspots ? `width: calc(100vw - ${(470/16)}rem);` : ''};
  }
`;

const EndPanel = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 90;
  & button.secondary, & div button.secondary {
    line-height: 18px;
    width: 220px;
  }
  @media (min-width: 1024px) {
    & button.secondary, & div button.secondary {
      width: 360px;
    }
  }
`;

const BgImage = styled.img`
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0; left: 0;
  filter: blur(3px) brightness(0.4);
  z-index: 5;
`;

const Title = styled.h1`
  font-family: 'Noto Serif', serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  color: #FFF;
  position: relative;
  z-index: 10;
  @media (min-width: 1024px) {
    font-size: 55px;
    line-height: 75px;
  }
  @media (min-width: 1920px) {
    font-size: 60px;
  }
`;

const SubTitle = styled.h2`
  font-family: 'Sintony', sans-serif;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  line-height: 20px;
  color: #FFF;
  position: relative;
  margin-bottom: 5px;
  z-index: 10;
  &.smol {
    width: 80%;
    font-size: 12px;
    text-align: center;
  }
  @media (min-width: 1024px) {
    &.smol {
      width: 50%;
      margin-bottom: 20px;
      font-size: 12px;
      line-height: normal;
    }
  }
  @media (min-width: 1366px) {
    &.smol {
      font-size: 14px;
      line-height: normal;
    }
  }
  @media (min-width: 1920px) {
    &.smol {
      font-size: 16px;
      line-height: normal;
    }
  }
`;

const StartOverButton = styled.button`
  box-shadow: none;
  border: none;
  font-family: 'Sintony', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  text-decoration: underline;
  color: #FFF;
  position: relative;
  margin-top: 3rem;
  z-index: 10;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    bottom: ${30 / 16}rem;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (min-width: 1920px) {
    bottom: ${80 / 16}rem;
  }
`;

const Tour = () => {
  const [percentScrolled, setPercentScrolled] = useState(0);
  const [openedSocials, setOpenedSocials] = useState(false);
  const [state, setState] = useState(store.initialState);
  const [loaded, setLoaded] = useState(false);
  const [percentLoaded, setPercentLoaded] = useState(0);
  const [showNewsletterForm, setShowNewsletterForm] = useState(false);
  const location = useLocation();
  const sub = useRef(null);
  const bgAudio = useRef(null);
  const bgExtAudio = useRef(null);
  const bgNarAudio = useRef(null);
  const bgNavAudio = useRef(null);
  const bgEndAudio = useRef(null);
  const { ref, inView } = useInView({ threshold: 1 });
  const firstImage = {
    'exterior': '/tour/exterior-hirez/frame-00001.jpg',
    'narthex': '/tour/narthex-hirez/frame-00001.jpg',
    'nave': '/tour/15fps-frames/frame-00001.jpg',
  };

  const bgAudioSrc = {
    'exterior': "/audios/Steinberg_The_Master's_Hospitality.mp3",
    'narthex': "/audios/Zes_Communion_Verse_for_Sundays,_Mode_Plagal_I.mp3",
    'nave': "/audios/Boyer_Ps_103_Opening_from_Come_Let_Us_Worship.mp3",
    'end': "/audios/Asmatikon_Cherubic_Hymn_from_Lost_Voices_of_Hagia_Sophia.mp3"
  };

  useEffect(() => {
    if (location.pathname.includes('/tour') ) {
      /** load first audio as well */
      const tempAudio = new Audio();
      tempAudio.src = '/tour/exterior-audios/building_scroll1.mp3';
      tempAudio.load();
    }
  }, []);

  /** keeping audio from playing on other pages */
  useEffect(() => {
    if (!location.pathname.includes('/tour') && bgAudio.current) bgAudio.current.pause();
  }, [location]);

  useMemo(() => {
    if (location.pathname.includes('/tour') && bgAudio.current && !state.mutedBg) {
      bgAudio.current.load();
      bgAudio.current.oncanplaythrough = () => {
        bgAudio.current.play();
      };
    }
  }, [state.currentTour]);


  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    store.lastPage('/tour');
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  /** just background audio things */
  useLayoutEffect(() => {
    bgAudio.current.volume = 0.5;
    if (!state.mutedBg) {
      bgAudio.current.muted = false;
      bgAudio.current.play();
    }
    else if (state.mutedBg) {
      bgAudio.current.muted = true;
    }
    console.log('muting/unmuting bg from tour')
  }, [state.mutedBg]);

  const setToggleDonationAlert = () => {
    store.openedExternalLinkAlert({ opened: true, type: "donation" });
  };

  useEffect(() => {
    /** play ending music on "thank you" section */
    if (inView) {
     /** switch bg audio per tour */
     bgAudio.current.src = bgAudioSrc['end'];
     bgAudio.current.load();
     bgAudio.current.play();
    }
  }, [inView]);

  const onSubscribe = () => {
    setShowNewsletterForm(true);
  };

  const scrollToTop = () => {
    state.audioTrack.pause();
    state.audioTrack.currentTime = 0;
    window.focus();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
    store.showTour(false);
  };

  return(
    <StyledTourContainer
      key="tour"
      fluid
      openedHotspots={state.openedHotspots}
    >
      <Helmet>
        <link rel="preload" href={logoIcon} as="image" />
        <link rel="preload" href={arrowIcon} as="image" />
        <link rel="preload" href={menuIcon} as="image" />
        <link rel="preload" href={mapIcon} as="image" />
        <link rel="preload" href={shareIcon} as="image" />
        <link rel="preload" href={closeIcon} as="image" />
        <link rel="preload" href={captionicon} as="image" />
        <link rel="preload" href={mutedIcon} as="image" />
        <link rel="preload" href={unmutedIcon} as="image" />
        <link rel="preload" href={listIcon} as="image" />
        <link rel="preload" href={smallCloseIcon} as="image" />
        <link rel="preload" href={moreIcon} as="image" />
        <link rel="preload" href={exteriorMapImage} as="image" />
        <link rel="preload" href={narthexMapImage} as="image" />
        <link rel="preload" href={naveMapImage} as="image" />
        <link rel="canonical" href={`${window.location.origin}/tour`} />
        <title>St. Nicholas Tour - The Map</title>
        <meta name="description" content="Start your tour of the Saint Nicholas Shrine. Tour the exterior, the Narthex, and the Nave. Light a candle or explore the Nave's icons in greater detail."/>
        <meta name="keywords" content="Image sequence, Virtual Tour, St Nicholas Shrine, Map"/>
      </Helmet>
        <audio ref={bgAudio} src={bgAudioSrc[state.currentTour]} autoPlay={true} loop={true} muted={state.mutedBg} />

        {(location.pathname === ('/tour') || location.pathname === ('/tour/'))
          ? <Map />
          : (
            <Outlet />
          )}
    </StyledTourContainer>
  );
};

Tour.defaultProps = {};
Tour.propTypes = {};

export default Tour;