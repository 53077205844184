import { useState, useLayoutEffect, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { NavLink} from "react-router-dom";
import styled from 'styled-components/macro';
import store from "../store";
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion';
import CookieConsentPrefs from "./CookieConsentPrefs";
import Button from './Button';

const CookieBanner = styled(motion.div)`
  width: 100vw;
  max-width: 1024px;
  position: fixed;
  bottom: 0; left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  background: ${props => props.theme.bgLight};
  padding: 22px;
  box-shadow: 0 0 ${10 / 16}rem rgba(0, 0, 0, 0.13);
  z-index: 1008;
  @media (min-width: 1920px) {
    padding: 28px;
  }
`;
const CookieTitle = styled.h3`
  font-family: 'Sintony', sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  color: #000;
  margin: 0;
  padding-top: 0;
  padding-bottom: 12px;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (min-width: 2160) {
    font-size: 24px;
    line-height: 36px;
  }
`;
const CookieMessage = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${10 / 16}rem;
  line-height: normal;
  color: #939393;
  padding-bottom: 12px;
  a, .link-button {
    padding: 0;
    border: none;
    box-shadow: none;
    color: ${(props) => props.theme.textMenu};
    text-decoration: underline;
  }
  @media (min-width: 1920px) {
    font-size: ${12 / 16}rem;
  }
  @media (min-width: 2160) {
    font-size: ${14 / 16}rem;
  }
`;
const CookieButtons = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 1920px) {
    flex-flow: row nowrap;
  }
`;

const CookieConsent = ({ openPrefsFromOutside }) => {
  const [accept, setAccept] = useState(null);
  const [seenBanner, setSeenBanner] = useState(false);
  const [openPrefs, setOpenPrefs] = useState(false);
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(() => {
    setOpenPrefs(state.openCookiePrefs);
  }, [state.openCookiePrefs]);

  useEffect(() => {
    store.openCookiePrefs(openPrefs);
  }, [openPrefs]);
  
  useLayoutEffect(() => {
    if (sessionStorage.getItem('cookie-consent-seen') !== null) {
      setSeenBanner(true);
    }

    if (sessionStorage.getItem('cookie-consent-accepted') === 'true') {
      setAccept(true);
    }
  }, [])

  useEffect(() => {
    setOpenPrefs(openPrefsFromOutside);
  }, [openPrefsFromOutside]);

  useEffect(() => {
    if (seenBanner) {
      sessionStorage.setItem('cookie-consent-seen', true);
    }
  }, [seenBanner]);

  useEffect(() => {
    if (accept) {
      sessionStorage.setItem('cookie-consent-accepted', true);
    } else {
      sessionStorage.setItem('cookie-consent-accepted', false);
    }
  }, [accept]);

  return (
    <>
      {accept && (
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-5DPRBJ7EMH"></script>
        </Helmet>
      )}
      {accept && (
        <Helmet>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-5DPRBJ7EMH');
            `}
          </script>
        </Helmet>
      )}
      {accept && (
        <Helmet>
          <script>
            {`
              !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
              posthog.init('phc_2tNAyxnQvor2fppTUB2YZ6x9ok8WYgLHE45HaGqTYGK',{api_host:'https://app.posthog.com'})
            `}
          </script>
        </Helmet>
      )}
      {!seenBanner && (
        <CookieBanner
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0  }}
        >
          <CookieTitle>We use cookies</CookieTitle>
          <CookieMessage>We use cookies and similar technologies to provide certain features and enhance the user experience. Depending on their purpose, analysis cookies may be used in addition to strictly necessary cookies. By clicking on "Accept" you declare your consent to use the aforementioned cookies. <button className="link-button" onClick={() => setOpenPrefs(true)}>Here</button> you can revoke your consent with affect for the future. For further information, please refer to our <NavLink as="a" to="/privacy-cookies-policy">Privacy and Cookies Policy</NavLink>.</CookieMessage>
          <CookieButtons>
            <Button
              type="tertiary"
              label="Accept"
              addStyle={{
                textDecoration: 'none',
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'auto',
                height: 'auto',
                fontSize: '80%',
                boxShadow: 'none',
                padding: '.5em 1.5em',
                margin: 5,
              }}
              action={() => {setAccept(true); setSeenBanner(true);}}
            />
            <Button
              type="light"
              label="Decline"
              addStyle={{ fontWeight: 'normal', width: 'auto', height: 'auto', fontSize: '80%', boxShadow: 'none', padding: '.5em 1.5em', margin: 5 }}
              action={() => {setAccept(false); setSeenBanner(true);}}
            />
            <Button
              type="light"
              label="Set Preferences"
              addStyle={{ fontWeight: 'normal', width: 'auto', height: 'auto', fontSize: '80%', boxShadow: 'none', padding: '.5em 1.5em', margin: 5 }}
              action={() => {setOpenPrefs(true); setSeenBanner(true);}}
            />
          </CookieButtons>
        </CookieBanner>
      )}
      {openPrefs && (
        <CookieConsentPrefs
          open={openPrefs}
          setOpen={setOpenPrefs}
          accept={accept}
          setAccept={setAccept}
        />
      )}
    </>
  );
};

CookieConsentPrefs.propTypes = {
  openPrefsFromOutside: PropTypes.bool,
};


CookieConsentPrefs.defaultProps = {
  openPrefsFromOutside: false,
};

export default CookieConsent;