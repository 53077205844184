import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import ButtonLeaveMessageDonate from "../../../components/ButtonLeaveMessageDonate";
import ICONS from "../../../assets/data/icons.json";
import { ReactComponent as XIcon } from "../../../assets/icons/icon-x.svg";
import { ReactComponent as CheckmarkIcon } from "../../../assets/icons/icon-checkmark.svg";
import SwipeOverlay from "../../../components/SwipeOverlay";
import Slider from "react-slick";
import leftArrowButtonIcon from "../../../assets/icons/icon-arrow-button-left.svg";

const PickerContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  z-index: 20;
  padding-top: 64px;
  @media (min-width: 1024px) {
    padding-top: 80px;
  }
  @media (min-width: 1920px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;


const IconName = styled.h3`
  color: ${(props) => props.theme.bgLight};
  font-family: "Sintony", sans-serif;
  font-size: ${14 / 16}rem;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  margin: 0;
  @media (min-width: 1920px) {
    font-size: ${18 / 16}rem};
    line-height: 28px;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.theme.bgLight};
  font-family: "Noto Serif", serif;
  font-size: ${25 / 16}rem;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  padding-left: 42px;
  padding-right: 42px;
  margin: 0;
  margin-top: 20px;
  @media (max-height: 811px) {
    font-size: ${20 / 16}rem;
  }

  @media (min-width: 1920px) {
    font-size: ${32 / 16}rem;
    line-height: ${40 / 16}rem;
    margin-top: ${45 / 16}rem;
    margin-bottom: 0;
    font-weight: semi-bold;
  }
`;

const Subtitle = styled.h2`
  color: ${(props) => props.theme.bgLight};
  font-family: "Sintony", sans-serif;
  font-weight: normal;
  font-size: ${15 / 16}rem;
  line-height: ${18 / 16}rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: ${18 / 16}rem;

  @media (max-height: 811px) {
    font-size: ${14 / 16}rem;
    margin-top: ${12 / 16}rem;
  }
  @media (min-width: 1920px) {
    padding: 0;
    font-size: ${20 / 16}rem;
    line-height: ${24 / 16}rem;
    margin-top: ${14 / 16}rem;
    width: ${554 / 16}rem;
    font-weight: normal;
  }
`;
const SubsequentText = styled.h2`
  color: ${(props) => props.theme.bgLight};
  font-family: "Sintony", sans-serif;
  font-weight: normal;
  font-size: ${15 / 16}rem;
  line-height: ${18 / 16}rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: ${18 / 16}rem;

  @media (max-height: 811px) {
    font-size: ${14 / 16}rem;
    margin-bottom: ${12 / 16}rem;
  }

  @media (min-width: 1920px) {
    padding: 0;
    margin-top: ${28 / 16}rem;
    font-size: ${20 / 16}rem;
    line-height: ${24 / 16}rem;
    margin-bottom: ${16 / 16}rem;
    width: ${554 / 16}rem;
    font-weight: normal;
  }
`;
const SlideshowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  overflow: visible;
  align-items: center;
  width: 600px;
  min-height: 300px;

  @media (max-height: 745px) {
    min-height: 250px;
  }

  @media (min-width: 768px) {
    min-height: 350px;
  }

  @media (min-width: 1385px) {
    width: 450px;
    min-height: 450px;
    overflow: hidden;
  }

  @media (min-width: 1690px) {
    width: 1000px;
    min-height: 400px;
    overflow: hidden;
  }
`;

const SlideshowView = styled.div`
  width: 100%;
  height: 100%;
  & li {
    opacity: 0.5;
    & .slick-active {
      opacity: 1;
    }
  }
  & .slick-slide div img {
    width: auto;
    max-width: none;
    height: 300px;
    @media (max-height: 745px) {
      height: 250px;
  }

  }

  @media (min-width: 768px) {
    & .slick-slide div img {
      height: 350px;
    }
  }

  @media (min-width: 1920px) {
    width: 100%;
    height: 100%;
    overflow: visible;

    & .slick-prev,
    .slick-next {
      width: 48px;
      height: 48px;
    }
   
    & .slick-slide div img {
      height: 400px;
    }
  }
  & .slick-slide {
    position: relative;
    transition: all 0.5s ease-in-out;
  }
  & .slick-slide div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .slick-active.slick-center {
    z-index: 99;
  }

  & .slick-active:not(.slick-current) {
    z-index: 10;
    opacity: 0.5;
    scale: 0.5;
  }

  & .slick-current {
    opacity: 1;
    scale: 1;
  }

  & .slick-slide:not(.slick-active) {
    opacity: 0;
    scale: 0.5;
  }
  & .slick-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    & li {
      & button {
        &::before {
          content: "•";
          color: white;
          width: 1rem;
          height: 2rem;
          line-height: 2rem;
        }
        & slick.active {
          &::before {
            color: white;
            width: 1rem;
            height: 2rem;
            line-height: 2rem;
            opacity: 1;
          }
        }
      }
    }
  }
`;

const PreviousButton = styled.button`
  position: absolute;
  top: 50%;
  left: 0;
  transform: rotate(0) translate(800%, -100%);
  border-radius: 99rem;
  border: none;
  width: ${32 / 16}rem;
  height: ${32 / 16}rem;
  background-image: url(${leftArrowButtonIcon});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 5;
  &:before {
    content: "";
  }
  @media (min-width: 1920px) {
    width: ${48 / 16}rem;
    height: ${48 / 16}rem;
  }
`;

const NextButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(180deg) translate(800%, 100%);
  border: none;
  border-radius: 99rem;
  width: ${32 / 16}rem;
  height: ${32 / 16}rem;
  background-image: url(${leftArrowButtonIcon});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 5;
  &:before {
    content: "";
  }
  @media (min-width: 1920px) {
    width: ${48 / 16}rem;
    height: ${48 / 16}rem;
  }
`;

const Image = styled.img``;

const ResponsiveButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  //margin-top: 2.3vh;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 40%;
  z-index: 9;
  button {
    margin-bottom: 1.5rem;
    width: 50%;
  }
  @media (min-width: 1920px) {
    flex-flow: row nowrap;

    width: 100%;

    button {
      width: 30%;
    }
  }
  @media (min-width: 1920px) {
    flex-flow: row nowrap;

    width: 100%;
  }
`;

const IconPicker = ({ openOnIndex, setOpenIconOverlay, setChosenIcon }) => {
  const refs = useRef(
    Array.apply(null, Array(ICONS.length)).map(() => React.createRef())
  );
  const windowRef = useRef(null);
  const [selectedIconIndex, setSelectedIconIndex] = useState(openOnIndex);
  const [numberOfSlides, setNumberOfSlides] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sliderRef = useRef(null);
  const chosenIcon = ICONS[selectedIconIndex];
  const isMobile = window.innerWidth < 1024;
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    variableHeight: true,
    speed: 400,
    arrows: false,
    afterChange: (current) => setSelectedIconIndex(current),
  };

  useLayoutEffect(() => {
    const onElementsRenderedTimeout = setTimeout(() => {
      if (refs.current[openOnIndex].current) {
        // Check if it's null
        refs.current[openOnIndex].current.scrollIntoView({
          block: "end",
          inline: "nearest",
          behavior: "smooth",
        });
      }
    }, 300);

    return () => {
      clearTimeout(onElementsRenderedTimeout);
    };
  }, [openOnIndex]);

  const onChangeIcon = (index) => {
    setSelectedIconIndex(index);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(
    () => (windowWidth < 1024 ? setNumberOfSlides(1) : setNumberOfSlides(3)),
    [windowWidth]
  );

  useEffect(() => {
    const handleScroll = () => {
      let timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        const scrollLeft = windowRef.current.scrollLeft;
        const itemWidth = windowRef.current.offsetWidth;
        const totalWidth = windowRef.current.scrollWidth;
        const totalItems = ICONS.length;
        const itemIndex = Math.round((scrollLeft / totalWidth) * totalItems);
        setSelectedIconIndex(itemIndex);
      }, 150);
    };

    if (windowRef.current) {
      windowRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (windowRef.current) {
        windowRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const onNextSlide = () => sliderRef.current.slickNext();
  const onPreviousSlide = () => sliderRef.current.slickPrev();

  return (
    <PickerContainer key="icon-picker-container">
      {windowWidth <= 812 && <SwipeOverlay timeout={2500} />}
      <Title>Choose An Icon</Title>
      <Subtitle>
        Icons are visual representations of the people and stories in the Bible.
        Orthodox Christians often choose an icon that connects to their&nbsp;prayer.
      </Subtitle>
      <SubsequentText>
        Take a moment to think about why or for whom you are lighting this
        candle and choose an option&nbsp;below.
      </SubsequentText>
      <SlideshowContainer>
        {!isMobile && <PreviousButton onClick={onPreviousSlide} />}
        <SlideshowView>
          <Slider {...settings} ref={sliderRef}>
            {ICONS.map((image, key) => (
              <React.Fragment key={key}>
                <Image
                  key={key}
                  src={image.imgUrl}
                  alt={`slideshow slide #${key}`}
                />
              </React.Fragment>
            ))}
          </Slider>
        </SlideshowView>
        {!isMobile && <NextButton onClick={onNextSlide} />}
      </SlideshowContainer>
      <IconName>{ICONS[selectedIconIndex].name}</IconName>
      <SubsequentText>{ICONS[selectedIconIndex].desc}</SubsequentText>
      <ResponsiveButtonContainer>
        <ButtonLeaveMessageDonate
          label="Choose"
          type="light"
          addStyle={
            isMobile
              ? { marginBottom: "9.5px", width: "15rem" }
              : { marginRight: "1.5rem", width: "15rem" }
          }
          icon={<CheckmarkIcon />}
          action={() => {
            setOpenIconOverlay(false);
            /** this is the problem child :[ */
            setChosenIcon(chosenIcon);
          }}
        />
        <ButtonLeaveMessageDonate
          label="Skip"
          type="dark1"
          addStyle={
            isMobile
              ? { width: "15rem" }
              : { marginRight: "1.5rem", width: "15rem" }
          }
          icon={<XIcon />}
          action={() => setOpenIconOverlay(false)}
        />
      </ResponsiveButtonContainer>
    </PickerContainer>
  );
};

IconPicker.propTypes = {
  openOnIndex: PropTypes.number,
  setOpenIconOverlay: PropTypes.func,
  setChosenIcon: PropTypes.func,
};

IconPicker.defaultProps = {
  openOnIndex: -1,
  setOpenIconOverlay: null,
  setChosenIcon: null,
};

export default IconPicker;
