
import * as React from "react";
import { useState, useRef, useLayoutEffect, useEffect } from "react";
import store from "../store";
import { useTheme } from "styled-components";

const MenuIcon = ({ width = 32, height = 32, action }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isWhiteIcon, setIsWhiteIcon] = useState(false);
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);
  const theme = useTheme();
  const colors = {
    white: theme.navWhite,
    normal: theme.navGold,
    gray: "#f7f7f7",
  };

  const handleOnClick = () => {
    action();
  };

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(() => {
    setIsWhiteIcon(state.headerThemeColor === theme.navWhite);
  }, [state.headerThemeColor]);

  return (
    <button
      style={{ border: 'none' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleOnClick()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
      >
        <g id="Group_2230" data-name="Group 2230" transform="translate(-15 -14)">
          <rect
            id="Rectangle_2306"
            className="background"
            data-name="Rectangle 2306"
            width={32}
            height={32}
            rx={16}
            transform="translate(15 14)"
            fill={isHovered && !isWhiteIcon ? colors.gray : "transparent"}
          />
            <path
              id="Path_2294"
              data-name="Path 2294"
              d="M-729.4,1991h-13.234"
              transform="translate(767.016 -1966.401)"
              fill={isWhiteIcon ? colors.white : colors.normal}
              stroke={isWhiteIcon ? colors.white : colors.normal}
              strokeLinecap="round"
              strokeWidth={2}
            />
            <path
              id="Path_2295"
              data-name="Path 2295"
              d="M-729.4,1991h-13.234"
              transform="translate(767.016 -1961)"
              fill={isWhiteIcon ? colors.white : colors.normal}
              stroke={isWhiteIcon ? colors.white : colors.normal}
              strokeLinecap="round"
              strokeWidth={2}
            />
            <path
              id="Path_2296"
              data-name="Path 2296"
              d="M-729.4,1991h-13.234"
              transform="translate(767.016 -1955.598)"
              fill={isWhiteIcon ? colors.white : colors.normal}
              stroke={isWhiteIcon ? colors.white : colors.normal}
              strokeLinecap="round"
              strokeWidth={2}
            />
        </g>
      </svg>
    </button>
  );
};
export default MenuIcon;


