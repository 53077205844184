import React, {
  useLayoutEffect,
  useState,
  useRef,
  Suspense,
  useEffect,
} from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import IconModel from "./IconModel.js";
import { motion, AnimatePresence } from "framer-motion";
import styled, { keyframes } from "styled-components/macro";
import { Canvas } from "@react-three/fiber";
import { ReactComponent as ListIcon } from "../assets/icons/32-icon-button-list-v2.svg";
import * as THREE from "three";
import {
  useGLTF,
  PerspectiveCamera,
  Html,
  CameraControls,
} from "@react-three/drei";
import { ReactComponent as CloseHotspot } from "../assets/icons/32-icon-feature.svg";
import { ReactComponent as HotspotArrowLeft } from "../assets/icons/icon-hotspot-arrowLeft.svg";
import { ReactComponent as HotspotArrowRight } from "../assets/icons/icon-hotspot-arrowRight.svg";
import { ReactComponent as LeftArrow } from "../assets/icons/32-icon-leftArrow-v2.svg";
import iconPanelSVG from "../assets/icons/icon-levelcard-bg.svg";
import gradientBackground from "../assets/images/exploreicons_mobile.png";
import gradientBackgroundDesktop from "../assets/images/exploreicons_desktop.png";
import iconsJSON from "../assets/data/icon_feature.json";
import ButtonListView from "../components/ButtonListView";
import MapButton from "../components/MapButton";
import IconList from "../components/IconList.js";
import store from "../store";
import LoadingOverlay from "../components/LoadingOverlay.js";
import background from "../assets/icons-background.png";
import mobileBackground from "../assets/icons-background-mobile.jpg";
import LevelSelectingButton from "../components/LevelSelectingButton.js";
import { useTheme } from "styled-components/macro";

const Overlay = styled(motion.div)`
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 96;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.99) 60%,
      rgba(255, 255, 255, 0.85) 100%
    );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 5;
  }
`;

const OverlayTitle = styled.h1`
  position: relative;
  font-family: "Noto Serif", serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0.5em;
  z-index: 6;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  @media (min-width: 1024px) {
    ${(props) =>
      props.iconListOpen ? `width: calc(100vw - ${470 / 16}rem);` : ""};
  }
`;

const BackButtonWrapper = styled.div`
  position: fixed;
  left: 0px;
  top: ${56 / 16}rem;
  z-index: 98;

  @media (min-width: 1024px) {
    top: ${88 / 16}rem;
  }
`;

const OverlayTagline = styled.h2`
  position: relative;
  font-family: "Sintony", sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  text-align: center;
  margin: 0 40;
  width: calc(100% - 80px);
  z-index: 6;
`;

const StyledHotspotPopup = styled.div`
  width: 473px;
  height: 10rem;
  background: rgba(255, 255, 255, 0.87);
  color: #000;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 98;
  border-radius: 8px;

  & h3 {
    margin; 0;
    margin-bottom: 8px;
    font-family: 'Noto Serif', serif;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }
  & h4 {
    margin; 0;
    margin-bottom: 8px;
    margin-left: 8%;
    margin-right: 8%;
    font-family: 'Sintony', sans-serif;
    font-size: 13px;
    font-weight: normal;
    line-height: normal;
  }


  @media (max-width: 812px) {
    width: ${300 / 16}rem;
    font-size: 20pt;

  }
`;

const StyledHotspotCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: -1.875rem;
  border: none;
  transform: translateY(-50%);
`;

const StyledHotSpotArrowLeft = styled.button`
  position: absolute;
  bottom: -3.25rem;
  left: -1.875rem;

  border: none;
  transform: translateY(-50%);
`;

const StyledHotSpotArrowRight = styled.button`
  position: absolute;
  bottom: -3.25rem;
  right: -1.875rem;
  border: none;
  transform: translateY(-50%);
`;

const BottomLeftButtonWrapper = styled.div`
  position: fixed;
  left: ${14 / 16}rem;
  bottom: ${15 / 16}rem;
  z-index: 1000;
`;

const BottomRightButtonWrapper = styled.div`
  position: fixed;
  right: ${15 / 16}rem;
  bottom: ${15 / 16}rem;
  z-index: 1000;
`;

const Header = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  padding: 16px 12px;
  z-index: 97;
  background: ${(props) =>
    props.hidebg
      ? "transparent"
      : "linear-gradient(180deg, rgba(22,41,57,0.7) 0%, rgba(0,0,0,0) 100%)"};
  width: 100%;
  height: 64px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 1024px) {
    top: 80px;
    height: 80px;
  }
  @media (min-width: 1366px) {
    padding: 40px 50px;
  }
`;

const AnimationOverlay = styled(motion.button)`
  width: 100%;
  height: 100vh;
  color: ${(props) => props.theme.textLightDefault};
  background: ${(props) =>
    props.isMobile
      ? `url(${gradientBackground})`
      : `url(${gradientBackgroundDesktop})`};
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 97;
  background-position: center 30%;
`;

const Panel = styled.div`
  width: 340px;
  height: auto;
  background: transparent;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  &:after {
    content: "";
    background-image: url(${iconPanelSVG});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 40%;
    position: absolute;
    top: 0;
    left: 0;
    width: 340px;
    height: 140px;
  }
`;

const Level = styled.h1`
  width: 150px;
  font-family: "Noto Serif", serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin: 0 auto;
  color: ${(props) => props.theme.neatural1};
  text-align: center;
`;

const Info = styled.p`
  width: 100%;
  font-family: "Sintony", sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  margin-top: 1rem;
  color: ${(props) => props.theme.neatural1};
  text-align: center;
`;


const TAU = Math.PI * 2;
const normalizeAngle = (angle) => {
  return THREE.MathUtils.euclideanModulo(angle, TAU);
}
const absoluteAngle = (targetAngle, sourceAngle) => {
  const angle = targetAngle - sourceAngle;
  return THREE.MathUtils.euclideanModulo(angle + Math.PI, TAU) - Math.PI;
}

const Icons = () => {
  const isMobile = window.matchMedia("(pointer:coarse) && (max-width: 1023px)").matches;
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [state, setState] = useState(store.initialState);
  const [viewMode, setViewMode] = useState(true);
  const [showLvlPanel, setShowLvlPanel] = useState(false);
  const [hotspotIndx, setHotspotIndx] = useState(0);
  const [numOfHotspots, setNumOfHotspots] = useState(0);
  const [controlsEnabled, setControlsEnabled] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [smoothTime, setSmoothTime] = useState(2);
  const [restThreshold, setRestThreshold] = useState(0.85);
  const [hideIconUI, setHideIconUI] = useState(false);
  const prevLvl = useRef(0);
  const bgAudio = useRef(null);
  const sub = useRef(null);
  const navigate = useNavigate();
  const camera = useRef(null);
  const controls = useRef(null);
  const animationEndTimeout = useRef(null);
  const theme = useTheme();

  const levelPositions = [
    {
      theta: -3.14,
      phi: 3,
      target: [0, 9, 1],
      position: [0, 5, 0],
      zoom: 1.5,
    },
    {
      theta: -3.14,
      phi: 1.6,
      target: [0, 8, 0],
      position: [0, 3, 0],
      zoom: 1.65,
    },
    {
      theta: -3.14,
      phi: 1.3,
      target: [0, 3.5, 0],
      position: [0, 1.5, 0],
      zoom: 1.45,
    },
    {
      theta: -4,
      phi: 1.2,
      target: [0, 4.1, 0],
      position: [0, 1.7, 0],
      zoom: 1.75,
    },
  ];

  const handleListViewClicked = () => {
    if (state.iconListOpen) {
      store.setHeaderVisible(true);
      store.setMenuOpen(false);
      store.iconListOpen(false);
      setHideIconUI(false);
    } else {
      store.setMenuOpen(true);
      isMobile && store.setHeaderVisible(false);
      store.iconListOpen(true);
      isMobile && setHideIconUI(true);
    }
    store.setMenuOpen(false);
  };

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    store.lastPage("/explore-icons");
    store.setHeaderThemeColor(theme.navWhite);

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(() => {
    const totalDuration = 5000; // 8 seconds in milliseconds
    const intervalTime = 100; // 100ms

    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) => {
        const newPercentage =
          prevPercentage + (intervalTime / totalDuration) * 100;
        return newPercentage > 100 ? 100 : newPercentage;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    !state.iconListOpen && setHideIconUI(false);
    store.setHeaderColorAll(theme.navWhite);
  }, [state.iconListOpen]);

  useEffect(() => {
    store.iconLevelData(iconsJSON[state.iconLevelIdx]);
    /** ON LEVEL SWITCH */
    if (camera.current && controls.current) {
      setShowLvlPanel(true);
      // enableControls(false);
      // removing two and three finger controls
      controls.current.touches.two = 32768;
      controls.current.touches.three = null;
      controls.current.mouseButtons.wheel = 16;
      controls.current.mouseButtons.right = null;
      controls.current.mouseButtons.middle = null;

      // reset hotspots
      setHotspotIndx(0);

      store.iconHotspot({ open: false, info: { title: "", description: "" } });

      controls.current.moveTo(
        ...levelPositions[state.iconLevelIdx].target,
        true
      );
      setTimeout(() => {
        controls.current.rotateTo(
          normalizeAngle(levelPositions[state.iconLevelIdx].theta),
          levelPositions[state.iconLevelIdx].phi,
          true
        );
        controls.current.zoomTo(levelPositions[state.iconLevelIdx].zoom, true);
      }, [1500]);

      prevLvl.current = state.iconLevelIdx;
    }
  }, [state.iconLevelIdx]);

  useEffect(() => {
    console.log(state.iconHotspot);
  }, [state.iconHotspot]);

  const onCloseHotspot = () => {
    const allSpots = document.getElementsByClassName("hotspot");
    for (let i = 0; i < allSpots.length; i++) {
      allSpots[i].classList.remove("selected");
      allSpots[i].classList.add("animate");
    }

    enableControls(true);
    store.iconHotspot({ open: false, info: { title: "", description: "" } });
    controls.current.zoomTo(levelPositions[state.iconLevelIdx].zoom, true);
  };

  const changeLevel = (newLevelIdx) => store.iconLevelIdx(newLevelIdx);

  const goBackToTour = () => {
    navigate("/tour");
  };

  const setToggleViewMode = () => {
    setViewMode((old) => !old);
    store.hideIconUI(!state.hideIconUI);
  };

  const onRemovePanel = () => {
    setShowLvlPanel(false);
    enableControls(true);
  };

  const onCompletePanelAnimation = () => {
    animationEndTimeout.current = setTimeout(() => {
      if (showLvlPanel) setShowLvlPanel(false);
      clearTimeout(animationEndTimeout.current);
    }, 4000);
  };

  const toPrevHotspot = () => {
    console.log(hotspotIndx, numOfHotspots);
    const indx = hotspotIndx;
    let newIndx = indx - 1;

    if (newIndx < 0) {
      newIndx = numOfHotspots - 1;
    }
    setHotspotIndx(newIndx);
  };

  const toNextHotspot = () => {
    console.log(hotspotIndx, numOfHotspots);
    const indx = hotspotIndx;
    let newIndx = indx + 1;

    if (newIndx > numOfHotspots - 1) {
      newIndx = 0;
    }
    setHotspotIndx(newIndx);
  };

  const setNewIndx = (newNum) => {
    setHotspotIndx(newNum);
  };
  const newNewNum = (newNum) => {
    setNumOfHotspots(newNum);
  };
  const toggleLvlPanel = (togg) => {
    setShowLvlPanel(togg);
  };
  const enableControls = (enable) => {
    console.log("enabling controls ", enable);
    setControlsEnabled(enable);
    store.disableControls(!enable);

    // if (!enable) {
    //   let enableTimeout = null;
    //   if (enableTimeout) clearTimeout(enableTimeout);
    //   enableTimeout = setTimeout(() => {
    //     setControlsEnabled(true);
    //     store.disableControls(false);
    //     console.log("enabling controls ", enable);
    //   }, 2000);
    // }
  };
  const settingLoaded = (isLoaded) => {
    setLoaded(isLoaded);
  };
  const settingSmoothTime = (smoofTime) => {
    setSmoothTime(smoofTime);
  };
  const settingRestThreshold = (restTime) => {
    setRestThreshold(restTime);
  };

  /** just background audio things */
  useLayoutEffect(() => {
    if (!state.mutedBg) bgAudio.current.muted = false;
    else if (state.mutedBg) bgAudio.current.muted = true;

    console.log("muting bg from icons");
  }, [state.mutedBg]);

  return (
    <Wrapper iconListOpen={state.iconListOpen}>
      <Helmet>
        <title>St. Nicholas Tour - Icon Exploration</title>
        <meta
          name="description"
          content="Discover the iconography of the Saint Nicholas National Shrine by exploring the Dome, Christological Cycle, Sanctuary Cycle, and Life Cycle of Saint Nicholas."
        />
        <meta name="keywords" content="Icon, Iconography, Christ, Saint" />
      </Helmet>

      <AnimatePresence>{state.iconListOpen && <IconList  />}</AnimatePresence>

      <audio
        ref={bgAudio}
        src="/audios/Peter_Peloponnesios_Cherubic_Hymn__Opening_Section.mp3"
        autoPlay={true}
        loop={true}
        muted={state.mutedBg}
      />

      <AnimatePresence>
        {!loaded && (
          <LoadingOverlay
            height={window.innerHeight}
            tagline="Icon Exploration"
            subtagline="Click and drag to explore the icons in the Nave."
            percentLoaded={loadingPercentage.toFixed(0)}
            backgroundImage={background}
            hideBlurb={true}
          />
        )}
      </AnimatePresence>
      <Suspense
        fallback={
          <LoadingOverlay
            height={window.innerHeight}
            tagline="Icon Exploration"
            subtagline="Click and drag to explore the icons in the Nave."
            percentLoaded={loadingPercentage.toFixed(0)}
            backgroundImage={background}
            hideBlurb={true}
          />
        }
      >
        {!hideIconUI && (
          <>
            <BackButtonWrapper>
              <MapButton
                label="Back"
                action={() => navigate(-1)}
                type="back"
                icon={<LeftArrow />}
                addClass="icon-first"
              />
            </BackButtonWrapper>

            <BottomLeftButtonWrapper>
              <LevelSelectingButton changeLevel={changeLevel} controlsEnabled={controlsEnabled} />
            </BottomLeftButtonWrapper>

            <BottomRightButtonWrapper style={{ right: state.iconListOpen ? '31rem' : `${15 / 16}rem`}}>
              <ButtonListView
                label="List View"
                action={handleListViewClicked}
                type="light"
                icon={<ListIcon />}
                addClass="icon-first"
              />
            </BottomRightButtonWrapper>
          </>
        )}
        <AnimatePresence>
          {showLvlPanel && (
            <AnimationOverlay
              key={`panel-${showLvlPanel.toString()}`}
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                ease: "easeInOut",
                duration: 1,
                delay: 0.8,
              }}
              exit={{ opacity: 0, transitionEnd: { display: "none" } }}
              onAnimationComplete={() => onCompletePanelAnimation()}
              onClick={() => onRemovePanel()}
              isMobile={isMobile}
            >
              <Panel>
                <Level>
                  {state.iconLevelData.level}
                  <br />
                  {state.iconLevelData.title}
                </Level>
                <Info>{state.iconLevelData.info}</Info>
              </Panel>
            </AnimationOverlay>
          )}
        </AnimatePresence>

        <Canvas
          dpr={window.devicePixelRatio}
          style={{ pointerEvents: controlsEnabled ? "all" : "none" }}
        >
          <PerspectiveCamera
            ref={camera}
            makeDefault
            rotation={[0, 0, 0]}
            fov={60}
            position={[0, 2, -5]}
            near={1}
            far={1000}
          ></PerspectiveCamera>
          <CameraControls
            ref={controls}
            target={[0, 2, 0]}
            position={[0, 2, -5]}
            polarAngle={1.6}
            azimuthAngle={Math.PI}
            maxDistance={4}
            minZoom={0.65}
            maxZoom={3}
            restThreshold={restThreshold}
            smoothTime={smoothTime}
            draggingSmoothTime={0.6}
            dollyDragInverted={true}
          />
          <Suspense fallback={<IconModel url="/interior_final_baked_v6.glb" />}>
            <IconModel
              store={store}
              url="/interior_final_baked_v6.glb"
              storeState={state}
              setHNumber={newNewNum}
              hotspotIndx={hotspotIndx}
              setHotspotIndx={setNewIndx}
              camera={camera}
              controls={controls}
              levelPositions={levelPositions}
              iconLevelIdx={state.iconLevelIdx}
              showLvlPanel={showLvlPanel}
              setShowLvlPanel={toggleLvlPanel}
              setToggleViewMode={setToggleViewMode}
              controlsEnabled={controlsEnabled}
              enableControls={enableControls}
              loaded={loaded}
              settingLoaded={settingLoaded}
              settingSmoothTime={settingSmoothTime}
              settingRestThreshold={settingRestThreshold}
            />
          </Suspense>
        </Canvas>
        {state.iconHotspot.open && (
          <StyledHotspotPopup>
            <StyledHotspotCloseButton onPointerUp={() => onCloseHotspot()}>
              <CloseHotspot />
            </StyledHotspotCloseButton>
            <h3>{state.iconHotspot.info.title}</h3>
            <h4>{state.iconHotspot.info.description}</h4>
            {numOfHotspots > 1 && (
              <>
                <StyledHotSpotArrowLeft onPointerUp={() => toPrevHotspot()}>
                  <HotspotArrowLeft />
                </StyledHotSpotArrowLeft>

                <StyledHotSpotArrowRight onPointerUp={() => toNextHotspot()}>
                  <HotspotArrowRight />
                </StyledHotSpotArrowRight>
              </>
            )}
          </StyledHotspotPopup>
        )}
      </Suspense>
    </Wrapper>
  );
};

Icons.defaultProps = {};
Icons.propTypes = {};

export default Icons;
