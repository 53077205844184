import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import store from "../store";
import { ReactComponent as PlayIcon } from "../assets/icons/32-icon-play.svg";
import { ReactComponent as PauseIcon } from "../assets/icons/32-icon-pause.svg";

const StyledButton = styled.button`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  font-family: "Sintony", sans-serif;
  font-size: ${12 / 16}rem;
  font-weight: bold;
  color: ${(props) => props.theme.primaryDark};
  text-decoration: none;
  width: ${98 / 16}rem;
  border: none;
  text-align: center;

  svg {
    margin-bottom: 1px;
    width: ${32 / 16}rem;
    height: ${32 / 16}rem;
  }
`;

const MenuButton = ({
  label,
  icon,
  classState,
  type,
  action,
  addClass,
  addStyle,
  link,
}) => (
  <StyledButton
    onClick={action}
    className={`${type} ${classState}`}
    style={addStyle}
    as={link ? "a" : "button"}
    href={link ? link : ""}
  >
    {icon}
    {label}
  </StyledButton>
);

export default MenuButton;

