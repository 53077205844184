import React from 'react';

const NotFound = () => {
  return(
    <div>404</div>
  );
};

NotFound.defaultProps = {};
NotFound.propTypes = {};

export default NotFound;