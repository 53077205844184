import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useTheme } from "styled-components";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../store";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { ReactComponent as CloseIcon } from "../assets/icons/icon-close.svg";
import HotspotList from "./HotspotList";
import LanguageList from "./LanguageList";
import { ReactComponent as LogoIcon } from "../assets/icons/modified_logo.svg";
import Button from "./Button";
import HeaderMuteIcon from "./HeaderMuteIcon";
import Menu from "./Menu";
import SoundIcon from "./SoundIcon";
import MenuIcon from "./MenuIcon";


const AudioToggle = ({ muted, toggleAction, showSound }) => {
  if (!showSound) return null;

  return (
    <SoundIcon muted={muted} action={toggleAction} />
  );
};

AudioToggle.propTypes = {
  muted: PropTypes.bool,
  toggleAction: PropTypes.func,
};
AudioToggle.defaultProps = {
  muted: true,
  toggleAction: null,
};

const HeaderContainer = styled(motion.div)`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: ${15 / 16}rem;
  padding-bottom: ${9 / 16}rem;
  background: ${(props) => props.nogradient ? "none" : "linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%,rgba(0, 0, 0, 0) 100%)"};
  z-index: 101;
  ${(props) => (props.openedHotspots || props.iconListOpen) ? `width : calc(100% - ${470 / 16}rem);` : `width: 100%;`}

  @media (min-width: 1024px) {
    padding: ${21/16}rem ${24/16}rem 0rem ${24/16}rem;

    svg {
      width: ${48 / 16}rem;
      height: ${48 / 16}rem;
    }
  }
`;

const Logo = styled.div`
  position: absolute;
  padding: 0;
  display: flex;
  pointer-events: none;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  height: ${32 / 16}rem;
  ${(props) => props.menuOpen && `margin-right: ${32 / 16}rem;`}
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    height: ${42/16}rem;
  }

  svg {
    max-height: 100%;
    width: auto;
    pointer-events: all;
    :hover {
      cursor: pointer;
    }
  }

  svg path {
    fill: ${(props) => props.themeColor};
  }
`;

const Header = ({ openedHotspots, onOpenedHotspots }) => {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [openedMap, setOpenedMap] = useState(false);
  const [openedLang, setOpenedLang] = useState(false);
  const [useWhiteTheme, setUseWhiteTheme] = useState(false);
  const [openedSocials, setOpenedSocials] = useState(false);
  const [openedButtons, setOpenedButtons] = useState(false);
  const [state, setState] = useState(store.initialState);
  const location = useLocation();
  const navigate = useNavigate();
  const sub = useRef(null);
  const theme = useTheme();
  const isInTour =
    location.pathname.includes("/tour") ||
    location.pathname.includes("light-a-candle");

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    // picking language based on browser language or preset language
    const lang = document.documentElement.lang.toLowerCase();
    let presetLang = { lang: "English", id: "en" };
    switch (lang) {
      case lang.includes("en"):
        presetLang = { lang: "English", id: "en" };
        break;
      case lang.includes("el"):
        presetLang = { lang: "Ελληνικά", id: "el" };
        break;
      case lang.includes("es"):
        presetLang = { lang: "Español", id: "es" };
        break;
      case lang.includes("zh-cn"):
        presetLang = { lang: "中文(简)", id: "zh-cn" };
        break;
      case lang.includes("zh-tw"):
        presetLang = { lang: "中文(繁)", id: "zh-tw" };
        break;
      case lang.includes("fr"):
        presetLang = { lang: "Français", id: "fr" };
        break;
      case lang.includes("ar"):
        presetLang = { lang: "عربى", id: "ar" };
        break;
      case lang.includes("ru"):
        presetLang = { lang: "русский", id: "ru" };
        break;
      case lang.includes("it"):
        presetLang = { lang: "Italiano", id: "it" };
        break;
      case lang.includes("ja"):
        presetLang = { lang: "日本語", id: "ja" };
        break;
      default:
        presetLang = { lang: "English", id: "en" };
        break;
    }
    if (localStorage.getItem("lang"))
      store.language(JSON.parse(localStorage.getItem("lang")));
    else store.language(presetLang);

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(
    () => setUseWhiteTheme(state.headerThemeColor === theme.navWhite),
    [state.headerThemeColor]
  );
  useEffect(() => {
    store.setLandingHeader(location.pathname !== "/tour");
    store.setHeaderThemeColor(theme.navWhite);

    if (state.menuOpen) store.setHeaderThemeColor(theme.navGold);
    if (location.pathname.includes("/tour") || location.pathname === "/tour" || location.pathname === "/tour/") store.setHeaderThemeColor(theme.navGold);

  }, [location.pathname, state.menuOpen]);

  useEffect(() => {
    if (openedMap) disablePageScroll();
    else enablePageScroll();
  }, [openedMap]);

  useEffect(() => {
    if (state.menuOpen) disablePageScroll();
    else enablePageScroll();

    if(state.menuOpen) store.setHeaderThemeColor(theme.navGold);
    else store.setHeaderThemeColor(state.phaseColor);
  }, [state.menuOpen]);

  useEffect(() => {
    console.log(state.phaseColor)
  }, [state.phaseColor]);

  const toggleAudioMute = () => {
    if (location.pathname.includes("/tour")) {
      state.audioTrack.muted = !state.audioTrack.muted;
      if (!state.audioTrack.muted) state.bgAudioTrack.play();
    }
    store.mutedBg(!state.mutedBg);

    /** muting any activly playing hotspot videos on bg audio unmute */
    if (!state.audioTrack.muted) {
      const allVideos = document.querySelectorAll('video');
      for (let i = 0; i < allVideos.length; i++) {
        const video = allVideos[i];
        if (!video.muted) video.muted = true;
      }
    }
  };

  const onLangToggle = () => {
    setOpenedLang((oldOpen) => !oldOpen);
    setOpenedMenu(false);
    setOpenedMap(false);
    setOpenedSocials(false);
  };

  const handleMenuButton = () => {
    store.toggleMenu();
  };

  const onScrollTop = () => {
    if (location.pathname !== "/") {
      store.setHeaderColorAll(theme.navGold);
      state.menuOpen && store.toggleMenu();
      navigate("/");
    }
    state.audioTrack.pause();
    state.audioTrack.currentTime = 0;
    window.focus();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
    store.showTour(false);
  };

  return (
    <>
      {state.headerVisible &&
      <HeaderContainer
        key={state.showTour}
        style={{
          display: window.location.pathname === "/" ? "none" : "block",
        }}
        animate={{
          transform: state.hideFrame ? "translateY(-266%)" : "translateY(0%)",
          boxShadow: "0px 3px 6px #00000029",
          justifyContent: !isInTour ? "center" : "space-between",
        }}
        whiteTheme={state.headerThemeColor === theme.navWhite}
        openedHotspots={state.openedHotspots}
        iconListOpen={state.iconListOpen}
        nogradient={state.menuOpen || window.location.pathname === "/tour" || window.location.pathname === "/tour/" }
      >
        <AnimatePresence>
          <motion.div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "space-between",
            }}
            animate={{
              width: "100%",
              opacity: 1,
              transform: "translateY(0%)",
            }}
            initial={{
              width: "100%",
              opacity: 0,
              transform: "translateY(-100%)",
            }}
            exit={{
              width: "100%",
              opacity: 0,
              transform: "translateY(-100%)",
            }}
          >
            {state.menuOpen ? (
              <Button
                type="header"
                icon={<CloseIcon />}
                action={store.toggleMenu}
                addStyle={{
                  paddingLeft: 0,
                }}
              />
            ) : (
              <MenuIcon action={store.toggleMenu} />
            )}

            <Logo
              theme={theme}
              themeColor={state.headerThemeColor}
              menuOpen={state.menuOpen}
              style={{ border: "none" }}
            >
              <LogoIcon onClick={onScrollTop}/>
            </Logo>
            {(location.pathname.includes("/tour") ||
              location.pathname.includes("/light-a-candle") ||
              location.pathname.includes("/explore-icons")) && (
              <AudioToggle
                muted={state.mutedBg}
                toggleAction={() => toggleAudioMute()}
                theme={theme}
                themeColor={state.headerThemeColor}
                showSound={state.showSoundIcon && !state.menuOpen}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </HeaderContainer>
        }

      <AnimatePresence>
        {state.menuOpen ? (
          <Menu
            opened={state.menuOpen}
            onOpenedHotspots={store.setOpenHotspots}
          />
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {state.openedHotspots && (
          <HotspotList onCloseList={store.setOpenHotspots} />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {openedLang && <LanguageList onCloseList={onLangToggle} />}
      </AnimatePresence>
    </>
  );
};

Header.propTypes = {
  openedHotspots: PropTypes.bool,
  onOpenedHotspots: PropTypes.func,
};
Header.defaultProps = {
  openedHotspots: false,
  onOpenedHotspots: null,
};

export default Header;
