import { useState, useRef, useLayoutEffect, useEffect } from "react";
import styled, { useTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import store from "../store";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ButtonIcons from "./ButtonIcons";
import MenuButton from "./MenuButton";
import { ReactComponent as InfoIcon } from "../assets/icons/icon-menu-info.svg";
import { ReactComponent as CandleIcon } from "../assets/icons/icon-menu-candles.svg";
import { ReactComponent as LocationIcon } from "../assets/icons/32-icon-location-v2.svg";
import { ReactComponent as ArrowAroundIcon } from "../assets/icons/24-icon-360-v2.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/icon-menu-facebook.svg";
import { ReactComponent as LinkedInIcon } from "../assets/icons/icon-menu-linkedin.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/x-twitter.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram.svg";
import { ReactComponent as DonateIcon } from "../assets/icons/icon-menu-donate.svg";
import { ReactComponent as WebsiteIcon } from "../assets/icons/icon-menu-web.svg";
import { ReactComponent as MusicIcon } from "../assets/icons/icon-menu-music.svg";

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 97;
  background: ${(props) => props.theme.bgNavyOverlay};
  opacity: 0.8;
`;

const MenuContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
  background: transparent;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${551 / 16}rem;
  padding-top: ${50 / 16}rem;
  background: ${(props) => props.theme.bgLight};
  border-radius: 0 0 20px 20px;
  position: relative;
  z-index: 98;

  @media (min-width: 1024px) {
    height: ${670 / 16}rem;
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 92%;
  @media (min-width: 1024px) {
    width: 96%;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: ${10 / 16}rem;
  border-bottom: 1px solid #ccc;

  @media (min-width: 1024px) {
    height: ${27 / 16}rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 99;
  }
`;

const MenuItem = styled(NavLink)`
  width: 100%;
  height: ${64 / 16}rem};
  font-size: ${18 / 16}rem;
  color: ${(props) => props.theme.textMenu} !important;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-family: "Noto Serif", serif;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #F4F3F0;
  }
  &::after {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  &:visited {
    color: ${(props) => props.theme.textMenu} !important;
  }

  @media (min-width: 1024px) {
    font-size: ${21 / 16}rem;
    line-height: ${33 / 16}rem;
    font-weight: semibold;
    height: ${76 / 16}rem;
  }
`;

const AlignCenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const IconRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: ${344 / 16}rem;
  padding-top: ${27 / 16}rem;

  a:hover {
    color: ${(props) => props.theme.textMenu};
  }

  @media (min-width: 1024px) {
    padding-top: ${35 / 16}rem;
  }
`;

const SocialIconRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${116 / 16}rem;
  padding-top: ${47 / 16}rem;
`;

const Copyright = styled.span`
  position: absolute;
  font-size: ${10 / 16}rem;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
  color: #939393;
  white-space: nowrap;
  text-align: center;
  a {
    color: ${(props) => props.theme.textMenu};
  }
`;

const Menu = ({ onOpenedHotspots }) => {
  const [state, setState] = useState(store.initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const setToggleDonationAlert = () => store.openedExternalLinkAlert({ opened: true, type: "donation" });
  const setToggleSpotifyAlert = () => store.openedExternalLinkAlert({ opened: true, type: "spotify", link: "https://open.spotify.com/playlist/6ktQ311zcOT7BpPq50EJY4?si=970cd544eb4e4b91&nd=1" });
  const setToggleWebsiteAlert = () => store.openedExternalLinkAlert({ opened: true, type: "website", link: "https://stnicholaswtc.org/" });

  useLayoutEffect(() => {
    const subscription = store.subscribe(setState);
    store.init();
    return () => store.unsubscribe(subscription);
  }, []);

  useEffect(() => {
    if (state.menuOpen) {
      store.setHeaderThemeColor(theme.navGold);
    } else {
      store.setHeaderThemeColor(state.phaseColor);
    }
  }, [location, state.menuOpen]);

  return (
    <>
      {state.menuOpen && (
        <MenuContainer>
          {/* <Button action={store.toggleMenu} /> */}
          <MenuWrapper style={{ justifyContent: "flex-start" }}>
            <Spacer />{" "}
            {/* Spacer for the top of the menu and to create the full length border at the bottom of the navbar */}
            <MenuItemContainer>
              <MenuItem to="/about" onClick={store.toggleMenu}>
                <InfoIcon />
                About the Tour
              </MenuItem>
              <MenuItem
                to="/tour"
                onClick={() => {
                  store.toggleMenu();
                  store.setHeaderColorAll(theme.navGold);
                }}
              >
                <LocationIcon />
                Tour Stops
              </MenuItem>
              <MenuItem to="/light-a-candle" onClick={store.toggleMenu}>
                <CandleIcon />
                Light a Candle
              </MenuItem>
              <MenuItem to="/explore-icons" onClick={store.toggleMenu}>
                <ArrowAroundIcon />
                Explore Icons
              </MenuItem>
            </MenuItemContainer>
            <AlignCenterColumn>
              <IconRow>
                <MenuButton
                  action={setToggleDonationAlert}
                  label="Make a Donation"
                  icon={<DonateIcon />}
                />
                <MenuButton
                  action={setToggleWebsiteAlert}
                  label="St. Nicholas National Shrine Website"
                  icon={<WebsiteIcon />}
                />
                <MenuButton
                  action={setToggleSpotifyAlert}
                  label="Music Playlist"
                  icon={<MusicIcon />}
                />
              </IconRow>

              <SocialIconRow>
                <ButtonIcons
                  isLink={true}
                  link="https://www.facebook.com/StNicholasWTC/"
                  label=""
                  type="light"
                  icon={<FacebookIcon />}
                />
                <ButtonIcons
                  isLink={true}
                  link="https://twitter.com/StNicholasWTC"
                  label=""
                  type="light"
                  icon={<TwitterIcon />}
                />
                <ButtonIcons
                  isLink={true}
                  link="https://www.instagram.com/stnicholaswtc"
                  label=""
                  type="light"
                  icon={<InstagramIcon />}
                />
                <ButtonIcons
                  isLink={true}
                  link="https://www.linkedin.com/company/st-nicholas-wtc/"
                  label=""
                  type="light"
                  icon={<LinkedInIcon />}
                />
              </SocialIconRow>
            </AlignCenterColumn>
            <Copyright>
              &copy; All Rights Reserved St Nicholas Shrine{" "}
              {new Date().getFullYear()}
              <br />
              <NavLink as="a" to="/privacy-cookies-policy">
                Privacy and Cookies Policy
              </NavLink>
            </Copyright>
          </MenuWrapper>
          <Background onClick={store.toggleMenu} />
        </MenuContainer>
      )}
    </>
  );
};

Menu.propTypes = { onOpenedHotspots: PropTypes.func };
Menu.defaultProps = { onOpenedHotspots: null };

export default Menu;
