// import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef } from "react";
import store from "../store";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import backgroundImageMobile from "../assets/about_image.jpg";
import backgroundImage from "../assets/background-about-desktop.png";
import { useTheme } from "styled-components";
import MapButton from "../components/MapButton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../assets/icons/32-icon-leftArrow-v2.svg";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  color: black;
`;

const BackgroundWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImageMobile});
  background-size: cover;
  background-position: center;
  z-index: 1;
  @media (min-width: 1024px) {
    background-image: url(${backgroundImage});
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.64;
    width: 100%;
    height: 100%;
  }
`;

const LeftDesktopGradient = styled.div`
  @media (min-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 36%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.64) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;
const RightDesktopGradient = styled.div`
  @media (min-width: 1024px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 36%;
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.64) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;
const Title = styled.h1`
  margin-top: 12%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Noto Serif", serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  width: 100%;
  text-align: center;
  color: #fffefa;

  z-index: 1;

  @media (min-width: 1024px) {
    max-width: ${590 / 16}rem;
  }

  @media (max-width: 800px) {
    font-size: 25px;
    line-height: 30px;
  }

  @media (max-width: 600px) {
    font-size: 25px;
    line-height: 30px;
    margin-top: 22%;
  }
`;

const Subtitle = styled.h2`
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Noto Serif", serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 38px;
  width: 100%;
  text-align: center;
  color: #fffefa;

  z-index: 1;

  @media (min-width: 1024px) {
    max-width: ${590 / 16}rem;
  }

  @media (max-width: 800px) {
    font-size: 16px;
    line-height: normal;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: normal;
    margin-top: 22%;
  }
`;

const Image = styled.img`
  padding: 0 ${44 / 16}rem;
  @media (min-width: 1024px) {
    max-width: 30%;
  }
`;

const BackButtonWrapper = styled.div`
  position: fixed;
  left: 0px;
  top: ${56 / 16}rem;
  z-index: 98;

  @media (min-width: 1024px) {
    top: ${88 / 16}rem;
  }
`;

const TextWrapper = styled.div`
  padding-top: ${64 / 16}rem;
  padding-left: ${15 / 16}rem;
  padding-right: ${15 / 16}rem;
  width: 100%;
  height: auto;
  z-index: 10;
  @media (min-width: 1024px) {
    padding-top: ${80 / 16}rem;
  }
`;

const Text = styled.p`
  z-index: 1;
  color: #fffefa;
  padding-bottom: 5rem;
  margin-top: ${56 / 16}rem;
  margin-left: auto;
  margin-right: auto;
  font-family: "Sintony", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 30px;
  text-align: center;
  @media (min-width: 1024px) {
    max-width: ${590 / 16}rem;
    font-size: 17px;
  }

  &.first {
    margin-top: 1rem;
  }
`;

const About = () => {
  const [state, setState] = React.useState(store.initialState);
  const sub = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    store.setHeaderColorAll(theme.navWhite);

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>St. Nicholas Tour - A New Perspective</title>
        <meta
          name="description"
          content="Experience an immersive 3D photogrammetry tour of the accessible Saint Nicholas National Shrine while learning about its hisotry, design, iconography, and more."
        />
        <meta name="keywords" content="Shrine, Guide, Photogrammetry, Tour" />
      </Helmet>
      <BackButtonWrapper>
        <MapButton
          label="Back"
          action={() => navigate(-1)}
          type="back"
          icon={<LeftArrow />}
          addClass="icon-first"
        />
      </BackButtonWrapper>
      <Wrapper>
        <BackgroundWrapper>
          <LeftDesktopGradient />
          <RightDesktopGradient />
          <Background />
        </BackgroundWrapper>
        <TextWrapper>
          <Title>A New Perspective on the Saint Nicholas National Shrine</Title>
          <Text className="first">
          On this tour, you will learn about how the Shrine–through its design and traditions–embody hope, life, and resilience. As you navigate to different areas, you will have the chance to explore the Shrine's features closely while a narrator guides you through its stories, spaces, and the practices of Orthodox Christianity.
            <br />
            <br />
            The tour's design reflects The Saint Nicholas National Shrine's goals: to be welcoming and accessible. As you use it–whether you are on or offsite–you should feel not just like you are looking at images from afar, but actually inside and moving through the Saint Nicholas Shrine with a guide.
            <br />
            <br />
            To create this immersive and intimate experience, Gallagher & Associates (G&A) used a technique called 3D photogrammetry, which helped to recreate the site in virtual space with high accuracy.  In contrast to traditional virtual tours, which enable users to look in all directions from several fixed spots, photogrammetry stitches together hundreds of still photographs to create a three-dimensional model one can move through and view from all angles. As a result, the app offers a perspective that enhances the one you get onsite. You can explore the Shrine's central space–the Nave–not only from the ground level, but high up, as though you were standing close to the dome. Additionally, you can learn about the Shrine's history, ceremonies, and iconography through vivid linear guided experiences, produced using drone and ground-level filming as well as archival film and images.
          </Text>
          <Title style={{ marginTop: 0 }}>Tour App Credits</Title>
          <Text className="first">
          <b>Narrator</b><br/>
          George Stephanopoulos<br/>
          <br/>
          <b>Translations</b><br/>
          Eriksen Translations Inc.<br/> 
          <br/>
          <b>Music</b><br/>
          Cappella Romana<br/>
          <br/>
          <b>App Design Team</b><br/>
          <b>G&A</b><br/>
          Jonathan Cohen, <i>Director of Technology</i><br/>
          Shir David, <i>Motion Designer</i><br/>
          Taiwo Demola, <i>Content Researcher</i><br/>
          Josh Gallagher, <i>Brand Director</i><br/>
          Josh Hartley, <i>Art Director</i><br/>
          Stephanie Land, <i>Senior Integrated Producer</i><br/>
          Jessica Lautin, <i>Director of Content Strategy</i><br/>
          Michael G Lewis, <i>Creative Director</i><br/>
          Udit Mahajan, <i>Senior UX Designer</i><br/>
          Anna Nikaki, <i>Tech Intern</i><br/>
          Noelle Palumbo, <i>Film Producer</i><br/>
          Vanessa Patchett, <i>Film Director</i><br/>
          Sydney Rhodes, <i>Coordination Manager</i><br/>
          Hailianne Schawo, <i>Junior Visual Designer</i><br/>
          Valeriya Till, <i>Senior Front End Web Developer</i><br/>
          Luobin Wang, <i>Senior Creative Technologist</i><br/>
          Richard Weir, <i>Head of Production</i><br/>
          {/* Catherine Yuan<br/> */}
          <br/>
          <b>Hirani</b><br/>
          Jayson Klein: Commercial sUAS Pilot (Pilot In Command)<br/>
          Frank DeChirico:  Surveyor, Commercial sUAS Pilot (Co-Pilot)<br/>
          Lucas Smith: Assistant Project Manager, Visual Observer<br/>
          <br/>
          <b>Everwood Court Productions</b><br/>
          Justin Stanley: Cinematographer <br/>
          Lauren Gagliardi Stanley: Producer<br/>
          Nicholas Gagliardi: Assistant Camera<br/>
          <br/>
          <b>Iconem</b><br/>
          Paul Aubineau: Photogrammetry Expert<br/>
          Galdric Robert: Head of Production<br/>
          Ines Belchi: Development Officer<br/>
          <br/>
          <b>Special Thanks</b><br/>
          Father Alexander Karloutsos<br/>
          Mark Arey<br/>
          Andrew Veniopoulos<br/>
          Friends of Saint Nicholas<br/>
          Nicole Katchis<br/>
          <br/>
          <b>The Port Authority of New York and New Jersey</b><br/>
          Steven Plate<br/>
          Michael Donovan<br/>
          Alan Reiss<br/>
          Michael Mahesh<br/>
          </Text>
        </TextWrapper>
      </Wrapper>
    </>
  );
};

About.defaultProps = {};
About.propTypes = {};

export default About;
