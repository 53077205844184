/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import styled, { keyframes } from "styled-components/macro";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Helmet } from "react-helmet";
import store from "../../store";
import hintShapeSvg from "../../assets/icons/hint-shape.svg";
import { ReactComponent as DownarrowIcon } from "../../assets/icons/48-icon-down.svg";
import Caption from "./Caption";
import naveData from "../../assets/data/nave_audio_scroll.json";
import naveHotspotData from "../../assets/data/nave_hotspots.json";
import { useNavigate } from "react-router-dom";
import MapButton from "../../components/MapButton";
import ButtonListView from "../../components/ButtonListView";
import { ReactComponent as LeftArrow } from "../../assets/icons/32-icon-leftArrow-v2.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/32-icon-button-list-v2.svg";
import LastEndTourOverlay from "../../components/LastEndTourOverlay";
import LoadingOverlay from "../../components/LoadingOverlay";
import LanguageSelector from "../../components/LanguageSelector";
import { useTheme } from "styled-components";

const MULT = 30;

const Container = styled(motion.div)`
  height: auto;
  width: 100%;
  position: relative;
`;

const BackButtonWrapper = styled(motion.div)`
  position: fixed;
  left: 0px;
  top: ${56 / 16}rem;
  z-index: 1000;

  @media (min-width: 1024px) {
    top: ${88 / 16}rem;
  }
`;
const BottomLeftButtonWrapper = styled(motion.div)`
  position: fixed;
  left: ${14 / 16}rem;
  bottom: ${15 / 16}rem;
  z-index: 1000;
`;

const BottomRightButtonWrapper = styled(motion.div)`
  position: fixed;
  right: ${13 / 16}rem;
  bottom: ${15 / 16}rem;
  z-index: 1000;

  ${(props) =>
    props.openedHotspots && `right: calc(${13 / 16}rem + ${470 / 16}rem);`}
`;

const Overlay = styled(motion.div)`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height + 80}px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 20;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 5;
  }
  @media (min-width: 1024px) {
    height: ${(props) => props.height}px;
  }
`;

const OverlayTitle = styled.h1`
  position: relative;
  font-family: "Noto Serif", serif;
  color: ${(props) => props.theme.textLightDefault};
  font-size: 40px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0.5em;
  z-index: 6;
  @media (min-width: 1024px) {
    font-size:${60/16}rem;
    line-height: ${75/16}rem;
    top:-10%;
  }
`;

const OverlayTagline = styled.h2`
  position: relative;
  font-family: "Sintony", sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  color: ${(props) => props.theme.textLightDefault};
  margin: 0;
  z-index: 6;
  @media (max-width: 800px) {
    font-size:${20 / 16}rem;
    line-height: ${28 / 16}rem};
    top: 2%;
  }
`;

const DownArrowWrapper = styled(motion.div)`
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: -60px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  z-index: 201;
  & svg {
    width: 32px;
    height: 32px;
  }
  @media (min-width: 1024px) {
    bottom: -40px;
    padding: 0.5em 2.5em;
    & svg {
      width: 48px;
      height: 48px;
    }
  }
`;

const Label = styled(motion.div)`
  margin-bottom: 20px;
  color: ${(props) => props.theme.primaryDark};
`;

const Hint = styled.div`
  width: 95px;
  height: ${28 / 16}rem;
  position: absolute;
  top: ${(props) => (props.top ? "2.5rem" : "unset")};
  right: ${(props) => (props.left ? "unset" : "1rem")};
  left: ${(props) => (props.left ? "1rem" : "unset")};
  bottom: ${(props) => (props.bottom ? "8.5rem" : "unset")};
  z-index: 60;
  white-space: nowrap;
  @media (min-width: 1024px) {
    top: ${(props) => (props.top ? "10%" : "unset")};
    bottom: ${(props) => (props.bottom ? "10%" : "unset")};
    right: 2em;

    @media (min-height: 680px) {
      top: ${(props) => (props.top ? "8%" : "unset")};
      bottom: ${(props) => (props.bottom ? "8%" : "unset")};
    }

    @media (min-height: 900px) {
      top: ${(props) => (props.top ? "6%" : "unset")};
      bottom: ${(props) => (props.bottom ? "6%" : "unset")};
    }
  }
  @media (min-width: 2500px) {
    top: ${(props) => (props.top ? "4.25%" : "unset")};
    bottom: ${(props) => (props.bottom ? "4.25%" : "unset")};
    right: 2em;
  }
  @media (min-width: 2500px) {
    top: ${(props) => (props.top ? "2.25%" : "unset")};
    bottom: ${(props) => (props.bottom ? "2.25%" : "unset")};
    right: 2em;
  }
  &:before {
    width: 95px;
    content: "";
    position: absolute;
    height: ${28 / 16}rem;
    background-image: url(${hintShapeSvg});
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(
      ${(props) => (props.left ? "-1" : "1")},
      ${(props) => (props.bottom ? "-1" : "1")}
    );
    z-index: 1;
  }
  &:after {
    width: 95px;
    content: "${(props) => props.text}";
    position: absolute;
    top: ${(props) => (props.top ? "5px" : 0)};
    left: 0;
    height: ${23 / 16}rem;
    color: #fff;
    font-family: "Sintony", sans-serif;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    line-height: ${23 / 16}rem;
    z-index: 5;
  }
`;

const Runway = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.frameCount * MULT}px;
  background-size: auto 100vh;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  z-index: 14;
  & .scroll-canvas {
    z-index: 10;
    display: ${(props) => (props.loadedImages ? "block" : "none")};
    & .scroll-canvas-canvas {
      height: ${(props) => props.height}px;
      width: 100vw;
      object-fit: cover !important;
      z-index: 10;
      transition: opacity 500ms ease-in-out;
    }
  }
  @media (min-width: 1130px) {
    background-size: 100%;
    background-position: 0% 0%;
  }
`;

const Canvas = styled.canvas`
  top: 0;
  left: 0;
  height: ${(props) => props.height}px;
  width: 100vw;
  object-fit: cover !important;
  z-index: 10;
  transition: opacity 500ms ease-in-out;
`;

const Scroller = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: ${(props) => props.height}px;
  background: rgba(129, 105, 32, 0.8);
  transition: opacity 500ms ease-in-out;
  overflow: hidden;
  z-index: 19;
`;

const ScrollerBar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 100vh;
  background: ${(props) => props.theme.bgLight};
  transition: transform 0.5s linear;
`;

const spin = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg); }
  to { transform: translate(-50%, -50%) rotate(360deg); }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${120 / 16}rem;
    height: ${120 / 16}rem;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border-top: 2px solid #a78724;
    border-right: 2px solid #a78724;
    border-left: 2px solid #a78724;
    animation: ${spin} 500ms infinite ease;
  }
`;

const LandscapeDetector = styled.div`
  @media screen and (max-width: 1024px) and (min-aspect-ratio: 13/9) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: ${(props) => props.theme.bgDark};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 1009;
    &:after {
      content: "Please turn your device to view the website.";
      width: 80%;
      font-family: "Sintony", sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      margin: 0;
      color: ${(props) => props.theme.primaryDark};
      @media (min-width: 1024px) {
        font-family: "Noto Serif", serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: #000;
      }
    }
  }
`;

const Nave = () => {
  const isMobile = window.matchMedia("(pointer:coarse)").matches;
  const [state, setState] = useState(store.initialState);
  const [scrollTop, setScrollTop] = useState(0);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView({});
  const [blurRunway, setBlurRunway] = useState(false);
  const [showArrow, setShowArrow] = useState(true);
  const runway = useRef(null);
  const canvas = useRef(null);
  const sub = useRef(null);
  const cframe = useRef(0);
  const images = useRef([]);
  const id = useRef("nave");
  const theme = useTheme();
  const title = useRef("III. The Nave");
  const tagline = useRef("Heaven and Earth");
  const frameCount = useRef(1802);
  const videoDuration = useRef(120);
  const firstImage = useRef("/tour/nave-hirez/frame-0001.jpg");
  const [isEndOfTour, setIsEndOfTour] = useState(false);
  const [hideUI, setHideUI] = useState(false);
  const percentScrolled = useRef(0);
  const captionsData = useRef(
    naveData[state.language.id] ? naveData[state.language.id] : naveData["en"]
  );
  const hotspotData = useRef(naveHotspotData);
  const navigate = useNavigate();
  const scrollTimeout = useRef(null);

  useMemo(() => {
    store.currentTour(id.current);
    const loadedImages = sessionStorage.getItem("nave");
    if (loadedImages === "true") {
      setLoaded(true);
    } else {
      setTimeout(() => {
        setLoaded(true);
        sessionStorage.setItem("nave", true);
      }, 8000);
    }
    console.log("session ", loadedImages);
  }, []);

  useEffect(() => {
    const totalDuration = 8000; // 8 seconds in milliseconds
    const intervalTime = 100; // 100ms

    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) => {
        const newPercentage =
          prevPercentage + (intervalTime / totalDuration) * 100;
        return newPercentage > 100 ? 100 : newPercentage;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, []);

  const drawImageScaled = (img, cvs, ctx) => {
    var hRatio = cvs.width / img.width;
    var vRatio = cvs.height / img.height;
    var ratio = Math.max(hRatio, vRatio);
    var centerShift_x = (cvs.width - img.width * ratio) / 2;
    var centerShift_y = (cvs.height - img.height * ratio) / 2;
    ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      centerShift_x,
      centerShift_y,
      img.width * ratio,
      img.height * ratio
    );
  };

  const handleListViewClicked = () => {
    state.openedHotspots
      ? store.setOpenHotspots(false)
      : store.setOpenHotspots(true);
    store.setMenuOpen(false);
  };

  useEffect(() => {
    store.currentTour(id.current);
  }, []);

  /** setting screenHeight on window resize */
  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
    store.loadedImages(false);
    const cvs = document.querySelector(`#${id.current}-canvas`);
    const ctx = cvs.getContext("2d");

    canvas.current.width = window.innerWidth;
    canvas.current.height = screenHeight;

    const currentFrame = (index) =>
      `/tour/${id.current}-hirez/frame-${index
        .toString()
        .padStart(4, "0")}.jpg`;

    images.current = [];

    const anim = {
      frame: 1,
    };
    console.log("state show tour ", state.showTour);

    for (let i = 0; i <= frameCount.current; i++) {
      const img = new Image();

      img.src = currentFrame(i);
      images.current.push(img);
    }

    gsap.to(anim, {
      frame: frameCount.current,
      ease: "none",
      snap: "frame",
      scrollTrigger: {
        id: `${id.current}-trigger`,
        trigger: runway.current,
        start: "top top",
        pin: false,
        scrub: true,
        invalidateOnRefresh: false,
        onToggle: (self) => {
          setScreenHeight(window.innerHeight);
          canvas.current.height = window.innerHeight;
          store.showTour(self.isActive);
        },
        onUpdate: (self) => {
          if (anim.frame > 0) {
            ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
            drawImageScaled(images.current[anim.frame], cvs, ctx);
          }

          /** set correct screen height if not set */
          if (screenHeight !== window.innerHeight)
            setScreenHeight(window.innerHeight);

          /** render the scrollbar progress and set percent for captions */
          setScrollTop(self.progress * self.end);
          percentScrolled.current = self.progress * 100;
          cframe.current = anim.frame;

          const endOfTourPercentage = 97; //TODO: this needs to be tested on multiple devices. Also, check if the timing of the card and the camera focus are okay for this due to the early termation
          percentScrolled.current >= endOfTourPercentage
            ? setIsEndOfTour(true)
            : setIsEndOfTour(false);

          /** toggle UI on scroll down / up */
          if  (self.direction > 0) {
            // scrolling down
            if ((self.progress * 100) > 0.9) {
              setHideUI(true);
            }
          } else {
            // scrolling up
            setHideUI(false);
            if ((self.progress * 100) <= 0.9) {
              console.log('whats here?');
            }
          }

          document
            .getElementById(`${id.current}-tour`)
            .querySelectorAll("video")
            .forEach((video) => {
              if (!video.paused) {
                video.pause();
              }
            });

          setShowArrow(false);
          clearTimeout(scrollTimeout.current);
          scrollTimeout.current = setTimeout(() => {
            setShowArrow(true);
            setHideUI(false);
          }, 5000);
        },
      },
    });

    return () => {
      // clean up ScrollTrigger instance on title element
      const runwayScrollTrigger = ScrollTrigger.getById(
        `${id.current}-trigger`
      );
      if (runwayScrollTrigger) {
        runwayScrollTrigger.kill();
      }
    };
  }, [state.currentTour]);

  useEffect(() => {
    if (state.scrollToMap && state.currentTour === id.current) {
      setBlurRunway(true);

      console.log(`#${state.scrollToMap.tour}-tour`);

      gsap.to(window, {
        duration: state.scrollToMap.from ? 0 : 1,
        scrollTo: `#${state.scrollToMap.tour}-tour`,
        onComplete: () => {
          const cvs = document.querySelector(
            `#${state.scrollToMap.tour}-canvas`
          );
          const ctx = cvs.getContext("2d");
          setBlurRunway(false);

          try {
            const img = new Image();
            img.src = `/tour/${state.scrollToMap.tour}-hirez/frame-0001.jpg`;

            ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
            drawImageScaled(img, cvs, ctx);
          } catch (err) {
            console.log("scroll to tour section error ", err);
          }

          store.hotspotOpen(true);
          store.scrollToMap(null);
        },
      });
    }
  }, [state.scrollToMap]);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(
    () =>
      loaded && percentScrolled.current > 0.5
        ? store.setHeaderColorAll(theme.navWhite)
        : store.setHeaderColorAll(theme.navGold),
    [percentScrolled.current]
  );

  const calcOffset = (time) => {
    const percentOffset = (time * 100) / videoDuration.current;
    return percentOffset;
  };

  useEffect(() => {
    console.log("UPDATING LANG ", state.language.id);
    captionsData.current = naveData[state.language.id];
  }, [state.language.id]);

  return (
    <>
      <Helmet>
        <title>St. Nicholas Tour - The Nave</title>
        <meta
          name="description"
          content="The Nave is where Orthodox Christians worship in light and color. Learn about Christ, theology, gospel, patrons, The Virgin Mary, and events of September 11."
        />
        <meta name="keywords" content="Icon, Christ, Saint, September 11" />
      </Helmet>
      {!loaded && (
        <LoadingOverlay
          height={window.innerHeight}
          tagline="III. The Nave"
          subtagline="Heaven and Earth"
          percentLoaded={loadingPercentage.toFixed(0)}
        />
      )}
      {isEndOfTour && <LastEndTourOverlay />}
      <Container
        id={`${id.current}-tour`}
        ref={ref}
        key={id.current}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 3 }}
      >
        {isMobile ? <LandscapeDetector /> : null}
        <AnimatePresence>
          {blurRunway && (
            <LoadingOverlay
              key="runway-blurrer"
              height={screenHeight}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Loader />
            </LoadingOverlay>
          )}
        </AnimatePresence>
        {state.headerVisible && (
          <>

            <AnimatePresence>
              {!hideUI && (
                <BackButtonWrapper
                  animate={{ opacity: 1, transform: 'translateX(0%)' }}
                  initial={{ opacity: 0, transform: 'translateX(-100%)' }}
                  exit={{ opacity: 0, transform: 'translateX(-100%)'  }}
                >
                  <MapButton
                    label="Tour Stops"
                    action={() => navigate("/tour")}
                    type="back"
                    icon={<LeftArrow />}
                    addClass="icon-first"
                  />
                </BackButtonWrapper>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {!hideUI && (
                <BottomLeftButtonWrapper
                  animate={{ opacity: 1, bottom: '15px' }}
                  initial={{ opacity: 0, bottom: '-60px' }}
                  exit={{ opacity: 0, bottom: '-60px'  }}
                >
                  <LanguageSelector />
                </BottomLeftButtonWrapper>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {!hideUI && (
                <BottomRightButtonWrapper
                  openedHotspots={state.openedHotspots}
                  animate={{ opacity: 1, transform: 'translateY(0%)' }}
                  initial={{ opacity: 0, transform: 'translateY(100%)' }}
                  exit={{ opacity: 0, transform: 'translateY(100%)'  }}  
                >
                  <ButtonListView
                    label="List View"
                    action={handleListViewClicked}
                    type="light"
                    icon={<ListIcon />}
                    addClass="icon-first"
                  />
                </BottomRightButtonWrapper>
              )}
            </AnimatePresence>
          </>
        )}
        <Overlay
          height={screenHeight}
          firstImage={firstImage.current}
          animate={{ opacity: 1 - scrollTop * 0.005 }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundImage: `url(${
              cframe.current > 0
                ? images.current[cframe.current].src
                : firstImage.current
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <OverlayTitle>{title.current}</OverlayTitle>
          <OverlayTagline>{tagline.current}</OverlayTagline>

          {id.current === "exterior" && (
            <Hint top={true} text="Audio Controls" />
          )}
          {id.current === "exterior" && (
            <Hint bottom={true} text="Tour Controls" />
          )}
        </Overlay>

        <DownArrowWrapper
          animate={{ opacity: showArrow ? 1 : 0}}
        >
            <motion.div
              animate={{
                transform: [
                  "translateY(0px)",
                  "translateY(-15px)",
                  "translateY(0px)",
                ],
              }}
              transition={{ repeat: Infinity, duration: 1.5 }}
            >
              <DownarrowIcon />
            </motion.div>
            <Label
              className="button2"
              animate={{ opacity: 1 - scrollTop * 0.005 }}>Scroll to Enter</Label>
          </DownArrowWrapper>

        <Scroller
          style={{
            position:
              state.showTour && state.currentTour === id.current
                ? "fixed"
                : "absolute",
          }}
          height={screenHeight}
        >
          <ScrollerBar
            frameCount={frameCount.current}
            style={{
              transform: `translateY(-${100 - percentScrolled.current}%)`,
            }}
          />
        </Scroller>
        <Runway
          id={id.current}
          ref={runway}
          frameCount={frameCount.current}
          height={screenHeight}
          loadedImages={state.loadedImages}
        >
          <Canvas
            ref={canvas}
            id={`${id.current}-canvas`}
            height={screenHeight}
            atTop={state.showTour && state.currentTour === id.current}
            style={{
              position: "sticky",
              backgroundImage: `url(${
                cframe.current > 0
                  ? images.current[cframe.current].src
                  : firstImage.current
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Runway>
        {captionsData.current.map((caption, key) => (
          <Caption
            key={key}
            index={key}
            tour={id.current}
            text={caption.text}
            audioUrl={caption.url}
            offset={calcOffset(caption.start)}
            captionData={caption}
            hotspotData={hotspotData.current}
          />
        ))}
      </Container>
    </>
  );
};

export default Nave;
