import * as React from "react";
import { useState, useRef, useLayoutEffect, useEffect } from "react";
import store from "../store";
import { useTheme } from "styled-components";

const SoundIcon = ({ width = 32, height = 32, action, muted }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isWhiteIcon, setIsWhiteIcon] = useState(false);
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);
  const theme = useTheme();
  const colors = {
    white: theme.navWhite,
    normal: theme.navGold,
    gray: "#f7f7f7",
  };

  const handleOnClick = () => {
    action();
  };

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(() => {
    setIsWhiteIcon(state.headerThemeColor === theme.navWhite);
  }, [state.headerThemeColor]);

  return (
    <button
      style={{ border: 'none' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleOnClick()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
      >
        <g id="icon_group" data-name="Group 2232" transform="translate(-45 -273)">
          <rect
            id="Rectangle_2307"
            className="background"
            data-name="Rectangle 2307"
            width={32}
            height={32}
            rx={16}
            transform="translate(45 273)"
            fill={isHovered && !isWhiteIcon ? colors.gray : "transparent"}
          />
          <g
            id="Group_2183"
            data-name="Group 2183"
            transform="translate(45.314 272.922)"
          >
            {!muted ? (
              <path
                id="FontAwsome_volume-up_"
                className="sound_waves"
                data-name="FontAwsome (volume-up)"
                d="M12.509,16.1a.659.659,0,0,0-.723,1.1,6.364,6.364,0,0,1,0,10.654.659.659,0,1,0,.723,1.1,7.682,7.682,0,0,0,0-12.858Zm.862,6.429a5.029,5.029,0,0,0-2.336-4.256.651.651,0,0,0-.9.2.661.661,0,0,0,.2.909,3.723,3.723,0,0,1,0,6.288.661.661,0,0,0-.2.909.652.652,0,0,0,.9.2A5.029,5.029,0,0,0,13.371,22.533ZM9.509,20.439a.654.654,0,1,0-.631,1.146,1.074,1.074,0,0,1,0,1.9.654.654,0,1,0,.631,1.146,2.381,2.381,0,0,0,0-4.188Z"
                transform="translate(9.34 -6.632)"
                fill={isWhiteIcon ? colors.white : colors.normal}
                stroke={isWhiteIcon ? colors.white : colors.normal}
                strokeWidth={0.3}
              />
            ) : (
              <>
                <line
                  id="Line_332"
                  className="sound-off-line"
                  data-name="Line 332"
                  x2={8}
                  transform="translate(19.227 18.906) rotate(-45)"
                  fill="none"
                  stroke={isWhiteIcon ? colors.white : colors.normal}
                  strokeLinecap="round"
                  strokeWidth={2}
                />
                <line
                  id="Line_333"
                  className="sound-off-line"
                  data-name="Line 333"
                  x2={8}
                  transform="translate(19.227 13.249) rotate(45)"
                  fill="none"
                  stroke={isWhiteIcon ? colors.white : colors.normal}
                  strokeLinecap="round"
                  strokeWidth={2}
                />{" "}
              </>
            )}
            <g
              id="Group_1838"
              data-name="Group 1838"
              transform="translate(6.046 8.887)"
            >
              <path
                id="FontAwsome_volume-mute_"
                data-name="FontAwsome (volume-mute)"
                d="M7.509,63h0A1.63,1.63,0,0,1,9.14,64.63V75.759a1.63,1.63,0,0,1-1.631,1.626,1.607,1.607,0,0,1-1.143-.477l-2.7-2.7H.625A1.627,1.627,0,0,1-1,72.58v-4.77A1.627,1.627,0,0,1,.625,66.184H3.662l2.7-2.7A1.607,1.607,0,0,1,7.509,63ZM7.48,75.675V64.714L4.35,67.844H.66v4.7H4.35Z"
                transform="translate(1 -63.004)"
                fill={isWhiteIcon ? colors.white : colors.normal}
              />
            </g>
          </g>
        </g>
      </svg>
    </button>
  );
};
export default SoundIcon;
