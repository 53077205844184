import { useState, useLayoutEffect, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { motion } from 'framer-motion';
import { enablePageScroll } from 'scroll-lock';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import store from '../store';

import { ReactComponent as HeadphonesIcon } from '../assets/icons/icon-headphones.svg';

const PanelBackgroundTop = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  background: ${props => props.theme.secondaryLight};
  z-index: 1;
`;

const PanelBackgroundLoading = styled(PanelBackgroundTop)`
  background: ${props => props.theme.bgDark};
  z-index: 10;
`;

const HeadphonesBigText = styled.h1`
  font-family: 'Noto Serif', serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  color: ${props => props.theme.primary4};
  z-index: 11;
  margin-top: 2em;
  margin-left: auto; margin-right: auto;
  @media (min-width: 1024px) {
    font-size: 45px;
    line-height: 55px;
    margin-top: 2.5em;
    width: 750px;
  }
  @media (min-width: 1920px) {
    font-size: 60px;
    line-height: 75px;
    width: 896px;
  }
`;

const HeadphonesText = styled.h2`
  font-family: 'Sintony', sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  margin-top: 1em;
  color: ${props => props.theme.primary4};
  font-weight: bold;
  margin-left: ${55 / 16}em;
  margin-right: ${55 / 16}em;
  z-index: 11;
  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
    margin-top: 2em;
    width: 100%;
  }
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const pulseButton = keyframes`
  0%   {
      transform: translate(-50%, -50%) scale(1) ; 
      opacity: 0;
  }
  50%   {
      opacity: 0.3;
  }
  100% {
      transform: translate(-50%, -50%) scale(1.4);
      opacity: 0;
  }
`;

const HeadphonesIconContainer = styled.div`
  width: ${56 / 16}em;
  height: ${56 / 16}em;
  border-radius: 100%;
  border: 1px solid ${props => props.theme.primary4};
  padding: 1em;
  position: relative;
  z-index: 11;
  & svg {
    width: ${24 / 16}em;
    height: ${24 / 16}em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: ${56 / 16}em;
    height: ${56 / 16}em;
    opacity: 0.4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${props => props.theme.primary4};
    border-radius: 100%;
    animation: ${pulseButton} 1000ms linear 0s infinite;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: ${66 / 16}em;
    height: ${66 / 16}em;
    opacity: 0.2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${props => props.theme.primary4};
    border-radius: 100%;
    animation: ${pulseButton} 1000ms linear 900ms infinite;
  }
`;

const StyledMotionContainer = styled(motion(Container))`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  min-height: 100vh;
  z-index: 9999;
  &.headphone-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: fixed;
    max-width: 100%;
  }
`;

const StyledStack = styled(Stack)`
  &.loading {
    widht: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;


const HeadphoneOverlay = ({
  spinner,
  setSpinner,
  loading,
  setLoading,
}) => {
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(() => {
    let spinnerTimeout = null;

    spinnerTimeout = setTimeout(() => {
      sessionStorage.setItem('headphones', true);
      setSpinner(false);
      onEnterExperience();
    }, 3000);

    return () => {
      clearTimeout(spinnerTimeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    console.log(loading)
  }, [loading]);

  const onEnterExperience = () => {
    state.audioTrack.id = 'global-audio-tour';
    state.audioTrack.src = '/tour/exterior-audios/TheBuilding-scroll1.mp3';
    state.audioTrack.load();
    state.audioTrack.muted = true;
    store.mutedBg(true);
    setLoading(false);
    enablePageScroll();
  };

  return (
    <>
      <StyledMotionContainer
      key="headphone-container"
      className="headphone-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      >
      <StyledStack
        gap={3}
        key="loader"
        className="loading"
      >
        <HeadphonesIconContainer>
          <HeadphonesIcon/>
        </HeadphonesIconContainer>
        <HeadphonesBigText>The experience is better with sound</HeadphonesBigText>
        <HeadphonesText>Please use headphones if you<br />are at the&nbsp;Shrine.</HeadphonesText>
        {spinner
          && (
            <Spinner
              animation="border"
              style={{
                borderWidth: '0.15em',
                borderColor: '#816920',
                borderRightColor: 'transparent',
                position: 'absolute',
                bottom: `${115 / 16}em`,
                zIndex: 11,
              }}
            />
          )}
        <PanelBackgroundLoading />
      </StyledStack>
      </StyledMotionContainer>
    </>
  );
};

HeadphoneOverlay.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  spinner: PropTypes.bool,
  setSpinner: PropTypes.func,
  setMuted: PropTypes.func,
};
HeadphoneOverlay.defaultProps = {
  loading: true,
  setLoading: null,
  spinner: true,
  setSpinner: null,
  setMuted: null,
};

export default HeadphoneOverlay;