import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
//import Button from "./Button";
import { ReactComponent as ArrowAroundIcon } from "../assets/icons/32-icon-around.svg";
import { ReactComponent as DonateIcon } from "../assets/icons/32-icon-donate.svg";
import EndScreenButton from "./EndScreenButton";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 41, 57, 0.8);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  width: 80%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  color: #FFFEFA;
  margin-bottom: 2rem;
  font-family: "Noto Serif", serif;
  line-height: ${28 / 16}rem;
  font-size: ${24 / 16}rem;

  @media (min-width: 1024px) {
    font-size: ${40 / 16}rem;
    line-height: ${60 / 16}rem;
    color: #FFFEFA;
  }
`;

const Text = styled.p`
  color: white;
  width: 80%;
  text-align: center;
  font-family: "Sintony", sans-serif;
  font-size: ${14 / 16}rem;
  line-height: ${28 / 16}rem;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    font-size: ${24 / 16}rem;
    line-height: ${36 / 16}rem;
    width: 35%;
  }
`;

const ThankYouText = styled.p`
  color: white;
  font-family: "Sintony", serif;
  font-size: ${18 / 16}rem;
  line-height: ${28 / 16}rem;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: ${28 / 16}rem;
    line-height: ${40 / 16}rem;
    margin-bottom: 2rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Button = styled.button`
  font-family: "Sintony", sans-serif;
  z-index: 99;

  @media (min-width: 1024px) {
    width: ${240/16}rem;
    height: ${48/16}rem;
    font-size: ${18/16}rem;
  }

  & .dark{
    color: #FFFEFA;
    border: 1px solid #816920;
    background-color: #81691F;
  }
`;

const LastEndTourOverlay = () => {
  const navigate = useNavigate();
  return (
    <Overlay>
      <TextWrapper>
        <Title>You've now reached the end of your tour.</Title>
        <Text>
          Whether you're coming to remember the lives lost on September 11 or
          are just taking a restful pause in your busy day, our doors will
          always be open.
        </Text>
        <ThankYouText>
          Thank you for your Visit. <br/>
          We appreciate your support.
        </ThankYouText>
        <ButtonWrapper>
          <EndScreenButton
            label="Explore Icons"
            action={() => navigate("/explore-icons")}
            type="dark"
            addClass='right-margin'
            icon={<ArrowAroundIcon/>}
            />
          <EndScreenButton
            label="Make a Donation"
            isLink={true}
            link="https://www.shelbygiving.com/App/Form/d593cfa1-ca11-4c28-8c17-bf9ed0039e1d"
            type="light"
            add-class='left-margin'
            icon={<DonateIcon/>}
          />
        </ButtonWrapper>
      </TextWrapper>
    </Overlay>
  );
};

export default LastEndTourOverlay;
