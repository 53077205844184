import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import store from "../store";
import selectIcon from "../assets/icons/24-icon-arrow-down.svg";

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${props => props.theme.bgLightDefault};
  box-shadow: none;
  border: none;
  outline: none;
  width: 9.25rem;
  border-radius: 50px;
  height: 40px;
`;

const ArrowIconButton = styled.button`
  box-shadow: none;
  border: none;
  outline: none;
  img {
    width: 24px;
    height: 24px;
    opacity: ${props => props.dimmed ? 0.25 : 1};
    transform: ${props => props.flip ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;

const LevelText = styled.span`
  //margin: 0 10px;
  color: ${props => props.theme.textLightDefault};
  font-weight: bold;
  font-family: 'Sintony';
`;

const LevelSelectingButton = ({ changeLevel, controlsEnabled }) => {
  const [currentLevel, setCurrentLevel] = useState(1);
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);
  const LAST_LEVEL = 4;

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(() => {
    changeLevel(currentLevel - 1);
  }, [currentLevel]);

  useEffect(() => {
    console.log("CONTROLS ENABLED IN LEVEL CHANGE ", controlsEnabled, state.disableControls);
  }, [controlsEnabled]);

  const handleUpClick = () => {
    if (currentLevel < LAST_LEVEL) {
      setCurrentLevel(prevLevel => prevLevel + 1);
    }
  };

  const handleDownClick = () => {
    if (currentLevel > 1) {
      setCurrentLevel(prevLevel => prevLevel - 1);
    }
  };

  return (
    <ButtonContainer>
      <ArrowIconButton
        onClick={handleDownClick}
        dimmed={currentLevel === 1 || !controlsEnabled}
        disabled={currentLevel === 1 || !controlsEnabled}
        flip
      >
        <img 
          alt="down arrow"
          src={selectIcon}
        />
      </ArrowIconButton>
      <LevelText>Level {currentLevel}</LevelText>
      <ArrowIconButton
        onClick={handleUpClick}
        dimmed={currentLevel === LAST_LEVEL || !controlsEnabled}
        disabled={currentLevel === LAST_LEVEL || !controlsEnabled}
      >
        <img
          alt="arrow up" 
          src={selectIcon}
          />
      </ArrowIconButton>
    </ButtonContainer>
  );
};

export default LevelSelectingButton;
