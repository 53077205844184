import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Button from './Button';
import { ReactComponent as LinkedInIcon } from '../assets/icons/icon-linkedin.svg';
import { ReactComponent as TwitterIcon } from '../assets/icons/icon-twitter.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/icon-facebook.svg';

const Background = styled(motion.div)`
  position: fixed;
  top: ${props => props.atEnd ? '0' : '-0.5em'};
  bottom: 0;
  left: ${props => props.atEnd ? '0' : '-1em'};
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: ${props => props.theme.secondary1};
  opacity: 0.8;
  &.outside-button {
    display: none;
  }
  @media (min-width: 1024px) { 
    top: ${props => props.atEnd ? '0' : '-1em'};
    left: ${props => props.atEnd ? '0' : '-2.5em'};
  }
`;

const SocialsWrapper = styled(motion.div)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: ${props => props.atEnd ? 'flex-end' : 'center'};
  position: ${props => props.atEnd ? 'absolute' : 'fixed'};
  top: ${props => props.atEnd ? 'unset' : '48px'};
  right: 0;
  z-index: 98;
  &.outside-button {
    flex-flow: row nowrap;
    top: unset;
    bottom: 19px;
    right: 38px;
    margin-bottom: 0 !important;
  }
  @media (min-width: 1024px) {
    top: ${props => props.atEnd ? 'unset' : '80px'};
  }
`;

const SocialDropdown = ({ opened, isWhite, setCloseSocials, atEnd, addClass }) => {
  return(
    <>
      <SocialsWrapper
        key="socials-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, type: 'tween', ease: 'easeInOut' }}
        atEnd={atEnd}
        className={addClass}
      >
        <Button
          icon={<LinkedInIcon />}
          type="tertiary"
          addClass={ isWhite ? "white-outline" : ""}
          addStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 0 }}
          isLink={true}
          link="https://www.linkedin.com/shareArticle?mini=true&url=https%3A//stnicholaswtc.org/&title=St%20Nicholas%20Tour&summary=&source="
          action={setCloseSocials}
        />
        <Button
          icon={<TwitterIcon />}
          type="tertiary"
          addClass={ isWhite ? "white-outline" : ""}
          addStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 0 }}
          isLink={true}
          link="https://twitter.com/intent/tweet?text=https%3A//stnicholaswtc.org/"
          action={setCloseSocials}
        />
        <Button
          icon={<FacebookIcon />}
          type="tertiary"
          addClass={ isWhite ? "white-outline" : ""}
          addStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 0 }}
          isLink={true}
          link="https://www.facebook.com/sharer/sharer.php?u=https%3A//stnicholaswtc.org/"
          action={setCloseSocials}
        ß/>
      </SocialsWrapper>
      <Background
        onClick={setCloseSocials}
        key="socials-background"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.8 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, type: 'tween', ease: 'easeInOut' }}
        atEnd={atEnd}
        className={addClass}
      />
    </>
  );
};

SocialDropdown.propTypes = {
  opened: PropTypes.bool,
  isWhite: PropTypes.bool,
  setCloseSocials: PropTypes.func,
  atEnd: PropTypes.bool,
  addClass: PropTypes.string,
};

SocialDropdown.defaultProps = {
  opened: false,
  isWhite: true,
  setCloseSocials: null,
  atEnd: false,
  addClass: "",
};

export default SocialDropdown;
