import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import store from "../store";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Sintony";
  font-size: ${18 / 16}rem;
  font-weight: bold;
  line-height: 0;
  margin: 0;
  padding: 0;
  padding: ${8 / 16}rem 0;
  border-radius: 50px;
  box-shadow: 0 0 ${10 / 16}rem rgba(0, 0, 0, 0.13);
  width: ${220 / 16}rem;
  height: 40px;

  @media (min-width: 1024px) {
    padding: ${8 / 16}rem 0;
    font-size: ${18 / 16}rem;
    width: ${220 / 16}rem;
    justify-content: center;
    font-family: "Sintony";
    font-weight: bold;
    line-height: 0;
  }
  span {
    padding-left: 0;
  }

  color: ${(props) => props.theme.textLightDefault};
  background-color: ${(props) => props.theme.bgLightDefault};
  border: 1px solid ${(props) => props.theme.borderLightDefault};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.bgBackButtonHoverLightDefault};
  }
  &:active {
    cursor: pointer;
    opacity: 73%;
    background-color: ${(props) => props.theme.bgBackButtonHoverLightDefault};
  }

  //Prop specific effects

  // Themes
  &.dark {
    color: ${(props) => props.theme.textDarkDefault};
    background-color: ${(props) => props.theme.bgDarkDefault};
    border: none;
  }
`;

const ButtonContent = styled.span`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  line-height: 0;
  padding: 0;

  //padding: ${9.5 / 16}rem ${21 / 16}rem;
`;

const ButtonEndOfTour = ({
  label,
  icon,
  classState,
  type,
  action,
  addClass,
  addStyle,
  isLink,
  link,
  matchIconFillToText,
  matchIconStrokeToText,
  iconWidth,
  iconHeight,
}) => {
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  return (
    <StyledButton
      onClick={action}
      className={`${type} ${classState} ${
        !label && icon ? "icon-only" : ""
      } ${addClass}`}
      style={addStyle}
      as={isLink ? "a" : "button"}
      href={isLink ? link : ""}
      target={isLink ? "_blank" : ""}
      disabled={state === "inactive"}
      matchIconFillToText={matchIconFillToText}
      matchIconStrokeToText={matchIconStrokeToText}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
      headerColor={state.headerThemeColor}
    >
      <ButtonContent>
        {icon && type === "icon-first" && <> {icon} </>}
        {label && <>{label}</>}
        {icon && type !== "icon-first" && <>{icon}</>}
      </ButtonContent>
    </StyledButton>
  );
};

ButtonEndOfTour.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object,
  state: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.func,
  addClass: PropTypes.string,
  addStyle: PropTypes.object,
  isLink: PropTypes.bool,
  link: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

ButtonEndOfTour.defaultProps = {
  label: null,
  icon: null,
  state: null,
  type: null,
  action: null,
  addClass: null,
  addStyle: null,
  isLink: false,
  link: "",
  fillColor: null,
  strokeColor: null,
};

export default ButtonEndOfTour;
