import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import store from "../store";
import Accordion from "react-bootstrap/Accordion";
import { ReactComponent as CloseIcon } from "../assets/icons/48-icon-close.svg";
import { ReactComponent as BackToTopIcon } from "../assets/icons/24-icon-backToTop.svg";
import Button from "./Button";
import HotspotOverlay from "../containers/Tour/HotspotOverlay";
import exteriorHotspotData from "../assets/data/exterior_hotspots.json";
import narthexHotspotData from "../assets/data/narthex_hotspots.json";
import naveHotspotData from "../assets/data/nave_hotspots.json";
import CloseButton from "../components/CloseButton";

const MenuContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 102;
  @media (min-width: 1024px) {
    width: ${470 / 16}rem;
    left: calc(100vw - ${470 / 16}rem);
  }
`;

const MenuWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100% - 48px);
  overflow-y: auto;
  background: ${(props) => props.theme.bgLight};
  position: relative;
  z-index: 99;
  & svg {
    & a:hover {
      opacity: 0.8;
    }
    & a:active {
      opacity: 0.4;
    }
  }
`;

const CloseContainer = styled.div`
  box-shadow: inset 1px 4px 9px -6px #000;
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  background-color: #BB5921;
  color: #FFF;
  padding: ${14 / 16}rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
  & span {
    display: inline-block;
    margin-right: ${14 / 16}rem
    font-family: 'Sintony', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
  }
  @media (min-width: 1024px) {
    height: 56px;
    & span {
      font-size: 1rem;
    }
  }
`;

const ListTitle = styled.h3`
  margin-top: 3rem;
  width: 100%;
  color: ${(props) => props.theme.textMenu};
  font-size: ${18 / 16}rem;
  font-family: "Noto Serif", serif;
  text-align: center;
  background-color: ${(props) => props.theme.bgDark};
  margin-bottom: 0;
  padding: 1rem;

  @media (min-width: 1024px) {
    font-weight: semiBold;
    font-size: ${22 / 16}rem;
  }
`;

const SectionTitle = styled.h3`
  font-family: "Noto Serif", serif;
  line-height: ${30 / 16}rem;
  font-size: ${22 / 16}rem;
  font-weight: SemiBold;
  text-align: center;
  width: 100%;
  padding: 1rem 0;
  margin: 0;
  margin-bottom: 0;

  @media (min-width: 1024px) {
    font-size: ${32 / 16}rem;
    font-family: "Noto Serif", serif;
    line-height: ${40 / 16}rem;
  }
`;

const SectionList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-top: ${8 / 16}rem;
  margin: 0;
  background: ${(props) => props.theme.bgDark};
`;

const SectionItem = styled.div`
  background: ${(props) => props.theme.bgCaptionCard};
  margin: ${8 / 16}rem ${14 / 16}rem;
  padding: ${19 / 16}rem ${20 / 16}rem;
  min-height: ${128 / 16}rem;
  border-radius: ${8 / 16}rem;
  &.accordion-item {
    border: 0;
  }
`;

const Title = styled.h4`
  font-family: "Noto Serif", serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
  margin-bottom: ${10 / 16}rem;

  @media (min-width: 1024px) {
    font-size: ${22 / 16}rem;
  }
`;


const Description = styled.p`
  width: auto;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  font-family: "Sintony", sans-serif;
  font-size: ${12 / 16}rem;
  font-weight: normal;
  line-height: ${15 / 16}rem;

  @media (min-width: 1024px) {
    font-size: 1rem;
    line-height: ${20 / 16}rem;
  }
`;

const BackToTopButton = styled.button`
  box-shadow: none;
  border: none;
  background-color: ${(props) => props.theme.bgDark};
  width: 100%;
  min-height: ${128 / 16}rem;
  color: ${(props) => props.theme.primaryDark};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  & svg {
    height: auto;
  }
`;


const HotspotList = ({ onCloseList }) => {
  const [state, setState] = useState(store.initialState);
  const scrollingContainerRef = useRef(null);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  useEffect(() => {
    document
      .getElementById(`${state.currentTour}-listsection`)
      .scrollIntoView();
  }, [state.currentTour]);

  const backToTop = () => {
    scrollingContainerRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleCloseButtonPressed = () => {
    onCloseList(false);
    store.setHeaderVisible(true);
  };

  return (
    <>
      <MenuContainer
        key="hotspot-list"
        initial={{
          opacity: 0,
          transform:
            window.innerWidth >= 1024 ? "translateX(100%)" : "translateY(100%)",
        }}
        animate={{
          opacity: 1,
          transform:
            window.innerWidth >= 1024 ? "translateX(0%)" : "translateY(0%)",
        }}
        exit={{
          opacity: 0,
          transform:
            window.innerWidth >= 1024 ? "translateX(100%)" : "translateY(100%)",
        }}
        transition={{ duration: 0.4, type: "tween", ease: "easeInOut" }}
      >
        <CloseContainer onClick={handleCloseButtonPressed}>
          <span>Close List View</span>
          <CloseButton action={handleCloseButtonPressed} />
        </CloseContainer>
        <MenuWrapper ref={scrollingContainerRef}>
          <SectionTitle id="exterior-listsection">I. The Building</SectionTitle>
          <SectionList>
            {exteriorHotspotData.map((hotspot, key) => (
              <SectionItem key={key} eventKey={`${key}`}>
                <Title>{hotspot.title}</Title>
                <Description>{hotspot.subtitle}</Description>
                <HotspotOverlay
                  inline={true}
                  captionInline={false}
                  hotspotData={hotspot}
                  closeList={onCloseList}
                />
              </SectionItem>
            ))}
          </SectionList>

          <SectionTitle id="narthex-listsection">II. The Narthex</SectionTitle>
          <SectionList flush>
            {narthexHotspotData.map((hotspot, key) => (
              <SectionItem key={key} eventKey={`${key}`}>
                <Title>{hotspot.title}</Title>
                <Description>{hotspot.subtitle}</Description>
                <HotspotOverlay
                  inline={true}
                  captionInline={false}
                  hotspotData={hotspot}
                  closeList={onCloseList}
                />
              </SectionItem>
            ))}
          </SectionList>

          <SectionTitle id="nave-listsection">III. The Nave</SectionTitle>
          <SectionList flush>
            {naveHotspotData.map((hotspot, key) => (
              <SectionItem key={key} eventKey={`${key}`}>
                <Title>{hotspot.title}</Title>
                <Description>{hotspot.subtitle}</Description>
                <HotspotOverlay
                  inline={true}
                  captionInline={false}
                  hotspotData={hotspot}
                  closeList={onCloseList}
                />
              </SectionItem>
            ))}
          </SectionList>
          <BackToTopButton onClick={backToTop}>
            <BackToTopIcon /> <span>Back to Top</span>
          </BackToTopButton>
        </MenuWrapper>
      </MenuContainer>
    </>
  );
};

HotspotList.propTypes = {
  onCloseList: PropTypes.func,
};

HotspotList.defaultProps = {
  onCloseList: null,
};

export default HotspotList;
