import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Button from './Button';

const DonationAlertContainer = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;
const DonationAlertWrapper = styled.div`
  width: 316px;
  height: 346px;
  border-radius: 8px;
  background: ${props => props.theme.bgLight};
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 15px;
  padding-top: 37px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  z-index: 102;
  @media (min-width: 1024px) {
    width: 550px;
    height: 362px;
    padding: 50px 40px;
  }
`;
const Message = styled.p`
  font-family: 'Sintony', sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  color: #000;
  margin: 0;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

const ContinueMessage = styled.p`
  font-family: 'Sintony', sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  color: #000;
  margin: 0;
  padding-top: 12px;
  padding-bottom: 26px;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

const Buttons = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  @media (min-width: 1920px) {
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 97;
  background: ${props => props.theme.secondary1};
  opacity: 0.8;
`;

const DonationAlert = ({ setClosed, inline, type, link }) => {
  useEffect(() => {
    console.log(inline, type, link)
  }, []);

  return(
    <DonationAlertContainer
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0  }}
    >
      <DonationAlertWrapper inline={inline}>
        {type === 'donation' && (<Message>You are about to go to a site where you can make a donation to the Saint Nicholas&nbsp;National&nbsp;Shrine.</Message>)}
        {type === 'spotify' && (<Message>You are about to go to Spotify.com, where you can listen to the Saint&nbsp;Nicholas National&nbsp;Shrine&nbsp;playlist.</Message>)}
        {type === 'website' && (<Message>You are about to go to an external&nbsp;website.</Message>)}
        <ContinueMessage>Would you like to continue?</ContinueMessage>
        <Buttons>
          <Button
            type="tertiary"
            label="Yes"
            addStyle={{
              textDecoration: 'none',
              display: 'flex',
              flexFlow: 'row nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 177,
              height: 40,
              boxShadow: 'none'
            }}
            isLink={true}
            link={link ? link : 'https://www.shelbygiving.com/App/Form/d593cfa1-ca11-4c28-8c17-bf9ed0039e1d'}
            action={setClosed}
          />
          <Button
            type="light"
            label="No"
            addStyle={{ fontWeight: 'normal', minWidth: 177, height: 40, boxShadow: 'none' }}
            action={setClosed}
          />
        </Buttons>
      </DonationAlertWrapper>
      <Background onClick={setClosed} />
    </DonationAlertContainer>
  );
};

DonationAlert.propTypes = {
  setClosed: PropTypes.func,
  inline: PropTypes.string,
};


DonationAlert.defaultProps = {
  setClosed: null,
  inline: null,
};

export default DonationAlert;
