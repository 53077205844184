import { useLayoutEffect, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { AnimatePresence, motion } from 'framer-motion';
import store from '../store';
import { useLocation } from 'react-router-dom';
import { ReactComponent as LeftarrowIcon } from '../assets/icons/48-icon-leftArrow.svg';
import { ReactComponent as RightarrowIcon } from '../assets/icons/48-icon-rightArrow.svg';
import { ReactComponent as ReadIcon } from '../assets/icons/48-icon-read.svg';
import Button from './Button';

const FooterContainer = styled(motion.div)`
  position: fixed;
  bottom: 0; left: 0;
  width: ${props => props.loc.includes('/light-a-candle') ? 'auto' : '100%'};
  height: 64px;
  background: ${props => props.theme.bgLight};
  box-shadow: 0px -1px 6px #00000029;
  padding: 1em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 100;
  @media (min-width: 1024px) {
    height: 80px;
    padding: 0.5em 2.5em;
  }
`;

const IconText = styled.div`
  margin: 0 30px;
  min-width: 12rem;
  h4, h5 {
    font-family: 'Noto Serif', serif;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  h5 {
    font-size: 14px;
  }
`;

const Footer = ({ onOpenedHotspots, openedHotspots }) => {
  const [state, setState] = useState(store.initialState);
  const location = useLocation();
  const sub = useRef(null);

  useEffect(() => {
    console.log(location.pathname, location.pathname.includes('/explore-icons'));
  }, [location]);
 
  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();

    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  const onIconLevelUp = () => {
    if (state.iconLevelIdx !== 3) {
      store.iconLevelIdx(state.iconLevelIdx + 1);
    }
  };

  const onIconLevelDown = () => {
    if (state.iconLevelIdx !== 0) {
      store.iconLevelIdx(state.iconLevelIdx - 1);
    }
  };

  const onIconListOpen = () => {
    store.iconListOpen(!state.iconListOpen);
  };

  return(
    <>
    <AnimatePresence>
      {!state.hideIconUI && (
        <FooterContainer
          animate={{ opacity: 1, transform: 'translateY(0%)'  }}
          initial={{ opacity: 0, transform: 'translateY(100%)'  }}
          exit={{ opacity: 0, transform: 'translateY(100%)'  }}
          style={
            (!location.pathname.includes('/explore-icons'))
            ? { background: 'transparent', boxShadow: 'none' }
            : {}
          }
          loc={location.pathname}
        >
          <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}> 
            {(location.pathname.includes('/explore-icons'))
              ? (
                <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    icon={<LeftarrowIcon />}
                    type="tertiary"
                    state={(state.iconLevelIdx <= 0) || state.disableControls ? 'inactive' : ''}
                    action={() => onIconLevelDown()}
                  />
                    <IconText style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center' }}>
                      <h4>{state.iconLevelData.level}</h4>
                      <h5>{state.iconLevelData.title}</h5>
                    </IconText>
                  <Button
                    icon={<RightarrowIcon />}
                    type="tertiary"
                    state={(state.iconLevelIdx >= 3) || state.disableControls ? 'inactive' : ''}
                    action={() => onIconLevelUp()}
                  />
                </div>
              )
              : null
            }
          </div>
          {(location.pathname.includes('/explore-icons')) ? <Button icon={<ReadIcon />} type="tertiary" action={() => onIconListOpen()} /> : null }
        </FooterContainer>
      )}
    </AnimatePresence>
    </>
  );
};

Footer.propTypes = {
  onOpenedHotspots: PropTypes.func,
  openedHotspots: PropTypes.bool,
};
Footer.defaultProps = {
  onOpenedHotspots: null,
  openedHotspots: false,
};

export default Footer;