import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import store from "../store";
import { ReactComponent as PlayIcon } from "../assets/icons/32-icon-play-v2.svg";
import { ReactComponent as PauseIcon } from "../assets/icons/32-icon-pause-v2.svg";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  margin: 0;
  padding: 0;
  border: 0px;
  @media (min-width: 1024px) {
    svg {
      width: 46px;
      height: 46px;
    }

  }
`;

const Button = ({
  label,
  icon,
  classState,
  type,
  action,
  addClass,
  addStyle,
  isLink,
  link,
}) => {
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  return (
    <StyledButton
      onClick={action}
      className={`${type} ${classState} ${
        !label && icon ? "icon-only" : ""
      } ${addClass}`}
      style={addStyle}
      as={isLink ? "a" : "button"}
      href={isLink ? link : ""}
      target={isLink ? "_blank" : ""}
      disabled={state === "inactive"}
    >
      {state.audioTrack.paused ? <PlayIcon /> : <PauseIcon />}
    </StyledButton>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object,
  state: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.func,
  addClass: PropTypes.string,
  addStyle: PropTypes.object,
  isLink: PropTypes.bool,
  link: PropTypes.string,
};

Button.defaultProps = {
  label: null,
  icon: null,
  state: null,
  type: null,
  action: null,
  addClass: null,
  addStyle: null,
  isLink: false,
  link: "",
};

export default Button;
