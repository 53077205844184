import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';
import { useGLTF, Html } from '@react-three/drei';
import { ReactComponent as Hotspot } from '../assets/icons/icon-hotspot.svg';
import iconsJSON from '../assets/data/icon_feature.json';
import iconJSON from '../assets/data/icon_feature.json';
import * as THREE from 'three';

const TAU = Math.PI * 2;

const normalizeAngle = (angle) => {
  return THREE.MathUtils.euclideanModulo(angle, TAU);
}
const absoluteAngle = (targetAngle, sourceAngle) => {
  const angle = targetAngle - sourceAngle;
  return THREE.MathUtils.euclideanModulo(angle + Math.PI, TAU) - Math.PI;
}

const pulseButton = keyframes`
  0%   {
    transform: translate(-50%, -50%) scale(1, 1); opacity: 0;
  }
  50%   {
    opacity: 0.65;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.6, 1.6); opacity: 0;
  }
`;

const StyledHtml = styled(Html)`
  width: 35rem;
  height: 35rem;
  border: none;
  box-shadow: none;
  background: transparent;
  cursor: pointer;
  border-radius: 100%;
  will-change: transform;
  &.selected {
    &:after {
      content: "";
      width: 36rem;
      height: 36rem;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #BB5921;
      z-index: 1001;
    }
  }
  &.animate {
    &:before {
      content: '';
      width: 35rem;
      height: 35rem;
      position: absolute;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 3rem solid #FFF;
      will-change: transform;
      animation: ${pulseButton} 1500ms ease-in-out 0s infinite;
    }
  }
  @media (min-width: 1024px) {
    width: 25rem;
    height: 25rem;
    &:before {
      content: '';
      width: 25rem;
      height: 25rem;
    }
    &.selected {
      &:after {
        content: "";
        width: 26rem;
        height: 26rem;
      }
    }
  }
  @media (min-width: 1366px) {
    width: 20rem;
    height: 20rem;
    &:before {
      content: '';
      width: 20rem;
      height: 20rem;
    }
    &.selected {
      &:after {
        content: "";
        width: 21rem;
        height: 21rem;
      }
    }
  }
`;

const StyledHotspotButtonWrapper = styled.button`
  z-index: 1001;
  position: absolute;
  border: none;
  &:disabled {
    opacity: 0.5;
  }
`;

const StyledHotspot = styled(Hotspot)`
  width: 35rem;
  height: 35rem;
  z-index: 1000;
  @media (min-width: 1024px) {
    width: 25rem;
    height: 25rem;
  }
  @media (min-width: 1366px) {
    width: 20rem;
    height: 20rem;
  }
`;

const IconModel = ({ storeState, controls, camera, url, store, levelPositions, iconLevelIdx, setHNumber, hotspotIndx, setHotspotIndx, showLvlPanel, setShowLvlPanel, setToggleViewMode, enableControls, controlsEnabled, loaded, settingLoaded, settingSmoothTime, settingRestThreshold, ...props }) => {
  const [annotations, setAnnotations] = useState([]);
  const [infoHotspots, setInfoHotspots] = useState([]);
  const [dataHotspots, setDataHotspots] = useState([]);
  const { scene } = useGLTF(url);
  const firstReveal = useRef(false);
  const hotspotTimeout = useRef(null);
  const lastHotspot = useRef(null);

  const onClickHotspot = (hotspotDataInfo, hotspotsInfo) => {
    console.log('!!! opened hotspot ', hotspotDataInfo, hotspotsInfo);
    clearTimeout(hotspotTimeout.current);
    // const iconInfoArray = iconJSON[iconLevelIdx];
    const iconInfo = hotspotsInfo.find(i => i.name.toLowerCase() === hotspotDataInfo.userData.name.toLowerCase());
    if (iconInfo) {
      if (document.getElementsByClassName(iconInfo.name).length > 0) {
        document.getElementsByClassName(iconInfo.name)[0].classList.remove("animate");
        document.getElementsByClassName(iconInfo.name)[0].classList.add("selected");
      }

      let lookAtSpotTime = null;
      if (lookAtSpotTime) clearTimeout(lookAtSpotTime);

      store.iconHotspot({ open: true, info: { title: iconInfo.title, description: iconInfo.info } });

      controls.current.rotateTo(normalizeAngle(iconInfo.theta), iconInfo.phi, true);
      controls.current.normalizeRotations();

      let zoom = 2;
      if (iconInfo.name.includes('Prophet') && iconLevelIdx === 0) {
        zoom = 2.5
      }
      controls.current.zoomTo(zoom, true);
      /** set per level */
      const indx = hotspotsInfo.findIndex(el => el.name.toLowerCase() === iconInfo.name.toLowerCase());
      setHotspotIndx(indx);
      lastHotspot.current = iconInfo.name;
    }
  };

  useEffect(() => {
    /** INITIAL */
    enableControls(false);
    setTimeout(() => {
      settingLoaded(true)
    }, 5100);
  }, []);

  useEffect(() => {
    if (controls && loaded) {
      setTimeout(() => {
        controls.current.addEventListener('sleep', () => {
          enableControls(true);
          console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
          console.log('target: ', controls.current.getTarget());
          console.log('position: ', controls.current.getPosition());
          console.log('Theta: ', controls.current.azimuthAngle);
          console.log('Phi: ', controls.current.polarAngle);
          console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
        });

        const moveCamera = async () => {
          const animate = true;
          controls.current.moveTo(0, 2, -1, animate);
          controls.current.zoomTo(1.2, animate);
          await controls.current.moveTo(0, 2, 1, animate);
          await controls.current.moveTo(0, 9, 1, animate);
          await controls.current.rotateTo(Math.PI, Math.PI, animate);
          await controls.current.zoomTo(2, animate);

          settingSmoothTime(0.85);
          settingRestThreshold(0);
          if (!firstReveal.current) {
            setShowLvlPanel(true);
            setToggleViewMode();
          }
          firstReveal.current = true;
        };

        // removing two and three finger controls
        controls.current.touches.two = 32768;
        controls.current.touches.three = null;
        controls.current.mouseButtons.wheel = 16;
        controls.current.mouseButtons.right = null;
        controls.current.mouseButtons.middle = null;


        moveCamera();
        console.log(controls.current);

        // setTimeout(() => {
        //   enableControls(true);
        // }, 5500);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    /** ON HOTSPOT ARROW CLICK */
    // move to hotspot based on new hotspot index
    if (dataHotspots.length > 0) {
      clearTimeout(hotspotTimeout.current);
      let lookAtSpotTime = null;
      if (lookAtSpotTime) clearTimeout(lookAtSpotTime);
      // removing two and three finger controls
      controls.current.touches.two = 32768;
      controls.current.touches.three = null;
      controls.current.mouseButtons.wheel = 16;
      controls.current.mouseButtons.right = null;
      controls.current.mouseButtons.middle = null;

      console.log(infoHotspots[hotspotIndx], infoHotspots);
      const allSpots = document.getElementsByClassName("hotspot");
      for (let i = 0; i < allSpots.length; i++) {
        allSpots[i].classList.remove("selected");
      }
      if (document.getElementsByClassName(infoHotspots[hotspotIndx].name).length > 0) {
        document.getElementsByClassName(infoHotspots[hotspotIndx].name)[0].classList.remove("animate");
        document.getElementsByClassName(infoHotspots[hotspotIndx].name)[0].classList.add("selected");
      }

      //const findDataHotspot = dataHotspots.find(el => el.name.toLowerCase() === infoHotspots[hotspotIndx].name.toLowerCase())[0];

      //controls.current.rotateTo(infoHotspots[hotspotIndx].theta, infoHotspots[hotspotIndx].phi, true);
      controls.current.rotateTo(
        normalizeAngle(infoHotspots[hotspotIndx].theta),
        infoHotspots[hotspotIndx].phi,
        true
      );
      controls.current.normalizeRotations();
      let zoom = 2;
      if (infoHotspots[hotspotIndx].name.includes('Prophet') && iconLevelIdx === 0) {
        zoom = 2.5
      }
      controls.current.zoomTo(zoom, true);
      store.iconHotspot({ open: storeState.iconHotspot.open, info: { title: infoHotspots[hotspotIndx].title, description: infoHotspots[hotspotIndx].info } });
    }
  }, [hotspotIndx]);

  useEffect(() => {
    store.iconHotspot({ open: false, info: { title: "", description: "" } });
    const allSpots = document.getElementsByClassName("hotspot");
    for (let i = 0; i < allSpots.length; i++) {
      allSpots[i].classList.remove("selected");
    }
    clearTimeout(hotspotTimeout.current);
    const tempannotations = [];
    const hotspotsArray = [];
    const allHotspots = [];
    scene.traverse((o) => {
      if (o.userData.name) {
        allHotspots.push(o.userData);
      }
      if (o.userData.name && (o.userData.level === (iconLevelIdx + 1))) {
        const iconInfoArray = iconJSON[iconLevelIdx];
        const iconInfo = iconInfoArray.hotspots.find(i => i.name.toLowerCase() === o.userData.name.toLowerCase());
        hotspotsArray.push({ ...o, ...iconInfo });
        tempannotations.push(
          <StyledHtml
            key={o.uuid}
            center={true}
            position={[o.position.x, o.position.y, o.position.z]}
            distanceFactor={1}
            zIndexRange={[96, 0]}
            className={`hotspot animate ${iconInfo.name}`}
          >
            
              <StyledHotspotButtonWrapper onPointerUp={() => onClickHotspot(o, iconInfoArray.hotspots)}>
              <StyledHotspot />
              </StyledHotspotButtonWrapper>
            
          </StyledHtml>
        )
      }
    })

    console.log(iconJSON[iconLevelIdx]);
    setAnnotations(tempannotations);
    setInfoHotspots(iconJSON[iconLevelIdx].hotspots);
    setDataHotspots(hotspotsArray);
    setHNumber(tempannotations.length);
    store.iconList(iconsJSON);
    console.log('Caching JSX for url ' + url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconLevelIdx]);

  useEffect(() => {
    //controls.current.addEventListener('sleep', () => {
      /** auto open first hotspot */
      clearTimeout(hotspotTimeout.current);
      const firstHotspot = dataHotspots.find(i => i.userData.name.toLowerCase() === iconJSON[iconLevelIdx].hotspots[0].name.toLowerCase());  
      if (firstHotspot && controlsEnabled) {
        hotspotTimeout.current = setTimeout(() => {
          onClickHotspot(firstHotspot, iconJSON[iconLevelIdx].hotspots);
        }, [3500]);
      }
    //});
  }, [iconLevelIdx, dataHotspots, controlsEnabled]);

  // By the time we're here the model is gueranteed to be available
  return <primitive object={scene} {...props} rotation={[0, 0, 0]} position={[0, -1, 0]}>{annotations}</primitive>;
}

IconModel.defaultProps = {
  storeState: {},
  controls: {},
  camera: {},
  url: '',
  store: {},
  levelPositions: {},
  iconLevelIdx: 0,
  setHNumber: null,
  hotspotIndx: 0,
  setHotspotIndx: null,
  showLvlPanel: false,
  setShowLvlPanel: null,
  setToggleViewMode: null,
  enableControls: null,
  loaded: PropTypes.bool,
  settingLoaded: null,
  settingSmoothTime: null,
  settingRestThreshold: null,
};
IconModel.propTypes = {
  storeState: PropTypes.object,
  controls: PropTypes.object,
  camera: PropTypes.object,
  url: PropTypes.string,
  store: PropTypes.object,
  levelPositions: PropTypes.object,
  iconLevelIdx: PropTypes.number,
  setHNumber: PropTypes.func,
  hotspotIndx: PropTypes.number,
  setHotspotIndx: PropTypes.func,
  showLvlPanel: PropTypes.bool,
  setShowLvlPanel: PropTypes.func,
  setToggleViewMode: PropTypes.func,
  enableControls: PropTypes.func,
  loaded: PropTypes.bool,
  settingLoaded: PropTypes.func,
  settingSmoothTime: PropTypes.func,
  settingRestThreshold: PropTypes.func,
};

export default IconModel;