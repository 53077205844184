import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components/macro";
import store from "../../store";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { ReactComponent as LocationIcon } from "../../assets/icons/32-icon-location-v2.svg";
import { ReactComponent as CandleIcon } from "../../assets/icons/32-icon-candles.svg";
import { ReactComponent as ArrowAroundIcon } from "../../assets/icons/32-icon-around.svg";
import mapShell from "../../assets/images/map_shell.png";
import mapNarthex from "../../assets/images/map_narthex.png";
import mapNave from "../../assets/images/map_nave.png";
import mapImage from "../../assets/images/building-map.png";
import { ReactComponent as LeftArrow } from "../../assets/icons/32-icon-leftArrow-v2.svg";
import MapButton from "../../components/MapButton";
import Button from "../../components/Button";
import { ReactComponent as NaveOverlaySVG } from "../../assets/images/nave-overlay.svg";
import { ReactComponent as NarthexOverlaySVG } from "../../assets/images/narthex-overlay.svg";
import { ReactComponent as ExteriorOverlaySVG } from "../../assets/images/building-overlay.svg";
import { ReactComponent as BuildingOutlineSVG } from "../../assets/images/building-outline.svg";
import { ReactComponent as RomanNumeralI } from "../../assets/icons/roman-numeral-I.svg";
import { ReactComponent as RomanNumeralII } from "../../assets/icons/roman-numeral-II.svg";
import { ReactComponent as RomanNumeralIII } from "../../assets/icons/roman-numeral-III.svg";

const sizeDivisors = {
  1024: 1.8,
  1921: 0.8,
  1950: 0.6,
};

const PageTitle = styled.h1`
  font-family: "Noto Serif", serif;
  font-weight: bold;
  font-size: ${18 / 16}rem;
  line-height: normal;
  color: ${(props) => props.theme.textLightOnDark};

  @media (min-width: 1024px) {
    font-size: ${28 / 16}rem;
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  background: #f4f3f0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding-top: 80px;

  @media (min-width: 810px) {
    position: fixed;
    width: 100%;
    padding-top: 0;
    justify-content: center;
    align-items: flex-start;
  }
`;

const OuterBuildingContainer = styled.div`
  display: none;
  //background-color: blue;
  pointer-events: none;
  z-index: 1;

  @media (min-width: 810px) {
    position: absolute;
    top: 16%;
    right: 50%;
    display: inline;
    width: ${697 / 16 / sizeDivisors[1024]}rem;
    height: ${916 / 16 / sizeDivisors[1024]}rem;
  }
  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      position: absolute;
      top: 10.46%;
      right: 50%;
      display: inline;
      width: ${697 / 16}rem;
      height: ${916 / 16}rem;
    }
  }

  @media (min-width: 1921px) {
    @media (min-height: 1189px) {
      left: 15%;
      width ${697 / 16 / sizeDivisors[1921]}rem;
      height: ${916 / 16 / sizeDivisors[1921]}rem;
    }
  }

  @media (min-width: 2561px) {
    @media (min-height: 1607px) {
      width: ${697 / 16 / sizeDivisors[1950]}rem;
      height: ${916 / 16 / sizeDivisors[1950]}rem;
      left: 20%;
    }
  }
`;

const LocationList = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: 810px) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 7%;
    left: 50%;
    width: 50%;
  }
  @media (min-width: 820px) {
    top: 7%;
  }


  @media (min-width: 1024px) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 21%;
    left: 50%;
    height: 100%;
  }

  @media (min-width: 1352px) {
    left: 50%;
    @media (min-height: 960px) {
      top: 27%;
      left: 54%;
    }
  }

  @media (min-width: 2040px) {
    top: 27%;
    left: 50%;
    @media (min-height: 1607px) {
      left: 54%;
    }
  }
`;

// width: ${535.65 / 16}rem;
// height: ${787.03 / 16}rem;

const BuildingContainer = styled.div`
  pointer-events: none;

  @media (min-width: 810px) {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`;

const TempleBackground = styled.img`
  position: absolute;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  bottom: ${51 / 16}rem;
  /* width: ${378 / 16}rem};
  height: ${559 / 16}rem; */
  width: auto;
  max-width: none;
  height: 75%;
  margin: auto;
  z-index: 1;

  @media (max-height: 700px) {
    bottom: 0;
  }

  @media (min-width: 810px) {
    display: none;
  }
`;

const Title = styled.h2`
  font-family: "Noto Serif", serif;
  font-weight: bold;
  font-size: ${28 / 16}rem;
  line-height: ${30 / 16}rem;
  color: ${(props) => props.theme.textLightOnDark};
  pointer-events: auto;

  @media (min-width: 810px) {
    font-weight: bold;
    font-size: ${45 / 16 / sizeDivisors[1024]}rem;
  }

  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      font-size: ${45 / 16}rem;
    }
  }

  @media (min-width: 1921px) {
    @media (min-height: 1189px) {
      font-size: ${45 / 16 / sizeDivisors[1921]}rem;
    }
  }

  @media (min-width: 1950px) {
    @media (min-height: 1607px) {
      font-size: ${45 / 16 / sizeDivisors[1950]}rem;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  pointer-events: auto;
  margin-top: ${15 / 16 / sizeDivisors[1024]}rem;
  .dark {
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    margin-top: ${15 / 16 / sizeDivisors[1024]}rem;
    .dark {
      margin-left: ${39 / 16 / sizeDivisors[1024]}rem;
    }
  }
  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      margin-top: ${15 / 16}rem;
      .dark {
        margin-left: ${39 / 16}rem;
      }
    }
  }
  @media (min-width: 1921px) {
    @media (min-height: 1189px) {
      margin-top: ${15 / 16 / sizeDivisors[1921]}rem;
      .dark {
        margin-left: ${39 / 16 / sizeDivisors[1921]}rem;
      }
    }
  }
  @media (min-width: 1950px) {
    @media (min-height: 1607px) {
      margin-top: ${15 / 16 / 0.3}rem;
      .dark {
        margin-left: ${39 / 16 / sizeDivisors[1921]}rem;
      }
    }
  }
`;

const ExteriorShell = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${mapShell});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  pointer-events: none;
  z-index: 3;
  opacity: ${(props) =>
    props.hoverLocation === "exterior" || !props.hoverLocation ? 1 : 0.5};
  filter: ${(props) =>
    props.hoverLocation === "exterior" ? "saturate(0)" : "saturate(1)"};
`;

const Narthex = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${mapNarthex});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  pointer-events: none;
  z-index: 4;

  opacity: ${(props) =>
    props.hoverLocation === "narthex" ||
    !props.hoverLocation ||
    props.hoverLocation === "exterior"
      ? 1
      : 0.5};
`;

const Nave = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${mapNave});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  pointer-events: none;
  z-index: 4;

  opacity: ${(props) =>
    props.hoverLocation === "nave" ||
    !props.hoverLocation ||
    props.hoverLocation === "exterior"
      ? 1
      : 0.5};
`;

const BuildingFocusEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(212, 206, 190, 1);
  pointer-events: auto;
  filter: blur(50px);
  opacity: ${(props) => (props.hoverLocation === "exterior" ? 1 : 0)};
`;

const MapSection = styled.div`
  z-index: 2;
  @media (max-width: 1023px) {
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    width: 100%;
    margin-bottom: 70px;
    &:first-child {
      margin-bottom: 50px;
    }
  }

  @media (min-width: 810px) {
    margin-left: ${31 / 16 / sizeDivisors[1024]}rem;
    margin-bottom: ${52.5 / 16 / sizeDivisors[1024]}rem;
    margin-top: ${25 / 16 / sizeDivisors[1024]}rem;

    opacity: ${(props) =>
      props.hoverLocation === props.mapSectionLocation || !props.hoverLocation
        ? 1
        : 0.3};
  }
  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      margin-left: ${31 / 16}rem;
      margin-bottom: ${52.5 / 16}rem;
      margin-top: ${25 / 16}rem;
    }
  }

  button {
    pointer-events: auto;
  }
  @media (max-height: 700px) {
    transform: translateY(5%);
  }
`;

const NarthexOverlay = styled(NarthexOverlaySVG)`
  position: absolute;
  pointer-events: none;
  z-index: 3;
  opacity: ${(props) => (props.hoverLocation === "narthex" ? 1 : 0)};

  @media (min-width: 810px) {
    width: 76.5%;
    top: 7.6%;
    left: 12.5%;
  }

  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      top: 21%;
      left: 12%;
    }
  }

  @media (min-width: 1921px) {
    @media (min-height: 1189px) {
      top: -13.5%;
      left: 12%;
      width: 76.25%;
      height: 100%;
    }
  }
`;

const NaveOverlay = styled(NaveOverlaySVG)`
  position: absolute;
  pointer-events: none;
  z-index: 3;
  opacity: ${(props) => (props.hoverLocation === "nave" ? 1 : 0)};

  @media (min-width: 810px) {
    width: 66%;
    top: 19.75%;
    left: 17.25%;
  }
  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      top: 40%;
      left: 17.5%;
    }
  }
  @media (min-width: 1921px) {
    @media (min-height: 1189px) {
      width: 66%;
      height: 100%;
      top: 15%;
    }
  }
`;

const ExteriorOverlay = styled(ExteriorOverlaySVG)`
  position: absolute;
  pointer-events: none;
  z-index: 8 !important;
  pointer-events: none;
  opacity: ${(props) => (props.hoverLocation === "exterior" ? 0.6 : 0)};

  @media (min-width: 810px) {
    bottom: -28%;
    left: 12%;
    width: 77.5%;
  }
  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      bottom: 7%;
      left: 12%;
      width: 77.5%;
      height: 86%;
    }
  }
`;

const BuildingOutline = styled(BuildingOutlineSVG)`
  position: absolute;
  z-index: 15;
  pointer-events: none;
  opacity: ${(props) => (props.hoverLocation === "exterior" ? 1 : 0)};

  @media (min-width: 810px) {
    bottom: -28%;
    left: 12%;
    width: 77.5%;
  }

  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      bottom: 7%;
      left: 12%;
    }
  }
  @media (min-width: 1921px) {
    @media (min-height: 1189px) {
      bottom: 7%;
      left: 5%;
      width: 91%;
      height: 86%;
    }
  }
`;

const SectionINumber = styled(RomanNumeralI)`
  position: absolute;
  z-index: 10;

  opacity: ${(props) =>
    props.hoverLocation === "exterior" || !props.hoverLocation ? 1 : 0.3};

  @media (min-width: 810px) {
    top: -3%;
    left: 47%;
  }
  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      top: 0%;
      left: 49%;
    }
  }
  @media (min-width: 1921px) {
    @media (min-height: 1189px) {
      top: 0%;
      left: 49%;
    }
  }
  @media (min-width: 1950px) {
    @media (min-height: 1607px) {
      top: 1%;
      left: 49.5%;
    }
  }
`;

const SectionIINumber = styled(RomanNumeralII)`
  position: absolute;
  z-index: 10;

  opacity: ${(props) =>
    props.hoverLocation === "narthex" || !props.hoverLocation ? 1 : 0.3};

  @media (min-width: 810px) {
    top: 26%;
    left: 46%;
  }
  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      left: 48%;
    }
  }
  @media (min-width: 1921px) {
    @media (min-height: 1189px) {
      top: 28%;
      left: 48.5%;
    }
  }
  @media (min-width: 1950px) {
    @media (min-height: 1607px) {
      top: 28%;
      left: 49%;
    }
  }
`;

const SectionIIINumber = styled(RomanNumeralIII)`
  position: absolute;
  overflow: visible;
  z-index: 10;
  bottom: 33.25%;
  left: 42.25%;

  opacity: ${(props) =>
    props.hoverLocation === "nave" || !props.hoverLocation ? 1 : 0.3};

  @media (min-width: 810px) {
    bottom: 31.5%;
    left: 35.5%;
  }
  @media (min-width: 1352px) {
    @media (min-height: 960px) {
      bottom: 33.25%;
      left: 42.25%;
    }
  }
  @media (min-width: 1921px) {
    @media (min-height: 1189px) {
      bottom: 34%;
      left: 44.15%;
    }
  }
  @media (min-width: 1950px) {
    @media (min-height: 1607px) {
      bottom: 34.5%;
      left: 45.7%;
    }
  }
`;

const SectionIHoverZone = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 899;
  pointer-events: auto;
`;
const SectionIIHoverZone = styled.div`
  position: absolute;
  top: 20%;
  left: 25%;
  width: 50%;
  height: 20%;
  z-index: 900;
  pointer-events: auto;
`;
const SectionIIIHoverZone = styled.div`
  position: absolute;
  bottom: 20%;
  left: 25%;
  width: 50%;
  height: 30%;
  z-index: 900;
  pointer-events: auto;
`;

const BackButtonWrapper = styled.div`
  position: fixed;
  left: 0px;
  top: ${56 / 16}rem;
  z-index: 98;

  @media (min-width: 1024px) {
    top: ${88 / 16}rem;
  }
`;

const Map = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(store.initialState);
  const [hoverLocation, setHoverLocation] = useState(null); // ["narthex", "nave", "exterior"]
  const sub = useRef(null);
  const theme = useTheme();

  //useEffect console log hoverLocation upon change
  useEffect(() => {
    console.log(hoverLocation);
  }, [hoverLocation]);

  useEffect(() => {
    sub.current = store.subscribe(setState);
    store.setHeaderColorAll(theme.navGold);
    return () => {
      sub.current.unsubscribe();
    };
  }, []);

  return (
    <MapWrapper>
      <BackButtonWrapper>
        <MapButton
          label="Back"
          action={() => navigate(-1)}
          type="back"
          icon={<LeftArrow />}
          addClass="icon-first"
        />
      </BackButtonWrapper>
      <OuterBuildingContainer>
        <BuildingFocusEffect
          onMouseEnter={() => setHoverLocation("exterior")}
          onMouseLeave={() => setHoverLocation(null)}
          hoverLocation={hoverLocation}
        />
        <BuildingContainer>
          <BuildingOutline hoverLocation={hoverLocation} />
          <SectionINumber hoverLocation={hoverLocation} />
          <Nave hoverLocation={hoverLocation}>
            <SectionIIINumber hoverLocation={hoverLocation} />
            <SectionIIIHoverZone
              onMouseEnter={() => setHoverLocation("nave")}
              onMouseLeave={() => setHoverLocation(null)}
            />
            <NaveOverlay hoverLocation={hoverLocation} />
          </Nave>
          <Narthex hoverLocation={hoverLocation}>
            <SectionIINumber hoverLocation={hoverLocation} />
            <SectionIIHoverZone
              onMouseEnter={() => setHoverLocation("narthex")}
              onMouseLeave={() => setHoverLocation(null)}
            />
            <NarthexOverlay hoverLocation={hoverLocation} />
          </Narthex>
          <ExteriorShell hoverLocation={hoverLocation}>
            <SectionIHoverZone
              onMouseEnter={() => setHoverLocation("exterior")}
              onMouseLeave={() => setHoverLocation(null)}
            />
          </ExteriorShell>
          <ExteriorOverlay hoverLocation={hoverLocation} />
        </BuildingContainer>
      </OuterBuildingContainer>
      <LocationList>
        <MapSection>
          <PageTitle>Tour Stops</PageTitle>
        </MapSection>
        {/* <TempleBackground src={mapImage} /> */}
        <MapSection
          bottomOffset={71}
          hoverLocation={hoverLocation}
          mapSectionLocation="exterior"
        >
          <Title
            onMouseEnter={() => setHoverLocation("exterior")}
            onMouseLeave={() => setHoverLocation(null)}
          >
            I. The Building
          </Title>
          <ButtonContainer
            onMouseEnter={() => setHoverLocation("exterior")}
            onMouseLeave={() => setHoverLocation(null)}
          >
            <Button
              label="Start Tour"
              type="light"
              icon={<LocationIcon />}
              action={() => {
                store.setHeaderColorAll(theme.primary9);
                navigate("/tour/exterior");
              }}
            />
          </ButtonContainer>
        </MapSection>
        <MapSection
          bottomOffset={48}
          hoverLocation={hoverLocation}
          mapSectionLocation="narthex"
        >
          <Title
            onMouseEnter={() => setHoverLocation("narthex")}
            onMouseLeave={() => setHoverLocation(null)}
          >
            II. The Narthex
          </Title>
          <ButtonContainer
            onMouseEnter={() => setHoverLocation("narthex")}
            onMouseLeave={() => setHoverLocation(null)}
          >
            <Button
              label="Start Tour"
              addClass="button-on-top"
              type="light"
              icon={<LocationIcon />}
              action={() => {
                store.setHeaderColorAll(theme.primary9);
                navigate("/tour/narthex");
              }}
            />
            <Button
              label="Light a Candle"
              type="dark"
              icon={<CandleIcon />}
              action={() => {
                store.setHeaderColorAll(theme.primary9);
                navigate("/light-a-candle");
              }}
            />
          </ButtonContainer>
        </MapSection>
        <MapSection
          bottomOffset={21}
          hoverLocation={hoverLocation}
          mapSectionLocation="nave"
        >
          <Title
            onMouseEnter={() => setHoverLocation("nave")}
            onMouseLeave={() => setHoverLocation(null)}
          >
            III. The Nave
          </Title>
          <ButtonContainer
            onMouseEnter={() => setHoverLocation("nave")}
            onMouseLeave={() => setHoverLocation(null)}
          >
            <Button
              label="Start Tour"
              addClass="button-on-top"
              type="light"
              icon={<LocationIcon />}
              action={() => {
                store.setHeaderColorAll(theme.primary9);
                navigate("/tour/nave");
              }}
            />
            <Button
              label="Explore Icons"
              type="dark"
              icon={<ArrowAroundIcon />}
              action={() => {
                store.setHeaderColorAll(theme.primary9);
                navigate("/explore-icons");
              }}
            />
          </ButtonContainer>
        </MapSection>
      </LocationList>
    </MapWrapper>
  );
};

export default Map;
