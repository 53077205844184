import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import errorIcon from '../assets/icons/32-icon-error.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/48-icon-close.svg';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from './Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const FormContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const FormWrapper = styled(motion(Container))`
  width: 80vw;
  height: 60vh;
  background-color: ${props => props.theme.bgDark};
  color: ${props => props.theme.neutral1};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 10em;
  position: relative;
  z-index: 999;
  @media (min-width: 1024px) {
    margin-top: 5em;
    width: 80vw;
  }
  @media (min-width: 1366px) {
    width: 30vw;
  }
`;

const Title = styled.h1`
  font-family: 'Noto Serif', serif;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 25px;
`;

const Subtitle = styled.p`
  font-family: 'Sintony',sans-serif;
  font-size: 10px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 1rem;
  &.red {
    color: #B52C2C;
  }
  &.blue {
    color: #007bff;
  }
  &.green {
    color: #28a745
  }
`;

const StyledForm = styled(Form)`
  width: calc(100% - 18px);
  padding: 0 18px;
  & input:focus, 
  & select:focus {
    border:  1px solid #816920;
    outline: 1px solid #816920;
    box-shadow: none;
  }
  &.was-validated {
    & .form-select:valid {
      background-size: 24px !important;
      border-color: ${props => props.theme.primary7} !important;
    }
    & .form-select:invalid {
      background-size: 24px, 24px !important;
    }
    & .form-control:valid {
      background-image: none;
      border-color: ${props => props.theme.primary7} !important;
    }
    & .form-control:invalid {
      background-image: url(${errorIcon}) !important;
      background-size: 24px !important;
    }
    & .form-control:valid:focus,
    & .form-select:valid:focus {
      box-shadow: 0 0 0 0.1rem #816920;
    }
  }
`;

const FormGroup = styled(Form.Group)`
  &.visible {
    visibility: visible;
    height: auto;
  }
  &.hidden {
    visibility: hidden;
    height: 0;
  }
`;

const FormLabel  = styled(Form.Label)`
  font-family: 'Sintony', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
`;

const FormControl = styled(Form.Control)`
  font-family: 'Sintony', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  height: 48px;
  border-radius: 0.5rem;
  border-color: ${props => props.theme.primary7};
  color: ${props => props.value === '' ? '#939393' :  props.theme.neutral1};
  &:placeholder {
    color: ${props => props.theme.neutral1};
  }
`;


const MiniForm = ({ status, message, onValidated, showForm, setShowForm }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    firstName &&
    lastName &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email,
      MERGE1: firstName,
      MERGE2: lastName,
    });
  };


  useEffect(() => {
    if(status === "success") clearFields();
    if(showForm && status === "success") clearFields();
  }, [status, showForm])

  const clearFields = () => {
      setFirstName('');
      setLastName('');
      setEmail('');
  }

  return (
    <FormWrapper
      layoutId="form"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0  }}
      transition={{ duration: 0.6 }}
      fluid="sm"
    >
      <Button
        type="tertiary"
        icon={<CloseIcon />}
        action={() => setShowForm(false)}
        addStyle={{
          position: 'absolute',
          top: 18,
          right: 18,
        }}
      />

    <Title>
      {status === "success" 
      ? "Success!" 
      : "Join our email list for future updates."
      }
    </Title>

    {status === "sending" && (
      <Subtitle className="blue">Sending...</Subtitle>
    )}
    {status === "error" && (
      <Subtitle className="red">{message}</Subtitle>
    )}
    {status === "success" && (
      <Subtitle className="green">{message}</Subtitle>
    )}

    {status !== "success" ? (
      <StyledForm noValidate onSubmit={ (e) => handleSubmit(e)}>
        <Row sm={2} style={{ marginBottom: '1em' }}>
          <FormGroup as={Col} id="initials">
            <FormLabel htmlFor="initials">First Name *</FormLabel>
            <FormControl
              required
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>

          <FormGroup as={Col} id="initials">
            <FormLabel htmlFor="initials">Last Name *</FormLabel>
            <FormControl
              required
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
        </Row>

        <Row sm={2} style={{ marginBottom: '1em' }}>
          <FormGroup as={Col} id="initials">
            <FormLabel htmlFor="initials">Email *</FormLabel>
            <FormControl
              required
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
        </Row>

        {status !== 'success' && (
            <Button
              type="secondary"
              label="Submit"
              addStyle={{ margin: '15px auto', width: 'auto' }}
            />
          )
        }

      </StyledForm>
    ) : null}
    {status === 'success' && (
      <Button
        type="secondary"
        label="Close"
        addStyle={{ margin: '15px auto' }}
        action={() => setShowForm(false)}
      />
    )}
  </FormWrapper>
  );
};


const NewsletterForm = ({ showForm, setShowForm }) => {
  const postUrl = `${process.env.REACT_APP_MAILCHIMP_URL}?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

    return (
      <FormContainer>
        <MailchimpSubscribe
          url={postUrl}
          render={({ subscribe, status, message }) => (
            <MiniForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
              showForm={showForm}
              setShowForm={setShowForm}
            />
          )}
        />
      </FormContainer>
    );
};

export default NewsletterForm;