import React, { useState, useLayoutEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import PageLayout from './containers/PageLayout';
import innerHeight from 'ios-inner-height';
import Tour from './containers/Tour';
import Exterior from './containers/Tour/Exterior';
import Narthex from './containers/Tour/Narthex';
import Nave from './containers/Tour/Nave';
import Landing from './containers/Landing';
import About from './containers/About';
import Candle from './containers/Candle/';
import NotFound from './containers/NotFound';
import Icons from './containers/Icons';
import Map from './containers/Tour/Map';
import PrivacyPolicy from './containers/PrivacyPolicy';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(true);

  useLayoutEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${innerHeight()}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight();

    if (sessionStorage.getItem('headphones')) {
      setLoading(false);
    }

    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  const setLoadingFunction = (load) => {
    setLoading(load);
  };

  const setSpinnerFunction = (spin) => {
    setSpinner(spin);
  };

  return(
    <BrowserRouter>
      <Routes>
        <Route element={
          <PageLayout
            loading={loading}
            setLoading={setLoadingFunction}
            spinner={spinner}
            setSpinner={setSpinnerFunction}
          />
        }>
          <Route exact path="/" element={
            <Landing loading={loading} />
          }/>
          <Route path='/tour' element ={<Map />}/>
          <Route path="/tour" element={<Tour />}>
            <Route path="exterior" element={<Exterior />}/>
            <Route path="narthex" element={<Narthex />}/>
            <Route path="nave" element={<Nave />}/>
          </Route>
          <Route path="/light-a-candle" element={<Candle />}/>
          <Route path="/explore-icons" element={<Icons />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/privacy-cookies-policy" element={<PrivacyPolicy />}/>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;