import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';
import { motion } from 'framer-motion';


const blinkIt = keyframes`
  50% { opacity: .8;}
`;


const moveFlame = keyframes`
  0%, 100% {
      transform: translateX(-50%) rotate(-2deg);
    }
    50% {
      transform: translateX(-50%) rotate(2deg);
    }
`;

const enlargeFlame = keyframes`
  0%, 100% {
      height: 120px;
    }
    50% {
      height: 140px;
    }
`;

const Holder = styled(motion.div)`
  & .center {
    width: 1170px;
    margin: 20px auto 0;
  }

  &.holder {
    margin:  ${props => props.lighting ? '2em' : 0} auto;
    width: 40px;
    height: 100px;
    position: relative;
    transform: ${props => props.lighting ? 'translateY(0) scale(0.8)' : 'translateY(-50%)'};
    top: ${props => props.lighting ? '30' : '50'}%;
    z-index: ${props => props.lighting ? 100 : 10};
    &.result {
      top: ${props => props.lighting ? '10' : '60'}%;
      @media(min-width: 768px) {
        top: 50%;
      }
      @media(min-width: 1024px) {
        top: 55%;
      }
    }
    @media(min-width: 768px) {
      transform: ${props => props.lighting ? 'translateY(0) scale(1)' : 'translateY(-50%)'};
      top: 40%;
    }
    @media(min-width: 1024px) {
      transform: ${props => props.lighting ? 'translateY(0) scale(0.6)' : 'translateY(-50%)'};
      top: ${props => props.lighting ? '40' : '50'}%;
    }
    @media(min-width: 1366px) {
      transform: ${props => props.lighting ? 'translateY(0) scale(0.7)' : 'translateY(-50%)'};
      top: ${props => props.lighting ? '42%' : '50'}%;
    }
    @media(min-width: 1920px) {
      transform: ${props => props.lighting ? 'translateY(0) scale(1)' : 'translateY(-50%)'};
      top: ${props => props.lighting ? '50%' : '50'}%;
    }
  }

  &.holder.smol {
    transform: translate(0, -30%) scale(0.28);
    position: absolute;
    left: 0;
    top: 50%;
  }

  &.holder *, &.holder *:before, &.holder *:after {
    position: absolute;
  }

  & .candle {
    bottom: 0;
    width: 40px;
    height: 130px;
    border-radius: 150px / 60px;
    box-shadow: inset 20px -30px 50px 0 rgba(0, 0, 0, 0.4), inset -20px 0 50px 0 rgba(0, 0, 0, 0.4);
    background: #190f02;
    background: -moz-linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
    background: -webkit-linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
    background: -o-linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
    background: -ms-linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
    background: linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
  }

  & .candle:before {
    width: 100%;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #d47401;
    background: #b86409;
    background: -moz-radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
    background: -webkit-radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
    background: -o-radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
    background: -ms-radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
    background: radial-gradient(#ffef80, #b86409 60%);
    background: radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
  }

  & .candle:after {
    width: 33px;
    height: 12px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    top: 2px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    background: -moz-radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
    background: -webkit-radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
    background: -o-radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
    background: -ms-radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
    background: radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
  }

  & .thread {
    width: 6px;
    height: 36px;
    top: -27px;
    left: 50%;
    z-index: 1;
    border-radius: 40% 40% 0 0;
    transform: translateX(-50%);
    background: #121212;
    background: -moz-linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
    background: -webkit-linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
    background: -o-linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
    background: -ms-linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
    background: linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
  }
`;

const BlinkingGlow = styled(motion.div)`
  opacity: ${props => props.lighting && !props.flameLit ? 0 : 1};
  width: 100px;
  height: 180px;
  left: 50%;
  top: -65%;
  transform: translateX(-50%);
  border-radius: 50%;
  background: #ff6000;
  -webkit-filter: blur(50px);
  -moz-filter: blur(60px);
  -o-filter: blur(60px);
  -ms-filter: blur(60px);
  filter: blur(60px);
  animation: ${props => props.flameLit ? blinkIt : 'none'} .1s infinite ${props => props.delay ? props.delay : 0}ms;
`;

const Glow = styled(motion.div)`
  opacity: ${props => props.lighting && !props.flameLit ? 0 : 1};
  width: 26px;
  height: 60px;
  border-radius: 50% 50% 35% 35%;
  left: 50%;
  top: -61px;
  transform: translateX(-50%);
  background: rgba(0, 133, 255, .7);
  box-shadow: 0 -40px 30px 0 #dc8a0c, 0 40px 50px 0 #dc8a0c, inset 3px 0 2px 0 rgba(0, 133, 255, .6), inset -3px 0 2px 0 rgba(0, 133, 255, .6);
  &:before {
    width: 70%;
    height: 60%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.35);
  }
`;

const Flame = styled(motion.div)`
  opacity: ${props => props.lighting && !props.flameLit ? 0 : 1};
  overflow: hidden;
  width: 24px;
  height: 120px;
  left: 50%;
  transform-origin: 50% 100%;
  transform: translateX(-50%);
  bottom: 100%;
  border-radius: 50% 50% 20% 20%;
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(white 80%, transparent);
  background: -webkit-linear-gradient(white 80%, transparent);
  background: -o-linear-gradient(white 80%, transparent);
  background: -ms-linear-gradient(white 80%, transparent);
  background: linear-gradient(white 80%, transparent);
  animation: ${moveFlame} 6s linear infinite ${props => props.delay ? props.delay : 0}ms, ${enlargeFlame} 5s linear infinite ${props => props.delay ? props.delay : 0}ms;
  &:before {
    width: 100%;
    height: 100%;
    border-radius: 50% 50% 20% 20%;
    box-shadow: 0 0 15px 0 rgba(247, 93, 0, .4), 0 -6px 4px 0 rgba(247, 128, 0, .7);
  }
`;

const LightCta = styled(motion.button)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -110%);
  width: 140px; height: 140px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 100%;
  border: 1px dashed ${props => props.theme.bgLight};
  transition: opacity: 300ms ease-out;
  z-index: 30;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    bottom: -25%; left: 50%;
    transform: translateX(-50%);
    height: 56px;
    width: 1px;
    background-color: ${props => props.theme.bgLight};
  }
  &:before {
    content: "Tap the circle to light your candle";
    width: 80%;
    font-family: 'Sintony', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (min-width: 1024px) {
    top: -15%;
    width: 180px; height: 180px;
  }
`;

const SimpleCandle = ({
  addClass,
  delay,
  lighting,
  isLit,
  setIsLit,
  pickedBoth,
  setPickedBoth
}) => {
  const [flameLit, setFlameLit] = useState(isLit);
  const litCandleTimeout = useRef(null);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(litCandleTimeout.current);
    }
  });

  const doLightFlame = () => {
    setFlameLit(true);
    setIsLit(true);
    setPickedBoth([true, true]);
  };

  return (
    <Holder
      className={`holder ${addClass}`}
      delay={delay * 1000}
      lighting={lighting}
      islit={isLit}
    >
      <LightCta
        style={{ opacity: (lighting && !flameLit && pickedBoth[0]) ? 1 : 0 }}
        onClick={doLightFlame}
      />
      <div className="candle">
        <BlinkingGlow
          lighting={lighting}
          flameLit={flameLit}
          animate={ lighting ? { opacity: flameLit ? [1, 0, 1, 0, 1] : 0 } : {}}
          transition={lighting ? { type: 'spring', duration: 0.3, bounce: 0.1 } : {}}
        />
        <div className="thread"></div>
        <Glow
          lighting={lighting}
          animate={ lighting ? { opacity: flameLit ? [1, 0, 1, 0, 1] : 0 } : {}}
          transition={lighting ? { type: 'spring', duration: 0.3, bounce: 0.1 } : {}}
        />
        <Flame
          lighting={lighting}
          animate={ lighting ? { opacity: flameLit ? [1, 0, 1, 0, 1] : 0 } : {}}
          transition={lighting ? { type: 'spring', duration: 0.3, bounce: 0.1 } : {}}
        />
      </div>
    </Holder>
  );
}

SimpleCandle.propTypes = {
  addClass: PropTypes.string,
  delay: PropTypes.number,
  lighting: PropTypes.bool,
  isLit: PropTypes.bool,
  pickedBoth: PropTypes.arrayOf(PropTypes.bool),
  setPickedBoth: PropTypes.func,
};

SimpleCandle.defaultProps = {
  addClass: '',
  delay: 0,
  lighting: false,
  isLit: false,
  pickedBoth: [false, false],
  setPickedBoth: null
}

export default SimpleCandle;
