import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Button from "./Button";
import styled from "styled-components/macro";
import store from "../store";
import { useTheme } from "styled-components";
import Slider from "react-slick";
import arrowIcon from "../assets/icons/32-icon-arrowDown.svg";
import { useNavigate } from "react-router-dom";
import leftArrowButtonIcon from "../assets/icons/icon-arrow-button-left.svg";
import { ReactComponent as XIcon } from "../assets/icons/icon-x.svg";
import { ToggleButton } from "react-bootstrap";
import { _isUndefined } from "gsap/gsap-core";

const SlideshowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  overflow: visible;
  align-items: center;
  width: ${props => props.isFullscreen ? '100%' : `${286 / 16}rem`};
  height: ${props => props.isFullscreen ? '350px' : '250px'};

  @media (min-width: 1024px) {
    width: ${(props) => (props.isFullscreen ? "70%" : "90%")};
    height: ${(props) => (props.isFullscreen ? "450px" : "300px")};
    overflow: hidden;
  }
`;

const SlideshowView = styled.div`
  width: 100%;
  height: 100%;

  & img {
    padding-bottom: 1rem;
  }
  & li {
    opacity: 0.5;
    & .slick-active {
      opacity: 1;
    }
  }
  & .slick-slide div img {
    ${(props) => (props.isFullscreen ? "height: 350px;" : "height: 250px;")}
    max-width: fit-content;
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 100%;
    overflow: visible;
    & img {
      padding-bottom: 2rem;
    }
    & .slick-slide div img {
      ${(props) => (props.isFullscreen ? "height: 300px;" : "height: 300px;")}
    }
    & .slick-prev,
    .slick-next {
      width: 48px;
      height: 48px;
    }
  }
  & .slick-slide {
    transition: all 0.5s ease-in-out;
  }
  & .slick-slide div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .slick-active:not(.slick-current) {
    opacity: .5;
    scale: .8;
  }

  & .slick-current {
    opacity: 1;
    scale: 1;
  }

  & .slick-slide:not(.slick-active) {
    opacity: 0;
    scale: .1;
  }
  & .slick-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    & li {
      & button {
        &::before {
          content: "•";
          color: ${(props) =>
            props.isFullscreen
              ? props.theme.primary9
              : props.theme.primaryDark};
          width: 1rem;
          height: 2rem;
          line-height: 2rem;
        }
        & slick.active {
          &::before {
            color: ${(props) =>
              props.isFullscreen
                ? props.theme.primary9
                : props.theme.primaryDark};
            width: 1rem;
            height: 2rem;
            line-height: 2rem;
            opacity: 1;
          }
        }
      }
    }
  }
`;

const PreviousButton = styled.button`
  position: absolute;
  top: 50%;
  left: 0;
  ${(props) =>
    props.isFullscreen
      ? "transform: translate(400%, -150%);"
      : "transform: rotate(90deg) translate(0%, 0%);"}
  ${(props) => !props.isDesktop && props.isFullscreen ? 'transform: translate(0%, -225%);' : ''}
  border-radius: 99rem;
  border: none;
  width: ${32 / 16}rem;
  height: ${32 / 16}rem;
  background-image: ${(props) =>
    props.isFullscreen ? `url(${leftArrowButtonIcon})` : `url(${arrowIcon})`};
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 5;
  &:before {
    content: "";
  }
  @media (min-width: 1024px) {
    width: ${48 / 16}rem;
    height: ${48 / 16}rem;
  }
`;

const NextButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  ${(props) =>
    props.isFullscreen
      ? "transform: rotate(180deg) translate(300%, 150%);"
      : "transform: rotate(-90deg) translate(0%, 0%);"}
  ${(props) => !props.isDesktop && props.isFullscreen ? 'transform: rotate(180deg) translate(25%, 225%);' : ''}
  border: none;
  border-radius: 99rem;
  width: ${32 / 16}rem;
  height: ${32 / 16}rem;
  background-image: ${(props) =>
    props.isFullscreen ? `url(${leftArrowButtonIcon})` : `url(${arrowIcon})`};
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 5;
  &:before {
    content: "";
  }
  @media (min-width: 1024px) {
    width: ${48 / 16}rem;
    height: ${48 / 16}rem;
  }
`;


const Image = styled.img`
  height: 300px;
`;

const CloseButton = styled.button`
  position: absolute;
  background: none;
  color: white;
  top: 1%;
  right: 10px;
  border: none;
  font-size: 1.75rem;
  cursor: pointer;
  z-index: 100;
  svg {
    width: 40px;
    height: 40px;
  }
`;

const Caption = styled.p`
  font-family: "Sintony", sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 28px;
  font-family: "Sintony", sans-serif;
  font-size: 10px;
  font-weight: normal;
  line-height: 13px;
  margin-top: 2rem;
  padding: 10px;
  padding-left: ${(props) => (!props.captioninline ? "10px" : 0)};
  height: 85px;
  @media only screen and (orientation: landscape) {
    margin-top: 0;
  }
  @media (min-width: 1024px) {
    font-family: Arial, Helvetica, sans-serif;
    font-size: ${14 / 16}rem;
    line-height: ${20 / 16}rem;
    margin-top: 1rem;
  }
  & b,
  & i {
    color: ${(props) =>
      props.isFullscreen ? props.theme.primary9 : props.theme.natural1};
  }
`;

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #162939;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Slideshow = ({ images, inline, captioninline, onBeforeChange }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [state, setState] = useState(store.state);
  const sliderRef = useRef(null);
  const sub = useRef(null);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isFullscreen && isDesktop ? 3 : 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: isFullscreen ? true : false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
      if (onBeforeChange) onBeforeChange(oldIndex, newIndex);
    },
    initialSlide: currentSlide,
  };

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);


  useEffect(() => {
    document.getElementById('FullScreenContainer') && document.getElementById('FullScreenContainer').scrollIntoView();

    if (document.getElementById('FullScreenContainer')) {
      if (document.getElementById('FullScreenContainer').webkitRequestFullScreen !== undefined) {
        document.getElementById('FullScreenContainer').webkitRequestFullScreen();
      } else if (document.getElementById('FullScreenContainer').requestFullscreen !== undefined) {
        document.getElementById('FullScreenContainer').requestFullscreen();
      } else if (document.getElementById('FullScreenContainer').mozRequestFullScreen !== undefined) {
        document.getElementById('FullScreenContainer').mozRequestFullScreen();
      }
    }
  }, [isFullscreen]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleFullscreenChange = (isFullscreen) => {
    setIsFullscreen(isFullscreen);
    store.setHeaderVisible(!isFullscreen);

    if (!isFullscreen) {
      if (document.webkitExitFullScreen !== undefined) {
        document.webkitExitFullScreen();
      } else if (document.exitFullscreen !== undefined) {
        document.exitFullscreen();
      } else if (document.mozExitFullScreen !== undefined) {
        document.mozExitFullScreen();
      }
    }
  };

  const onNextSlide = () => sliderRef.current.slickNext();
  const onPreviousSlide = () => sliderRef.current.slickPrev();

  return (
    <>
      {isFullscreen ? (
        <FullScreenContainer id='FullScreenContainer'>
          <CloseButton onClick={() => handleFullscreenChange(false)}><XIcon /></CloseButton>
          <SlideshowContainer isFullscreen={isFullscreen} isDesktop={isDesktop}>
            <PreviousButton
              onClick={onPreviousSlide}
              isFullscreen={isFullscreen}
            />
            <SlideshowView isinline={inline} isFullscreen={isFullscreen}>
              <Slider {...settings} ref={sliderRef}>
                {images.map((image, key) => (
                  <React.Fragment key={key}>
                    <Image
                      key={key}
                      src={image.image}
                      alt={`slideshow slide #${key}`}
                      onClick={() => handleFullscreenChange(true)}
                      isFullscreen={isFullscreen}
                    />
                  </React.Fragment>
                ))}
              </Slider>
            </SlideshowView>
            <NextButton onClick={onNextSlide} isFullscreen={isFullscreen} />
          </SlideshowContainer>
          <Caption captioninline={captioninline} isFullscreen={isFullscreen}>
            <b>{images[currentSlide].caption}</b>
            <br />
            <i>{images[currentSlide].credit}</i>
          </Caption>
        </FullScreenContainer>
      ) : (
        <>
          <SlideshowContainer>
            <PreviousButton onClick={onPreviousSlide} />
            <SlideshowView isinline={inline} isFullscreen={isFullscreen}>
              <Slider {...settings} ref={sliderRef}>
                {images.map((image, key) => (
                  <React.Fragment key={key}>
                    <Image
                      key={key}
                      src={image.image}
                      alt={`slideshow slide #${key}`}
                      onClick={() => handleFullscreenChange(true)}
                      isFullscreen={isFullscreen}
                    />
                  </React.Fragment>
                ))}
              </Slider>
            </SlideshowView>
            <NextButton onClick={onNextSlide} />
          </SlideshowContainer>
          <Caption captioninline={captioninline} isFullscreen={isFullscreen}>
            <b>{images[currentSlide].caption}</b>
            <br />
            <i>{images[currentSlide].credit}</i>
          </Caption>
        </>
      )}
    </>
  );
};

export default Slideshow;
