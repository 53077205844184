import React, { useRef, useLayoutEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Button from "./ButtonEndOfTour";
import store from "../store";
import theme from "../theme";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../assets/icons/32-icon-location-v2.svg";
import { ReactComponent as CandleIcon } from "../assets/icons/32-icon-candles.svg";
import { ReactComponent as ArrowAroundIcon } from "../assets/icons/32-icon-around.svg";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 41, 57, 0.8);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 1rem;
  font-family: "Noto Serif", serif;
  line-height: ${28 / 16}rem;
  font-size: ${24 / 16}rem;
  font-family: "Noto Serif", serif;

  @media (min-width: 1024px) {
    font-size: ${60 / 16}rem;
    line-height: ${75 / 16}rem;
    font-weight: semibold;
  }
`;

const Text = styled.p`
  color: white;
  font-family: "Noto Serif", serif;
  font-size: ${16 / 16}rem;
  line-height: ${28 / 16}rem;
  margin-bottom: 0;

  @media (min-width: 1024px) {
    font-size: ${20 / 16}rem;
    line-height: ${28 / 16}rem;
    font-family: "Sintony", sans-serif;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  button {
    margin-top: 1rem;
  }

  @media (min-width: 1024px) {
    width: ${420 / 16}rem;
    flex-direction: row;
    justify-content: center;
    width: ${400 / 16}rem;
    button {
      margin-top: 0;
      margin-right: 1.75rem;
    }
    button:last-child {
      margin-right: 0;
    }
  }
`;

const EndTourOverlay = ({
  onButtonAction,
  subtitle,
  title,
  buttonText,
  isLightACandle,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [state, setState] = useState(store.initialState);
  const sub = useRef(null);

  useLayoutEffect(() => {
    sub.current = store.subscribe(setState);
    store.init();
    return () => {
      store.unsubscribe(sub.current);
    };
  }, []);

  return (
    <Overlay>
      <Text>{subtitle}</Text>
      <Title>{title}</Title>
      <ButtonContainer>
        {isLightACandle ? (
          <Button
            label="Light a Candle"
            type="dark"
            icon={<CandleIcon />}
            action={() => {
              store.setHeaderColorAll(theme.primary9);
              navigate("/light-a-candle");
            }}
            addStyle={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
          />
        ) : (
          !title.includes("Building") && (
            <Button
              label="Explore Icons"
              type="dark"
              icon={<ArrowAroundIcon />}
              action={() => {
                store.setHeaderColorAll(theme.primary9);
                navigate("/explore-icons");
              }}
              addStyle={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
            />
          )
        )}

        <Button
          label={buttonText}
          action={onButtonAction}
          type="light"
          icon={<LocationIcon />}
          addStyle={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
        />
      </ButtonContainer>
    </Overlay>
  );
};

export default EndTourOverlay;
